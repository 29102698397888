import React, { useContext, useEffect, useState } from "react";
import Api from "src/api/Api";
import { useQuery } from "src/api/useQuerySlice";
import LoadingBtn from "src/libs/buttons/Button/LoadingBtn";
import GenericList from "src/libs/generic-list/generic-list";
import { ModalContext } from "src/app/components/Modal/ModalContext";
import "./related-add-modal.scss";
import { IdNameDto } from "src/api/tools/tools.models.dto";
import { RelatedArticleSectionDto } from "src/api/article/article.dto";

interface SectionRootDto {
  id: number;
}

interface MediaAddEditModalProps {
  sectionId: number;
  sectionName: string;
}

const RelatedArticleAddEditModal: React.FC<MediaAddEditModalProps> = (props) => {
  const { sectionId, sectionName = "global" } = props;
  const { handleConfirm } = useContext(ModalContext);
  const [selectedItem, setSelectedItem] = useState<SectionRootDto>();

  const { data, call: callList, status: listStatus } = useQuery<Array<IdNameDto>>("Article/ListRootIdNames", [Api.Article.ListRootIdNames]);
  const { call, status } = useQuery<void, RelatedArticleSectionDto>("RelatedArticle/AddRelationSection", [Api.RelatedArticle.AddRelatedSection], {
    callOnLoad: false,
  });

  useEffect(() => {
    callList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (selectedItem && selectedItem?.id) {
      var addDto = {
        sectionId: sectionId,
        sectionName: sectionName,
        parentArticleRootId: selectedItem.id,
      } as RelatedArticleSectionDto;
      call(addDto);
    }
  };

  useEffect(() => {
    if (status === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="related-add-modal">
      {data ? (
        <>
          <div className="list-wrapper">
            <GenericList
              status={listStatus}
              data={data}
              cols={[
                ["Id", "id", 1],
                ["Name", "name", 10],
              ]}
              inputProps={{
                multiselect: false,
                onChange: (x: any) => setSelectedItem(x),
              }}
            />
          </div>
        </>
      ) : null}

      <div className="ml-auto pt-3">
        <LoadingBtn onClick={() => onSubmit()} status={status} className="ml-auto">
          Add
        </LoadingBtn>
      </div>
    </div>
  );
};

export default RelatedArticleAddEditModal;
