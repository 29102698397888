import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import ToastsStore from "src/features/toast-store/ToastsStore";
import { useQuery, useQueryRefetch } from "../../../../../../../../../api/useQuerySlice";
import { BasicLangKeyDto, FlatLangKeyDto, LangKeyDto } from "../../../../../../../../../api/lang/lang.dto";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";

interface LangEditFormProps {
  langId: string;
  parentId?: number;
  langKey?: LangKeyDto;
  children?: React.ReactNode;
}
interface AddLangKeyDto {
  id: string;
  dto: LangKeyDto;
}
const AddEditKeyForm = (props: LangEditFormProps) => {
  const { langKey, langId, parentId, children } = props;

  const { call: addCall, status: addStatus } = useQuery<void, AddLangKeyDto>("lang/addKey", [Api.Lang.AddKey], { callOnLoad: false });
  const { call: editCall, status: editStatus } = useQuery<void, BasicLangKeyDto>("lang/editKey", [Api.Lang.UpdateKey], { callOnLoad: false });

  const [isAddActive, setIsAddActive] = useState<boolean>(false);
  const { refetch } = useQueryRefetch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FlatLangKeyDto>({ defaultValues: {} });

  const onSubmit = async (dto: FlatLangKeyDto) => {
    if (langKey) editCall({ id: langKey.id, name: dto.name, value: dto.value });
    else addCall({ dto: { parentId: parentId, ...dto }, id: langId });
  };

  // useEffect(() => {
  //   reset(data);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  // useEffect(() => {
  //   if (updateStatus === "finished") {
  //     ToastsStore.success("Data has been saved");
  //     refetch(id);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [updateStatus]);

  useEffect(() => {
    if (addStatus === "finished") {
      reset({ name: "", value: "" });
      setIsAddActive(false);
      ToastsStore.success("Your key has been added");
      setTimeout(() => {
        refetch("lang/listKeys");
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  useEffect(() => {
    if (editStatus === "finished") {
      reset({ name: "", value: "" });
      setIsAddActive(false);
      ToastsStore.success("Your key has been updated");
      setTimeout(() => {
        refetch("lang/listKeys");
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  useEffect(() => {
    if (langKey && isAddActive) {
      reset({ name: langKey.name, value: langKey.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddActive]);

  return (
    <>
      {isAddActive ? (
        <div onClick={() => setIsAddActive(true)}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex">
              <TextInput
                id="name"
                label="Nazwa"
                validation_msg={errors?.name?.message}
                required
                inputProps={{
                  ...register("name", Validators.onlyLettersAndRequired),
                }}
              />
              &nbsp; &nbsp;
              <TextInput
                id="value"
                label="Value"
                inputProps={{
                  ...register("value"),
                }}
              />
              &nbsp; &nbsp;
              <div className="center-y mt-1 mt-05">
                <LoadingBtn variant="like-input" type="submit" status={addStatus} style={{ minWidth: "auto" }}>
                  Save
                </LoadingBtn>
              </div>
            </div>

            {/* <Row gap={0}>
              <Col size={4}>
              <TextInput
              id="name"
              label="Nazwa"
                  validation_msg={errors?.name?.message}
                  required
                  inputProps={{
                    ...register("name", Validators.required),
                  }}
                />
              </Col>
              <Col size={4}>
                <TextInput
                  id="value"
                  label="Value"
                  inputProps={{
                    ...register("value", Validators.required),
                  }}
                />
              </Col>
              <Col size={4} className="pt-2 mt-05">
                <LoadingBtn type="submit" status={addStatus} style={{ minWidth: "auto" }}>
                  Add
                </LoadingBtn>
              </Col>
            </Row> */}
          </Form>
        </div>
      ) : (
        <>
          {children}
          <div className="addEdit-new-item d-inline-block" onClick={() => setIsAddActive(true)}>
            {langKey ? <> &nbsp; Edit</> : "Add new"}
          </div>
        </>
      )}
    </>
  );
};

export default AddEditKeyForm;
