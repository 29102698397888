import React from "react";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { useParams } from "react-router-dom";
import { CustomerDto } from "../../../../../../../../api/order/order.dto";

interface Props {
  children: React.ReactNode;
}

const EditShippingAddressWrapper: React.FC<Props> = (props) => {
  const { children } = props;
  const { id = "" } = useParams<{ id: string }>();
  const { data } = useQuery<CustomerDto, string>("Customer/get--checkShippmentAvaliability", [Api.Customer.Get, id], {
    statusResetDelay: 1000,
  });

  return <>{data?.isShippingAddress ? children : null}</>;
};

export default EditShippingAddressWrapper;
