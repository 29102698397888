export const getBase64 = (file: File, callBack: any): any => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callBack(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};
