import { generatePath } from "react-router-dom";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { ArticleRootDto } from "../../../../../../../../../api/article/article.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import GenericList, { NumberStringTuple } from "../../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../../route-paths";
import Menu, { MenuItemType } from "../../../../../../../../components/Menu/menu";
import { showSlideOverlay } from "../../../../../../../../components/router-transition/routerTransitionSlice";
import Pagination from "src/app/components/Pagination/Pagination";
import { BasicColumnFilterDto, StandardFilterDto } from "src/libs/filters/filters.dto";
import { useState } from "react";
import { PaginationFilterDto } from "src/app/components/Pagination/pagination.dto";
import { ArrayResult } from "src/api/client";
import Filters from "src/libs/filters/FiltersForm";
import Validators from "src/libs/forms/validators/validators";
import TopLink from "src/app/components/top-link/top-link";

interface CategoriesListProps {}

const CategoriesList = (props: CategoriesListProps) => {
  const [filters, setFilters] = useState<StandardFilterDto>({});
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 10, skip: 0 });

  const { data, status, call } = useQuery<ArrayResult<ArticleRootDto>, BasicColumnFilterDto>("article/listRoot", [
    Api.Article.ListRoot,
    { ...filters, ...pagination },
  ]);
  const { status: delStatus, call: callDeleteRoot } = useQuery<void, number>("article/deleteRoot", [Api.Article.DeleteRoot], { callOnLoad: false });

  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    call({ ...filters, ...pagination });
  }, [filters, pagination]);

  useEffectAfterMounted(() => {
    if (delStatus === "finished") {
      ToastsStore.success("Article has been deleted");
      call();
    }
  }, [delStatus]);

  const handleDeleteRoot = (articleRoot: ArticleRootDto) => {
    Confirm(`Are you sure you want to delete article ${articleRoot.rootName}?`, () => callDeleteRoot(articleRoot.id));
  };

  const standardCols: NumberStringTuple[] = [
    ["Id", "id", 1],
    ["Name", "", 10, (x) => <TopLink to={generatePath(RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_EDIT, { id: x.id?.toString() })} className="primary">{x.rootName}</TopLink>],
  ];

  const standardActions = (actionsItems: (articleRoot: ArticleRootDto) => Array<MenuItemType>): NumberStringTuple => [
    "",
    "",
    1,
    (articleRoot: ArticleRootDto) => <Menu header="Options" items={actionsItems(articleRoot)} />,
  ];

  const actionsItems__edit = (articleRoot: ArticleRootDto) => {
    return {
      label: "Edit",
      onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_EDIT, { id: articleRoot.id?.toString() }))),
    };
  };
  const actionsItems__delete = (articleRoot: ArticleRootDto) => {
    return { label: "Delete", onAction: () => handleDeleteRoot(articleRoot) };
  };

  const standardActionsItems = (articleRoot: ArticleRootDto): Array<MenuItemType> => {
    return [actionsItems__edit(articleRoot), actionsItems__delete(articleRoot)];
  };
  return (
    <>
      <Filters
        onSubmit={setFilters}
        setPagination={setPagination}
        status={status}
        fields={[
          {
            field: "searchByColumn",
            component: "SelectBox",
            label: "Column",
            options: [
              { id: "name", label: "Nazwa" },
              // { id: "role", label: "Rola" },
            ],
            conditionalFields: [
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "name",
                fields: [{ field: "searchText", component: "TextBox", placeholder: "Wyszukaj", label: "Column contents", validators: Validators.required }],
              },
              // {
              //   basedOn: "searchByColumn",
              //   condition: (x: any) => x === "role",
              //   fields: [
              //     {
              //       field: "searchEnum",
              //       component: "SelectBox",
              //       label: "Wyszukaj",
              //       validators: Validators.required,
              //       options: mapEnum(UserRoleEnum, (key: any) => {
              //         return {
              //           id: key,
              //           label: UserRoleEnum_Parser(key),
              //         };
              //       }),
              //     },
              //   ],
              // },
            ],
          },
        ]}
      />

      <GenericList //
        status={status}
        data={data?.list}
        cols={[...standardCols]}
        actions={standardActions(standardActionsItems)}
      />
      <Pagination
        disableScroll
        totalCount={data?.totalCount}
        itemsPerPage={10}
        take={pagination.take!}
        setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
      />
    </>
  );
};

export default CategoriesList;
