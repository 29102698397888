import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { OrderDto } from "../../../../../../../../api/order/order.dto";
import ToastsStore from "src/features/toast-store/ToastsStore";

interface OrderManageFormProps {
  order: OrderDto;
}

interface NotepadManageDto {
  notepad: string;
}

const NotepadManageForm: React.FC<OrderManageFormProps> = (props) => {
  const { order } = props;
  const { call, status } = useQuery<void, { id: string; notepad: string }>("order/update-notepad", [Api.Order.UpdateNotepad], { callOnLoad: false });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<NotepadManageDto>({ defaultValues: {} });

  const onSubmit = async (dto: NotepadManageDto) => {
    call({ id: order.id.toString(), notepad: dto.notepad });
  };

  useEffect(() => {
    reset({
      notepad: order.notepad,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (status === "finished") {
      ToastsStore.success("Notatkę zmieniono");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-child form-child-wrapper">
          <TextInput
            id="notepad"
            label="Note"
            validation_msg={errors?.notepad?.message}
            inputProps={{
              type: "textarea",
              ...register("notepad"),
            }}
          />
        </div>
        <br />
        <div className="ml-auto">
          <LoadingBtn type="submit" status={status}>
            Save
          </LoadingBtn>
        </div>
      </Form>
    </>
  );
};

export default NotepadManageForm;
