import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import ToastsStore from "src/features/toast-store/ToastsStore";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import { GeneralSettingsDto, GeneralSettings_Basic_Dto } from "../../../../../../../../api/generalSettings/generalSettings.dto";

interface GenSettBasicFormProps {
  data?: GeneralSettingsDto
}

const GenSettBasicForm = (props: GenSettBasicFormProps) => {
  const { data } = props;

  const { call, status: updateStatus } = useQuery<void, GeneralSettings_Basic_Dto>("generalSettings/updateBasic", [Api.GeneralSettings.UpdateBasic], {
    callOnLoad: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<GeneralSettings_Basic_Dto>({ defaultValues: {} });

  const onSubmit = async (dto: any) => {
    call({ ...dto, id: data?.id });
  };

  useEffectAfterMounted(() => {
    reset({ websiteDescription: data?.websiteDescription, websiteTitle: data?.websiteTitle });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
      <TextInput
        id="websiteTitle"
        label="Website title"
        validation_msg={errors?.websiteTitle?.message}
        inputProps={{
          ...register("websiteTitle", Validators.required),
        }}
      />
      <TextInput
        id="websiteDescription"
        label="Website description"
        validation_msg={errors?.websiteDescription?.message}
        inputProps={{
          type: "textarea",
          ...register("websiteDescription", Validators.required),
        }}
      />
      <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
        Save
      </LoadingBtn>
    </Form>
  );
};

export default GenSettBasicForm;
