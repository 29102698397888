import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { CategoryRootDto, FlatCategoryRootDto } from "../../../../../../../../../api/category/category.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import CheckBoxInput from "../../../../../../../../../libs/forms/CheckBox/CheckBox";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { RoutePath } from "../../../../../../../../../route-paths";

const CategoryAddForm = () => {
  const { call, status: updateStatus } = useQuery<void, CategoryRootDto>("category/add", [Api.Category.AddRoot], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FlatCategoryRootDto>({ defaultValues: {} });
  const navigate = useNavigate();

  const onSubmit = async (dto: FlatCategoryRootDto) => {
    call({ ...dto } as CategoryRootDto);
  };

  useEffect(() => {
    if (updateStatus === "finished") {
      reset();
      ToastsStore.success("Category added successfuly");
      navigate(RoutePath.PANEL_ASSORTMENT_CATEGORIES_LIST as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
      <TextInput
        id="rootName"
        label="Name"
        validation_msg={errors?.rootName?.message}
        required
        inputProps={{
          ...register("rootName", Validators.required),
        }}
      />
      <CheckBoxInput
        id="isFavourite"
        label="Is favorite?"
        validation_msg={errors?.isFavourite?.message}
        inputProps={{
          ...register("isFavourite"),
        }}
        description={"Favorite"}
      />
      <TextInput
        id="rootMeta_head"
        label="[html] Section html>head"
        validation_msg={errors?.rootMeta_head?.message}
        required
        inputProps={{
          type: "textarea",
          ...register("rootMeta_head"),
        }}
      />
      <br />
      <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
        Save
      </LoadingBtn>
    </Form>
  );
};

export default CategoryAddForm;
