import { Navigate, Outlet } from "react-router-dom";
import { RoutePath } from "../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../components/Breadcrumbs/BreadcrumbsItem";
import ExactPathSwitch from "../../../../../components/ExactPathSwitch/ExactPathSwitch";

const CustomersLayout = () => {

  return (
    <>
      <BreadcrumbsItem to={RoutePath.PANEL_CUSTOMERS} title="Customers" isNotLink={true} />
      <ExactPathSwitch
        path={RoutePath.PANEL_CUSTOMERS}
        if_exact={<Navigate to={RoutePath.PANEL_CUSTOMERS_LIST} />}
        else={<Outlet />}
      />
    </>
  );
};

export default CustomersLayout;
