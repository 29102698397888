import ToastsStore from "src/features/toast-store/ToastsStore";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import Api from "../../../../../../../../../api/Api";
import GenericList, { NumberStringTuple } from "../../../../../../../../../libs/generic-list/generic-list";
import Menu, { MenuItemType } from "../../../../../../../../components/Menu/menu";
import { Confirm } from "../../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import ProductParam from "../../../../../../../../../api/productParam/ProductParam";
import { ProductParamDto } from "../../../../../../../../../api/productParam/productParam.dto";
import AddEditParamForm from "./AddEditParamForm";

interface Props {
  productLangId: number;
}

const ParamsManager = (props: Props) => {
  const { productLangId } = props;
  const {
    call: callList,
    status,
    data: productParamsData,
  } = useQuery<Array<ProductParam>, number>("productParam/list", [Api.ProductParam.List, productLangId], { statusResetDelay: 1000 });
  const { status: deleteStatus, call: deleteCall } = useQuery<void, string>("productParam/delete", [Api.ProductParam.Delete], { callOnLoad: false });

  const standardCols: NumberStringTuple[] = [
    [
      "Name : Value",
      "",
      10,
      (x: ProductParamDto) => (
        <>
          <AddEditParamForm productLangId={productLangId} productParam={x}>
            <u>{x.name}</u>:{" "}
            {x.values.map((y, index) => {
              return y + (index + 1 < x.values.length ? ", " : "");
            })}
          </AddEditParamForm>
        </>
      ),
    ],
  ];

  useEffectAfterMounted(() => {
    if (deleteStatus === "finished") {
      ToastsStore.success("Param has been deleted");
      callList(productLangId);
    }
  }, [deleteStatus]);
  const standardActions = (actionsItems: (productParam: ProductParamDto) => Array<MenuItemType>): NumberStringTuple => [
    "",
    "",
    2,
    (productParam: ProductParamDto) => <Menu header="Options" items={actionsItems(productParam)} />,
  ];

  const actionsItems__delete = (dto: ProductParamDto) => {
    let id = dto.id?.toString();
    return { label: "Delete", onAction: () => Confirm(`Are you sure you want to delete the ${dto.name} key?`, () => deleteCall(id)) };
  };

  const standardActionsItems = (dto: ProductParamDto): Array<MenuItemType> => {
    return [actionsItems__delete(dto)];
  };

  return (
    <>
      <h2 className="title">🎛️ Specification</h2>
      <hr />
      <GenericList status={status} data={productParamsData} cols={[...standardCols]} actions={standardActions(standardActionsItems)} />
      <AddEditParamForm productLangId={productLangId} />
    </>
  );
};

export default ParamsManager;
