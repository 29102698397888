import React from "react";
import { generatePath } from "react-router-dom";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import ProductAddForm from "./product-add-form/ProductAddForm";

const ProductAddPage = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_ASSORTMENT_PRODUCTS_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_ASSORTMENT_PRODUCTS_ADD)} title="Dodawanie produktu" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Dodawanie produktu</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[6, 8, 10, 12]}>
            <Tile>
              <h2 className="title">Dane podstawowe</h2>
              <ProductAddForm />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default ProductAddPage;
