import React, { useEffect } from "react";
import Api from "../../../../../../../../api/Api";
import { ImageDto, ImageFieldEnum } from "../../../../../../../../api/image/image.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../features/confirm";
import { html } from "../../../../../../../../features/html";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import GenericListIcon from "../../../../../../../../libs/generic-list/generic-list-icon";
import { useTrigger } from "../../../../../../../components/GlobalTrigger/GlobalTriggerSlice";
import Menu from "../../../../../../../components/Menu/menu";
import Modal from "../../../../../../../components/Modal/Modal";
import MediaAddEditModal from "./media-addEdit-modal";

interface MediaListProps {
  scopeId: number;
}

const MediaList: React.FC<MediaListProps> = (props) => {
  const { scopeId } = props;
  const { setTrigger, triggerId } = useTrigger();
  const { call, data, status } = useQuery<Array<ImageDto>, number>("generalSettings/ListSlides", [Api.GeneralSettings.ListSlides, scopeId], {
    callOnLoad: true,
  });
  const { call: callDeleteMedia, status: deleteMediaStatus } = useQuery<void, number>("image/Image", [Api.Image.Delete], {
    callOnLoad: false,
  });

  useEffect(() => {
    if (deleteMediaStatus === "finished") call(scopeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMediaStatus]);

  const Internal_ImageFieldEnum_Parser = (x: ImageFieldEnum) => {
    switch (x) {
      case ImageFieldEnum.MainPicture:
        return "First slide";
      case ImageFieldEnum.MainPictureGallery:
        return "Slide";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="d-flex pb-3">
        <div className="mr-auto">
          <h2 className="title pr-4">Home 🠚 Slider</h2>
        </div>
        <Modal
          title={`Add new`}
          disableStandardButtons={true}
          button={{
            title: `+ Add new`,
            variant: "like-input-primary",
          }}
          maxWidth={600}
          confirm={() => call(scopeId)}
        >
          <MediaAddEditModal itemId={scopeId} />
        </Modal>
      </div>
      <hr />
      <GenericList
        status={status}
        data={data}
        cols={[
          ["Image", "path", 2, (x) => <GenericListIcon img={process.env.REACT_APP_API_URL + x} size="xl" openOnClick={true} />],
          ["Alt", "alt", 2],
          ["Description", "description", 5, (x) => html(x)],
          ["Function", "field", 2, (x) => Internal_ImageFieldEnum_Parser(x)],
          [
            "",
            "",
            0,
            (x) => {
              return (
                <Modal
                  title={`Edycja obrazu`}
                  maxWidth={600}
                  button={{
                    hidden: true,
                  }}
                  disableStandardButtons={true}
                  openTrigger={triggerId === "media" + scopeId + "_addEdit_modal--" + x.path}
                  confirm={() => call(scopeId)}
                >
                  <MediaAddEditModal itemId={scopeId} media={x} />
                </Modal>
              );
            },
          ],
        ]}
        actions={[
          "",
          "",
          1,
          (item: ImageDto) => (
            <Menu
              header="Options"
              items={[
                {
                  label: "Edit",
                  onAction: () => setTrigger("media" + scopeId + "_addEdit_modal--" + item.path),
                },
                {
                  label: "Delete",
                  onAction: () => Confirm(`Are you sure you want to delete ${item.path}?`, () => callDeleteMedia(item.id)),
                },
              ]}
            />
          ),
        ]}
      />
    </>
  );
};

export default MediaList;
