import React from "react";
import { GeneralSettingsDto } from "../../../../../../../../api/generalSettings/generalSettings.dto";
import MediaList from "./media-list";

interface MediaListProps {
  data?: GeneralSettingsDto;
}

const MediaListWrapper: React.FC<MediaListProps> = (props) => {
  const {data} = props;
  return <>{data && data.id ? <MediaList scopeId={data?.id} /> : null}</>;
};

export default MediaListWrapper;
