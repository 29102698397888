import React, { useEffect } from "react";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import GenericListIcon from "../../../../../../../../../libs/generic-list/generic-list-icon";
import Menu from "../../../../../../../../components/Menu/menu";
import Modal from "../../../../../../../../components/Modal/Modal";
import RelatedAuthorAddEditModal from "./related-authors-add-modal";
import { RelatedArticleAuthorDto, RelatedAuthorRootRelationDto } from "src/api/article/article.dto";

interface RelatedAuthorListProps {
  articleRootId: number;
}

const RelatedAuthors: React.FC<RelatedAuthorListProps> = (props) => {
  const { articleRootId } = props;
  const { call, data, status } = useQuery<Array<RelatedArticleAuthorDto>, number>(
    "author/ListRelatedAuthors",
    [Api.Article.ListRelatedAuthors, articleRootId],
    {
      callOnLoad: true,
    }
  );
  const { call: callDeleteRelatedAuthor, status: deleteRelatedAuthorStatus } = useQuery<void, RelatedAuthorRootRelationDto>(
    "Author/DeleteRelatedAuthor",
    [Api.Article.DeleteRelatedAuthor],
    {
      callOnLoad: false,
    }
  );

  useEffect(() => {
    if (deleteRelatedAuthorStatus === "finished") {
      call(articleRootId);
      ToastsStore.success("Related author has been deleted");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRelatedAuthorStatus]);

  return (
    <>
      <div className="d-flex pb-3">
        <div className="mr-auto">
          <h2 className="title pr-4">🔗 Related authors</h2>
        </div>
        <Modal
          title={`Add new related author`}
          disableStandardButtons={true}
          button={{
            title: `+ Add new`,
            variant: "like-input-primary",
          }}
          maxWidth={1200}
          confirm={() => call(articleRootId)}
          className={"RelatedAuthorAddEditModal"}
        >
          <RelatedAuthorAddEditModal articleRootId={articleRootId} />
        </Modal>
      </div>
      <hr />
      <GenericList
        status={status}
        data={data}
        cols={[
          [
            "",
            "image",
            2,
            (x) => <GenericListIcon img={process.env.REACT_APP_API_URL + (x?.path ?? "/system-images/avatar_placeholder.png")} size="md" openOnClick={true} />,
          ],
          ["Id", "authorRootId", 2],
          ["Name", "rootName", 7],
        ]}
        actions={[
          "",
          "",
          1,
          (item: RelatedArticleAuthorDto) => (
            <Menu
              header="Options"
              items={[
                {
                  label: "Delete",
                  onAction: () =>
                    Confirm(`Are you sure you want to delete relation with ${item.rootName}?`, () =>
                      callDeleteRelatedAuthor({ articleRootId: item.parentArticleRootId, relatedAuthorRootId: item.authorRootId })
                    ),
                },
              ]}
            />
          ),
        ]}
      />
    </>
  );
};

export default RelatedAuthors;
