import React from "react";
import { CategoryLangDto } from "../../../../../../../../../api/category/category.dto";
import FaqList from "./faq-list";

interface FaqListProps {
  data?: CategoryLangDto;
}

const FaqListWrapper: React.FC<FaqListProps> = (props) => {
  const { data } = props;
  return <>{data && data.id ? <FaqList scopeId={data.id} /> : null}</>;
};

export default FaqListWrapper;
