import React, { useEffect } from "react";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { RelatedCategoryRootDto, RelatedCategoryRootRelationDto } from "../../../../../../../../../api/product/product.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import GenericListIcon from "../../../../../../../../../libs/generic-list/generic-list-icon";
import Menu from "../../../../../../../../components/Menu/menu";
import Modal from "../../../../../../../../components/Modal/Modal";
import RelatedCategoryAddEditModal from "./related-categories-add-modal";

interface RelatedCategoryListProps {
  productRootId: number;
}

const RelatedCategories: React.FC<RelatedCategoryListProps> = (props) => {
  const { productRootId } = props;
  const { call, data, status } = useQuery<Array<RelatedCategoryRootDto>, number>(
    "product/ListRelatedCategories",
    [Api.Product.ListRelatedCategories, productRootId],
    {
      callOnLoad: true,
    }
  );
  const { call: callDeleteRelatedCategory, status: deleteRelatedCategoryStatus } = useQuery<void, RelatedCategoryRootRelationDto>(
    "Product/DeleteRelatedCategory",
    [Api.Product.DeleteRelatedCategory],
    {
      callOnLoad: false,
    }
  );

  useEffect(() => {
    if (deleteRelatedCategoryStatus === "finished") {
      call(productRootId);
      ToastsStore.success("Related product has been deleted");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRelatedCategoryStatus]);

  return (
    <>
      <div className="d-flex pb-3">
        <div className="mr-auto">
          <h2 className="title pr-4">🔗 Categories</h2>
        </div>
        <Modal
          title={`Add new related category`}
          disableStandardButtons={true}
          button={{
            title: `+ Add new`,
            variant: "like-input-primary",
          }}
          maxWidth={1200}
          confirm={() => call(productRootId)}
          className={"RelatedCategoryAddEditModal"}
        >
          <RelatedCategoryAddEditModal productRootId={productRootId} />
        </Modal>
      </div>
      <hr />
      <GenericList
        status={status}
        data={data}
        cols={[
          [
            "",
            "",
            1,
            (x) =>
              x.image ? (
                <GenericListIcon img={process.env.REACT_APP_API_URL + x.image.path} size="md" openOnClick={true} />
              ) : (
                <GenericListIcon img={"/images/placeholder.jpg"} size="md" openOnClick={true} />
              ),
          ],
          ["Id", "categoryRootId", 1],
          ["Name", "rootName", 9],
        ]}
        actions={[
          "",
          "",
          1,
          (item: RelatedCategoryRootDto) => (
            <Menu
              header="Options"
              items={[
                {
                  label: "Delete",
                  onAction: () =>
                    Confirm(`Are you sure you want to delete relation with ${item.rootName}?`, () =>
                      callDeleteRelatedCategory({ productRootId: item.parentProductRootId, relatedCategoryRootId: item.categoryRootId })
                    ),
                },
              ]}
            />
          ),
        ]}
      />
    </>
  );
};

export default RelatedCategories;
