import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import Api from "src/api/Api";
import { LangDto } from "src/api/lang/lang.dto";
import { generateExtraReducers } from "src/redux/redux-tools";
import { RootState, useAppDispatch, useAppSelector } from "../../../redux/store";

export interface GlobalLangState {
  langs: Array<LangDto>;
  defaultLang?: LangDto;
}

const initialState: GlobalLangState = {
  langs: [
    {
      id: 2,
      prefix: "en",
      name: "English",
      country: "United Kingdom",
      isDefault: true,
      isActive: true,
    },
  ],
  defaultLang: {
    id: 2,
    prefix: "en",
    name: "English",
    country: "United Kingdom",
    isDefault: true,
    isActive: true,
  },
};
export const getDefaultLangAsync = createAsyncThunk("Lang/GetDefault", async () => await Api.Lang.GetDefault());
export const listLangsAsync = createAsyncThunk("Lang/List", async () => await Api.Lang.List());

export const globalLangsSlice = createSlice({
  name: "globalLangs",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    generateExtraReducers<GlobalLangState>(builder, [
      {
        call: listLangsAsync,
        logs: true,
        dataField: "langs",
        statusField: "",
      },
      {
        call: getDefaultLangAsync,
        logs: true,
        dataField: "defaultLang",
        statusField: "",
      },
    ]),
});

export default globalLangsSlice;

export function useInitGlobalLangs() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listLangsAsync());
    dispatch(getDefaultLangAsync());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export function useGlobalLangs() {
  const langs = useAppSelector((state: RootState) => state.globalLangs.langs);
  const defaultLang = useAppSelector((state: RootState) => state.globalLangs.defaultLang);

  return { langs, defaultLang };
}
