import React, { useEffect } from "react";
import Api from "../../../../../../../../../api/Api";
import { FaqDto } from "../../../../../../../../../api/faq/faq.dto";
import { ImageDto } from "../../../../../../../../../api/image/image.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import { useTrigger } from "../../../../../../../../components/GlobalTrigger/GlobalTriggerSlice";
import Menu from "../../../../../../../../components/Menu/menu";
import Modal from "../../../../../../../../components/Modal/Modal";
import FaqAddEditModal from "./faq-addEdit-modal";

interface FaqListProps {
  scopeId: number;
}

const FaqList: React.FC<FaqListProps> = (props) => {
  const { scopeId } = props;
  const { setTrigger, triggerId } = useTrigger();
  const { call, data, status } = useQuery<Array<FaqDto>, number>("category/ListFaqs", [Api.Category.ListFaqs, scopeId], {
    callOnLoad: true,
  });
  const { call: callDeleteFaq, status: deleteFaqStatus } = useQuery<void, number>("faq/delete", [Api.Faq.Delete], {
    callOnLoad: false,
  });

  useEffect(() => {
    if (deleteFaqStatus === "finished") call(scopeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFaqStatus]);

  return (
    <>
      <div className="d-flex pb-3">
        <div className="mr-auto">
          <h2 className="title pr-4">Faq</h2>
        </div>
        <Modal
          title={`Add new`}
          disableStandardButtons={true}
          button={{
            title: `+ Add new`,
            variant: "like-input-primary",
          }}
          maxWidth={600}
          confirm={() => call(scopeId)}
        >
          <FaqAddEditModal itemId={scopeId} />
        </Modal>
      </div>
      <hr />
      <GenericList
        status={status}
        data={data}
        cols={[
          ["Question", "question", 2],
          ["Response", "response", 9],
          [
            "",
            "",
            0,
            (x) => {
              return (
                <Modal
                  title={`Edycja faq`}
                  maxWidth={600}
                  button={{
                    hidden: true,
                  }}
                  disableStandardButtons={true}
                  openTrigger={triggerId === "faq-category" + scopeId + "_addEdit_modal--" + x.id}
                  confirm={() => call(scopeId)}
                >
                  <FaqAddEditModal itemId={scopeId} faq={x} />
                </Modal>
              );
            },
          ],
        ]}
        actions={[
          "",
          "",
          1,
          (item: ImageDto) => (
            <Menu
              header="Options"
              items={[
                {
                  label: "Edit",
                  onAction: () => setTrigger("faq-category" + scopeId + "_addEdit_modal--" + item.id),
                },
                {
                  label: "Delete",
                  onAction: () => Confirm(`Czy na pewno chcesz to usunąć?`, () => callDeleteFaq(item.id)),
                },
              ]}
            />
          ),
        ]}
      />
    </>
  );
};

export default FaqList;
