import React, { useEffect } from "react";
import "./param-cell.scss";
import { NameLangPrefixDto, ProdVariantParamDto } from "src/api/prodVariant/prodVariant.dto";
import TextInput from "src/libs/forms/TextBox/TextInput";
import Api from "src/api/Api";
import { useQuery, useQueryRefetch } from "src/api/useQuerySlice";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Modal from "src/app/components/Modal/Modal";
import EditLangNamesModal from "./edit-langNames-modal";
import { useTrigger } from "src/app/components/GlobalTrigger/GlobalTriggerSlice";

interface Props {
  prodVariantParam: ProdVariantParamDto;
  label?: string;
}

const ParamCellEdit: React.FC<Props> = (props) => {
  const { prodVariantParam, label } = props;

  const { call: editCall, status } = useQuery<void, ProdVariantParamDto>("ProdVariant/EditVariantParam", [Api.ProdVariant.UpdateVariantParam], {
    callOnLoad: false,
  });

  const { refetch } = useQueryRefetch();
  const { setTrigger, triggerId } = useTrigger();

  const handleBlur = (e: any) => {
    if (prodVariantParam.rootValue.toString() !== e.target!.value.toString()) {
      const dto = {
        ...prodVariantParam,
        rootValue: e.target!.value ?? "",
      } as ProdVariantParamDto;
      editCall(dto);
    }
  };

  useEffect(() => {
    if (status === "finished") {
      refetch("ProdVariant/ListProdVariantParams");
      ToastsStore.success("Changes have been saved");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className={`param-cell ${label ? "--with-label" : ""}`} title={label??""}>
      <Modal
        title={`Language settings - ${prodVariantParam.rootValue}`}
        openTrigger={triggerId === `edit-langNames_${prodVariantParam.prodVariantId}_${prodVariantParam.prodVariantColId}_${prodVariantParam.id}`}
        disableStandardButtons={true}
        button={{
          hidden: true,
        }}
        maxWidth={900}
        confirm={() => refetch("ProdVariant/ListProdVariantParams")}
        className={"VariantManagerModal"}
      >
        <EditLangNamesModal entity={prodVariantParam} endpoint={Api.ProdVariant.UpdateVariantParam} />
      </Modal>
      <TextInput
        label={label ? label : undefined}
        
        inputProps={{
          onBlurCapture: handleBlur,
          defaultValue: prodVariantParam.rootValue,
        }}
      />
      <span
        className={`lang-btn ${
          prodVariantParam.langNames === null ||
          prodVariantParam.langNames === undefined ||
          prodVariantParam.langNames.length === 0 ||
          prodVariantParam.langNames?.findIndex((x) => !(x.name === null || x.name === undefined || x.name === "" || x.name?.length === 0)) === -1
            ? "gray-scale"
            : ""
        }`}
        onClick={() => setTrigger(`edit-langNames_${prodVariantParam.prodVariantId}_${prodVariantParam.prodVariantColId}_${prodVariantParam.id}`)}
      >
        {prodVariantParam.langNames &&
        !(
          prodVariantParam.langNames === null ||
          prodVariantParam.langNames === undefined ||
          prodVariantParam.langNames.length === 0 ||
          prodVariantParam.langNames?.findIndex((x) => !(x.name === null || x.name === undefined || x.name === "" || x.name?.length === 0)) === -1
        )
          ? prodVariantParam.langNames.map((x: NameLangPrefixDto, index: number) => {
              if (x.name === null || x.name === undefined || x.name === "" || x.name?.length === 0) return <b className="lang-dots" key={index}>.</b>;
              return null;
            })
          : null}
        🌎
      </span>
    </div>
  );
};

export default ParamCellEdit;
