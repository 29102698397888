import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import Api from '../../../../../api/Api';
import { ResetPasswordDto } from '../../../../../api/auth/auth.dto';
import { generateExtraReducers } from '../../../../../redux/redux-tools';
import { RootState } from '../../../../../redux/store';
import { useAppSelector } from '../../../../../redux/store';

export interface resetPasswordDataState {
  resetPassword_status: 'default' | 'finished' | 'loading' | 'failed';
  requestResetPassword_status: 'default' | 'finished' | 'loading' | 'failed';
}

const initialState: resetPasswordDataState = {
  resetPassword_status: 'default',
  requestResetPassword_status: 'default'
};

export const requestResetPasswordAsync = createAsyncThunk(
  'auth/requestResetPassword',
  async (email: string) => {
    await Api.Auth.RequestResetPassword(email);
  }
);

export const resetPasswordAsync = createAsyncThunk(
  'auth/resetPassword',
  async (dto: ResetPasswordDto) => {
    var result = await Api.Auth.ResetPassword(dto);
    return result;
  }
);

export const resetPasswordDataSlice = createSlice({
  name: 'resetPasswordData',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => generateExtraReducers<resetPasswordDataState>(builder, [
    { call: resetPasswordAsync, logs: true, dataField: 'resetPassword_data', statusField: 'resetPassword_status' },
    { call: requestResetPasswordAsync, logs: true, dataField: 'requestResetPassword_data', statusField: 'requestResetPassword_status' }
  ])
});

// eslint-disable-next-line no-empty-pattern
export default resetPasswordDataSlice.reducer;

export function useResetPassword() {
  const resetPassword_status = useAppSelector((state: RootState) => state.resetPasswordData.resetPassword_status);
  const requestResetPassword_status = useAppSelector((state: RootState) => state.resetPasswordData.requestResetPassword_status);

  const dispatch = useDispatch();
  const resetPassword = (dto: ResetPasswordDto) => dispatch<any>(resetPasswordAsync(dto));
  const requestResetPassword = (email: string) => dispatch<any>(requestResetPasswordAsync(email));

  return { resetPassword_status, resetPassword, requestResetPassword_status, requestResetPassword };
}