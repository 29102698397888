import React, { useEffect } from "react";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import { useTrigger } from "../../../../../../../../components/GlobalTrigger/GlobalTriggerSlice";
import Menu from "../../../../../../../../components/Menu/menu";
import Modal from "../../../../../../../../components/Modal/Modal";
import MediaAddEditModal from "./socialmedia-list-addEdit-modal";
import { SocialMediaIconEnum_Parser, SocialMediaLinkDto } from "src/api/author/author.dto";

interface MediaListProps {
  scopeId: number;
}

const SocialMediaList: React.FC<MediaListProps> = (props) => {
  const { scopeId } = props;
  const { setTrigger, triggerId } = useTrigger();
  const { call, data, status } = useQuery<Array<SocialMediaLinkDto>, number>("author/ListSocialMediaLinks", [Api.Author.ListSocialMediaLinks, scopeId], {
    callOnLoad: true,
  });
  const { call: callDeleteMedia, status: deleteMediaStatus } = useQuery<void, { authorLangId: number; id: string }>(
    "Author/DeleteSocialMediaLink",
    [Api.Author.DeleteSocialMediaLink],
    {
      callOnLoad: false,
    }
  );

  useEffect(() => {
    if (deleteMediaStatus === "finished") call(scopeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMediaStatus]);

  return (
    <>
      <div className="d-flex pb-3">
        <div className="mr-auto">
          <h2 className="title pr-4">Social media</h2>
        </div>
        <Modal
          title={`Add new`}
          disableStandardButtons={true}
          button={{
            title: `+ Add new`,
            variant: "like-input-primary",
          }}
          maxWidth={600}
          confirm={() => call(scopeId)}
        >
          <MediaAddEditModal authorLangId={scopeId} />
        </Modal>
      </div>
      <hr />
      <GenericList
        status={status}
        data={data}
        cols={[
          ["Name", "name", 3],
          ["Icon", "icon", 3, (x) => SocialMediaIconEnum_Parser(x)],
          ["Url", "url", 6],
          [
            "",
            "",
            0,
            (x) => {
              return (
                <Modal
                  title={`Edit social media`}
                  maxWidth={600}
                  button={{
                    hidden: true,
                  }}
                  disableStandardButtons={true}
                  openTrigger={triggerId === "socialmedia" + scopeId + "_addEdit_modal--" + x.id}
                  confirm={() => call(scopeId)}
                >
                  <MediaAddEditModal authorLangId={scopeId} socialmedia={x} />
                </Modal>
              );
            },
          ],
        ]}
        actions={[
          "",
          "",
          1,
          (item: SocialMediaLinkDto) => (
            <Menu
              header="Options"
              items={[
                {
                  label: "Edit",
                  onAction: () => setTrigger("socialmedia" + scopeId + "_addEdit_modal--" + item.id),
                },
                {
                  label: "Delete",
                  onAction: () => Confirm(`Are you sure you want to delete ${item.name}?`, () => callDeleteMedia({authorLangId: scopeId, id: item.id!})),
                },
              ]}
            />
          ),
        ]}
      />
    </>
  );
};

export default SocialMediaList;
