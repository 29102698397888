import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { useParams } from "react-router-dom";
import { CustomerDto, OrderDto } from "../../../../../../../../api/order/order.dto";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Col from "../../../../../../../../libs/grid/col";
import Row from "../../../../../../../../libs/grid/row";
import TextBlock from "../../../../../../../../libs/text-block/text-block";

interface CustomerFormProps {}

const CustomerForm: React.FC<CustomerFormProps> = (props) => {
  const { orderId = "" } = useParams<{ orderId: string }>();
  const { data } = useQuery<OrderDto, string>("order/get-customer", [Api.Order.Get, orderId], { callOnLoad: true });

  const {
    register,
    reset,
    formState: { errors },
  } = useForm<CustomerDto>({ defaultValues: {} });

  useEffect(() => {
    console.log("----");
    console.log(data?.customerCopy);
    if (data && data.customerCopy) reset({ ...data.customerCopy } as CustomerDto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Form>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Personal informations</h3>
          <Row gap={0} align="center">
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="name"
                label="Name"
                validation_msg={errors?.address?.name?.message}
                required
                inputProps={{
                  disabled: true,
                  ...register("address.name", Validators.required),
                }}
              />
              <TextInput
                id="surname"
                label="Surname"
                required
                validation_msg={errors?.address?.surname?.message}
                inputProps={{
                  disabled: true,
                  ...register("address.surname", Validators.required),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="email"
                label="Email"
                required
                validation_msg={errors?.address?.email?.message}
                inputProps={{
                  type: "email",
                  disabled: true,
                  ...register("address.email", Validators.email),
                }}
              />
              <TextInput
                id="phone"
                label="Phone"
                required
                validation_msg={errors?.address?.phone?.message}
                inputProps={{
                  maxLength: 20,
                  disabled: true,
                  ...register("address.phone", Validators.required),
                }}
              />
            </Col>
          </Row>
          <h3 className="title form-child">Invoice data</h3>
          <Row gap={0}>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="company"
                label="Company name"
                validation_msg={errors?.company?.message}
                inputProps={{
                  disabled: true,
                  ...register("company"),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="nip"
                label="TAX number"
                validation_msg={errors?.nip?.message}
                inputProps={{
                  maxLength: 10,
                  disabled: true,
                  ...register("nip"),
                }}
              />
            </Col>
          </Row>
          <h3 className="title form-child d-flex">Customer address</h3>

          <Row gap={0}>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="country"
                label="Country"
                validation_msg={errors?.address?.country?.message}
                required
                inputProps={{
                  disabled: true,
                  ...register("address.country", Validators.required),
                }}
              />
              <TextInput
                id="street"
                label="Street"
                required
                validation_msg={errors?.address?.street?.message}
                inputProps={{
                  disabled: true,
                  ...register("address.street", Validators.required),
                }}
              />
              <TextInput
                id="homenumber"
                label="Home number"
                validation_msg={errors?.address?.homenumber?.message}
                required
                inputProps={{
                  disabled: true,
                  ...register("address.homenumber", Validators.required),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="city"
                label="City"
                required
                validation_msg={errors?.address?.city?.message}
                inputProps={{
                  disabled: true,
                  ...register("address.city", Validators.required),
                }}
              />

              <TextInput
                id="postalcode"
                label="Postal code"
                required
                validation_msg={errors?.address?.postalcode?.message}
                inputProps={{
                  maxLength: 6,
                  disabled: true,
                  ...register("address.postalcode", Validators.required),
                }}
              />
              <TextInput
                id="flatnumber"
                label="Flat number"
                validation_msg={errors?.address?.flatnumber?.message}
                inputProps={{
                  disabled: true,
                  ...register("address.flatnumber"),
                }}
              />
            </Col>
            {data?.customerCopy.isShippingAddress ? (
              <Col size={12}>
                <TextBlock>
                  <p className="mt-0">⚠️ Customer has set a different delivery address</p>
                </TextBlock>
              </Col>
            ) : null}
          </Row>
          <h3 className="title form-child">Other informations</h3>
          <Row gap={0}>
            <Col size={12}>
              <TextInput
                id="notepad"
                label="Message for the seller"
                inputProps={{
                  type: "textarea",
                  disabled: true,
                  ...register("address.notepad"),
                }}
              />
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default CustomerForm;
