import "./App.scss";

import { RoutePath } from "../route-paths";
import { Route } from "react-router-dom";

import useTheme from "../features/theme-controller/useTheme";
import { AuthRoute, NoAuthRoute } from "../features/auth-provider/AuthProvider";
import MultiRoutes from "../features/auth-provider/AuthRoutes";
import PanelLayout from "./routes/auth/panel/panel-layout";
import NotFoundPage from "./routes/auth/panel/panel-pages/not-found-page";
import PanelPage from "./routes/auth/panel/panel-pages/panel-page";
import AccountPage from "./routes/auth/panel/panel-pages/settings/account/account-page";
import SettingsLayout from "./routes/auth/panel/panel-pages/settings/settings-layout";
import UserAddPage from "./routes/auth/panel/panel-pages/users/add/user-add";
import UserEditPage from "./routes/auth/panel/panel-pages/users/edit/user-edit";
import UsersListPage from "./routes/auth/panel/panel-pages/users/list/users-list";
import UsersLayout from "./routes/auth/panel/panel-pages/users/users-layout";
import RequestResetPasswordPage from "./routes/no-auth/reset-password-page/request-reset-password-page";
import ResetPasswordPage from "./routes/no-auth/reset-password-page/reset-password-page";
import SignInPage from "./routes/no-auth/sign-in-page/sign-in-page";
import SingleBoxLayout from "./routes/no-auth/SingleBoxLayout";
import LangsLayout from "./routes/auth/panel/panel-pages/settings/langs/langs-layout";
import LangsListPage from "./routes/auth/panel/panel-pages/settings/langs/list/langs-list";
import LangAddPage from "./routes/auth/panel/panel-pages/settings/langs/add/user-add";
import LangEditPage from "./routes/auth/panel/panel-pages/settings/langs/edit/lang-edit";
import CategoryAddPage from "./routes/auth/panel/panel-pages/assortment/categories/add/category-add";
import CategoriesListPage from "./routes/auth/panel/panel-pages/assortment/categories/list/categories-list";
import CategoriesLayout from "./routes/auth/panel/panel-pages/assortment/categories/categories-layout";
import CategoryEditPage from "./routes/auth/panel/panel-pages/assortment/categories/edit/category-edit";
import SubcategoryAddPage from "./routes/auth/panel/panel-pages/assortment/categories/addSubcategory/subcategory-add";
import ProductsLayout from "./routes/auth/panel/panel-pages/assortment/products/products-layout";
import ProductsListPage from "./routes/auth/panel/panel-pages/assortment/products/list/products-list";
import ProductAddPage from "./routes/auth/panel/panel-pages/assortment/products/add/product-add";
import ProductEditPage from "./routes/auth/panel/panel-pages/assortment/products/edit/product-edit";
import GeneralSettingsPage from "./routes/auth/panel/panel-pages/settings/general-settings/general-settings-page";
import KnowledgeBaseLayout from "./routes/auth/panel/panel-pages/posts/knowledge-base-layout";
import ArticlesListPage from "./routes/auth/panel/panel-pages/posts/articles/list/articles-list";
import TagsListPage from "./routes/auth/panel/panel-pages/posts/tags/list/tags-list";
import TagAddPage from "./routes/auth/panel/panel-pages/posts/tags/add/tag-add";
import TagEditPage from "./routes/auth/panel/panel-pages/posts/tags/edit/tag-edit";
import TagsLayout from "./routes/auth/panel/panel-pages/posts/tags/tags-layout";
import ArticlesLayout from "./routes/auth/panel/panel-pages/posts/articles/articles-layout";
import ArticleAddPage from "./routes/auth/panel/panel-pages/posts/articles/add/article-add";
import ArticleEditPage from "./routes/auth/panel/panel-pages/posts/articles/edit/article-edit";
import OrdersLayout from "./routes/auth/panel/panel-pages/orders/orders-layout";
import {
  OrdersListAllPage,
  OrdersListNewsPage,
  OrdersListInprogressPage,
  OrdersListArchivedPage,
} from "./routes/auth/panel/panel-pages/orders/list/orders-list";
import OrdersManagePage from "./routes/auth/panel/panel-pages/orders/manage/order-manage";
import CustomersLayout from "./routes/auth/panel/panel-pages/customer/customers-layout";
import CustomersListPage from "./routes/auth/panel/panel-pages/customer/list/customers-list";
import CustomerEditPage from "./routes/auth/panel/panel-pages/customer/edit/customer-edit";
import AuthorsLayout from "./routes/auth/panel/panel-pages/posts/authors/authors-layout";
import AuthorEditPage from "./routes/auth/panel/panel-pages/posts/authors/edit/author-edit";
import AuthorAddPage from "./routes/auth/panel/panel-pages/posts/authors/add/author-add";
import AuthorsListPage from "./routes/auth/panel/panel-pages/posts/authors/list/authors-list";

function App() {
  const theme = useTheme();

  return (
    <div id="app" className={`theme-${theme}`}>
      <MultiRoutes>
        <AuthRoute path={RoutePath.AUTH} element={<PanelLayout />}>
          <AuthRoute path={RoutePath.PANEL} element={<PanelPage />}>
            <AuthRoute path={RoutePath.PANEL_ORDERS} element={<OrdersLayout />}>
              <AuthRoute path={RoutePath.PANEL_ORDERS_MANAGE} element={<OrdersManagePage />} />
              <AuthRoute path={RoutePath.PANEL_ORDERS_LIST} element={<OrdersListAllPage />} />
              <AuthRoute path={RoutePath.PANEL_ORDERS_LIST_NEW} element={<OrdersListNewsPage />} />
              <AuthRoute path={RoutePath.PANEL_ORDERS_LIST_INPROGRESS} element={<OrdersListInprogressPage />} />
              <AuthRoute path={RoutePath.PANEL_ORDERS_LIST_ARCHIVED} element={<OrdersListArchivedPage />} />
              {/* <AuthRoute path={RoutePath.PANEL_ORDERS_ADD} element={<ProjectAddPage />} />
              <AuthRoute path={RoutePath.PANEL_ORDERS_EDIT} element={<ProjectEditPage />} /> */}
            </AuthRoute>
            <AuthRoute path={RoutePath.PANEL_CUSTOMERS} element={<CustomersLayout />}>
              <AuthRoute path={RoutePath.PANEL_CUSTOMERS_LIST} element={<CustomersListPage />} />
              <AuthRoute path={RoutePath.PANEL_CUSTOMERS_MANAGE} element={<CustomerEditPage />} />
            </AuthRoute>
            <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE} element={<KnowledgeBaseLayout />}>
              <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES} element={<ArticlesLayout />}>
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_LIST} element={<ArticlesListPage />} />
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_ADD} element={<ArticleAddPage />} />
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_EDIT} element={<ArticleEditPage />} />
              </AuthRoute>
              <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_TAGS} element={<TagsLayout />}>
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_LIST} element={<TagsListPage />} />
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_ADD} element={<TagAddPage />} />
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_EDIT} element={<TagEditPage />} />
              </AuthRoute>
              <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_AUTHORS} element={<AuthorsLayout />}>
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_AUTHORS_LIST} element={<AuthorsListPage />} />
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_AUTHORS_ADD} element={<AuthorAddPage />} />
                <AuthRoute path={RoutePath.PANEL_KNOWLEDGEBASE_AUTHORS_EDIT} element={<AuthorEditPage />} />
              </AuthRoute>
            </AuthRoute>
            <AuthRoute path={RoutePath.PANEL_ASSORTMENT} element={<SettingsLayout />}>
              <AuthRoute path={RoutePath.PANEL_ASSORTMENT_CATEGORIES} element={<CategoriesLayout />}>
                <AuthRoute path={RoutePath.PANEL_ASSORTMENT_CATEGORIES_LIST} element={<CategoriesListPage />} />
                <AuthRoute path={RoutePath.PANEL_ASSORTMENT_CATEGORIES_ADDSUBCATEGORY} element={<SubcategoryAddPage />} />
                <AuthRoute path={RoutePath.PANEL_ASSORTMENT_CATEGORIES_ADD} element={<CategoryAddPage />} />
                <AuthRoute path={RoutePath.PANEL_ASSORTMENT_CATEGORIES_EDIT} element={<CategoryEditPage />} />
              </AuthRoute>
              <AuthRoute path={RoutePath.PANEL_ASSORTMENT_PRODUCTS} element={<ProductsLayout />}>
                <AuthRoute path={RoutePath.PANEL_ASSORTMENT_PRODUCTS_LIST} element={<ProductsListPage />} />
                <AuthRoute path={RoutePath.PANEL_ASSORTMENT_PRODUCTS_ADD} element={<ProductAddPage />} />
                <AuthRoute path={RoutePath.PANEL_ASSORTMENT_PRODUCTS_EDIT} element={<ProductEditPage />} />
              </AuthRoute>
            </AuthRoute>
            <AuthRoute path={RoutePath.PANEL_USERS} element={<UsersLayout />}>
              <AuthRoute path={RoutePath.PANEL_USERS_LIST} element={<UsersListPage />} />
              <AuthRoute path={RoutePath.PANEL_USERS_ADD} element={<UserAddPage />} />
              <AuthRoute path={RoutePath.PANEL_USERS_EDIT} element={<UserEditPage />} />
            </AuthRoute>
            <AuthRoute path={RoutePath.PANEL_SETTINGS} element={<SettingsLayout />}>
              <AuthRoute path={RoutePath.PANEL_SETTINGS_ACCOUNT} element={<AccountPage />} />
              <AuthRoute path={RoutePath.PANEL_SETTINGS_GENERAL_SETTINGS} element={<GeneralSettingsPage />} />
              <AuthRoute path={RoutePath.PANEL_SETTINGS_LANGUAGES} element={<LangsLayout />}>
                <AuthRoute path={RoutePath.PANEL_SETTINGS_LANGUAGES_LIST} element={<LangsListPage />} />
                <AuthRoute path={RoutePath.PANEL_SETTINGS_LANGUAGES_ADD} element={<LangAddPage />} />
                <AuthRoute path={RoutePath.PANEL_SETTINGS_LANGUAGES_EDIT} element={<LangEditPage />} />
              </AuthRoute>
            </AuthRoute>
            <AuthRoute path={"*"} element={<NotFoundPage />} />
          </AuthRoute>
          <AuthRoute path={"*"} element={<NotFoundPage />} />
        </AuthRoute>
        <Route element={<SingleBoxLayout />}>
          <NoAuthRoute path={RoutePath.REQUEST_RESET_PASSWORD} element={<RequestResetPasswordPage />} />
          <NoAuthRoute path={RoutePath.RESET_PASSWORD} element={<ResetPasswordPage />} />
          <NoAuthRoute path={RoutePath.SIGN_IN} element={<SignInPage />} />
          <NoAuthRoute path={RoutePath.HOME} element={<SignInPage />} />
          <Route path={"*"} element={<SignInPage />} />
        </Route>
      </MultiRoutes>
    </div>
  );
}

export default App;
