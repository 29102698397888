import React from "react";
import { useForm } from "react-hook-form";
import { SignInDto } from "../../../../../api/auth/auth.dto";
import LoadingBtn from "../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../libs/forms/validators/validators";
import TextBlock from "../../../../../libs/text-block/text-block";
import { RoutePath } from "../../../../../route-paths";
import TopLink from "../../../../components/top-link/top-link";
import { useSignIn } from "./SignInDataSlice";

interface SignInFormProps {}

const SignInForm = (props: SignInFormProps) => {
  const { signIn, status } = useSignIn();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInDto>({
    // defaultValues: {
    //   email: "rootadmin@codeebo.com",
    //   password: "Qwe123",
    // },
  });

  const onSubmit = (data: SignInDto) => {
    signIn(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {/* <TextBlock>
        <br />
        <p className="my-0 pt-0">Dostępne konta:</p>
        <ul className="my-0 pt-0">
          <li>rootadmin</li>
          <li>sprzedawca</li>
          <li>copywriter</li>
          <li>client</li>
        </ul>
      </TextBlock> */}
      <TextInput
        id="email"
        label="Email"
        validation_msg={errors?.email?.message}
        inputProps={{
          ...register("email", Validators.email),
          type: "email",
        }}
      />
      <TextInput
        id="password"
        label="Hasło"
        validation_msg={errors?.password?.message}
        inputProps={{
          ...register("password", Validators.password),
          type: "password",
        }}
      />
      <LoadingBtn type="submit" status={status}>
        Zaloguj
      </LoadingBtn>
      <TextBlock className="mt-2 mt-4 text-right">
        <TopLink to={RoutePath.REQUEST_RESET_PASSWORD} className="weight-500 primary">
          Resetuj hasło
        </TopLink>
      </TextBlock>
    </Form>
  );
};

export default SignInForm;
