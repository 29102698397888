import React from "react";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Api from "src/api/Api";
import { NameLangPrefixDto, ProdVariantColDto } from "src/api/prodVariant/prodVariant.dto";
import { useQuery } from "src/api/useQuerySlice";
import BreakTitle from "src/app/components/BreakTitle/BreakTitle";
import { useGlobalLangs } from "src/app/components/GlobalLangs/GlobalLangsSlice";
import { ModalContext } from "src/app/components/Modal/ModalContext";
import { emojiFlags } from "src/features/flags-emoji";
import { langNames_to_Jsons, langNames_to_Objects } from "src/features/lang-names-tools";
import LoadingBtn from "src/libs/buttons/Button/LoadingBtn";
import CheckBoxInput from "src/libs/forms/CheckBox/CheckBox";
import Form from "src/libs/forms/Form/Form";
import TextInput from "src/libs/forms/TextBox/TextInput";
import TextLangBox from "src/libs/forms/TextLangBox/TextLangBox";
import Validators from "src/libs/forms/validators/validators";

// import "./variant-manager-modal.scss";

interface Props {
  productRootId: number;
  prodVariantCol?: ProdVariantColDto;
}

interface RawProdVariantColDto extends Omit<ProdVariantColDto, "id" | "productRootId" | "langNames"> {
  langNames: string[];
}

const AddEditVariantColModal = (props: Props) => {
  const { productRootId, prodVariantCol: tradeUnit } = props;
  const { langs: langsData } = useGlobalLangs();

  const { call: addCall, status: addStatus } = useQuery<void, ProdVariantColDto>("ProdVariant/AddVariantCol", [Api.ProdVariant.AddVariantCol], {
    callOnLoad: false,
  });
  const { call: editCall, status: editStatus } = useQuery<void, ProdVariantColDto>("ProductVariant/UpdateVariantCol", [Api.ProdVariant.UpdateVariantCol], {
    callOnLoad: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<RawProdVariantColDto>({ defaultValues: {} });

  const { handleConfirm } = useContext(ModalContext);

  const onSubmit = (data: RawProdVariantColDto) => {
    try {
    } catch (error) {}
    var dto = {
      ...data,
      langNames: langNames_to_Objects(data.langNames),
      productRootId: productRootId,
    } as ProdVariantColDto;

    if (tradeUnit && tradeUnit.id) {
      dto.id = tradeUnit.id;
      editCall(dto);
    } else {
      addCall(dto);
    }
  };

  useEffect(() => {
    if (addStatus === "finished" || editStatus === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, editStatus]);

  // useEffect(() => {
  //   if (tradeUnit !== null) {
  //     var langNamesJsons = langNames_to_Jsons(tradeUnit?.langNames??[]);
  //     if(langsData) {
  //       for (let i = 0; i < langsData.length; i++) {

  //       }
  //     }
  //     var formInitialData = {
  //       ...tradeUnit,
  //       langNames:
  //     } as RawTradeUnit;
  //     reset(formInitialData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tradeUnit]);

  useEffect(() => {
    var langNames;
    if (langsData !== null) {
      langNames = langsData?.map((x) => {
        var copyOfnameLang = tradeUnit?.langNames?.find((y) => y.langPrefix === x.prefix);
        return { name: copyOfnameLang?.name ?? "", langPrefix: x.prefix } as NameLangPrefixDto;
      });
    }
    reset({ ...(tradeUnit ?? {}), langNames: langNames_to_Jsons(langNames ?? []) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langsData, tradeUnit]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="add-edit-modal" direction="row" cols={3}>
      <BreakTitle>Names</BreakTitle>
      <div className="langs-wrapper d-flex">
        <div className="lang-wrapper d-flex">
          <TextInput
            id="rootName"
            label="Global"
            validation_msg={errors?.rootName?.message}
            required
            inputProps={{
              ...register("rootName", Validators.required),
            }}
          />
          {langsData
            ? langsData.map((item, index) => (
                <TextLangBox
                  key={index}
                  id={`langNames.${index}`}
                  label={`${emojiFlags(item.prefix)} Name ${item.prefix}`}
                  validation_msg={errors?.langNames?.[index]?.message}
                  field="name"
                  inputProps={{
                    placeholder: "Name",
                    ...register(`langNames.${index}`),
                  }}
                />
              ))
            : null}
        </div>
      </div>
      <br />
      <br />
      <BreakTitle>Settings</BreakTitle>
      <CheckBoxInput
        id="isFilter"
        description="As filter"
        validation_msg={errors?.isFilter?.message}
        inputProps={{
          ...register("isFilter"),
        }}
      />
      <CheckBoxInput
        id="isDropdown"
        description="Variant selector"
        validation_msg={errors?.isDropdown?.message}
        inputProps={{
          ...register("isDropdown"),
        }}
      />
      <br />
      <div className="ml-auto">
        {tradeUnit ? (
          <LoadingBtn type="submit" status={editStatus} className="ml-auto">
            Save
          </LoadingBtn>
        ) : (
          <LoadingBtn type="submit" status={addStatus} className="ml-auto">
            Add
          </LoadingBtn>
        )}
      </div>
    </Form>
  );
};

export default AddEditVariantColModal;
