import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Api from "../../../../../../../../../api/Api";
import { SystemScopeEnum } from "../../../../../../../../../api/enums/enums";
import { FaqDto } from "../../../../../../../../../api/faq/faq.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
// import WYSIWYGEditor from "../../../../../../../../../libs/forms/WysiwigEditor/WysiwigEditor";
import { ModalContext } from "../../../../../../../../components/Modal/ModalContext";

interface FaqAddEditModalProps {
  itemId: number;
  faq?: FaqDto;
}

interface FormDto {
  question: string;
  response: string;
}

const FaqAddEditModal: React.FC<FaqAddEditModalProps> = (props) => {
  const { itemId, faq } = props;
  const { call, status } = useQuery<void, FaqDto>("Category/addFaq", [Api.Category.AddFaq], { callOnLoad: false });
  const { call: editCall, status: editStatus } = useQuery<void, FaqDto>("Category/updateFaq", [Api.Category.UpdateFaq], {
    callOnLoad: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormDto>({ defaultValues: {} });

  const { handleConfirm } = useContext(ModalContext);

  const onSubmit = (data: FormDto) => {
    if (faq) {
      editCall({ ...faq, ...data, scopeId: itemId });
    } else {
      var addDto = {
        scope: SystemScopeEnum.CategoryLang,
        scopeId: itemId,
        ...data,
      } as FaqDto;
      call(addDto);
    }
  };

  useEffect(() => {
    if (status === "finished" || editStatus === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, editStatus]);

  useEffect(() => {
    if (faq !== null) {
      reset(faq);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faq]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} direction="row" cols={1}>
      <TextInput
        id="question"
        label="Question"
        validation_msg={errors?.question?.message}
        required
        inputProps={{
          ...register("question", Validators.required),
        }}
      />
      <TextInput
        id="response"
        label="Response"
        validation_msg={errors?.response?.message}
        required
        inputProps={{
          type: "textarea",
          ...register("response", Validators.required),
        }}
      />
      <br />
      <div className="ml-auto">
        {faq ? (
          <LoadingBtn type="submit" status={editStatus} className="ml-auto">
            Save
          </LoadingBtn>
        ) : (
          <LoadingBtn type="submit" status={status} className="ml-auto">
            Add
          </LoadingBtn>
        )}
      </div>
    </Form>
  );
};

export default FaqAddEditModal;
