import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import ToastsStore from "src/features/toast-store/ToastsStore";
import { AddUserDto } from "../../../../../../../../api/user/user.dto";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../../../../../../route-paths";

interface SignInFormProps { }

const UserAddForm = (props:SignInFormProps) => {
  const { call, status: updateStatus } = useQuery<void, AddUserDto>('user/add', [Api.User.Add], { callOnLoad: false });
  const { register, handleSubmit, formState: { errors }, reset } = useForm<AddUserDto>({ defaultValues: {} });
  const navigate = useNavigate();

  const onSubmit = async (dto: AddUserDto) => {
    call({ ...dto });
  };

  useEffect(() => {
    if (updateStatus === 'finished') {
      reset()
      ToastsStore.success("Użytkownik został dodany");
      navigate(RoutePath.PANEL_USERS_LIST as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus])


  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="name"
          label="Nazwa"
          validation_msg={errors?.name?.message}
          inputProps={{
            ...register("name", Validators.required)
          }}
        />
        <TextInput
          id="email"
          label="Email"
          validation_msg={errors?.email?.message}
          inputProps={{
            ...register("email", Validators.required),
            type: "email"
          }}
        />
        <TextInput
          id="phone"
          label="Telefon"
          validation_msg={errors?.phone?.message}
          inputProps={{
            ...register("phone", Validators.required)
          }}
        />
        <TextInput
          id="password"
          label="Hasło"
          validation_msg={errors?.password?.message}
          inputProps={{
            ...register("password", Validators.password),
            type: "password",
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">Save</LoadingBtn>
      </Form>
    </>
  );
};

export default UserAddForm;
