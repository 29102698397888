import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import SelectBox from "../../../../../../../../../libs/forms/SelectBox/SelectBox";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { ModalContext } from "../../../../../../../../components/Modal/ModalContext";
import { SocialMediaIconEnum, SocialMediaIconEnum_Parser, SocialMediaLinkDto } from "src/api/author/author.dto";
import { mapEnum } from "src/features/enumMap";

interface SocialMediaAddEditModalProps {
  authorLangId: number;
  socialmedia?: SocialMediaLinkDto;
}
interface SocialMediaLinkDtoWithAuthorLangId {
  authorLangId: number;
  dto: SocialMediaLinkDto;
}
const SocialMediaAddEditModal: React.FC<SocialMediaAddEditModalProps> = (props) => {
  const { authorLangId, socialmedia } = props;
  const { call, status } = useQuery<void, SocialMediaLinkDtoWithAuthorLangId>("author/AddSocialMediaLink", [Api.Author.AddSocialMediaLink], {
    callOnLoad: false,
  });
  const { call: editCall, status: editStatus } = useQuery<void, SocialMediaLinkDtoWithAuthorLangId>(
    "author/UpdateSocialMediaLinkAsync",
    [Api.Author.UpdateSocialMediaLink],
    {
      callOnLoad: false,
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<any>({ defaultValues: {} });
  const { handleConfirm } = useContext(ModalContext);

  const onSubmit = (data: any) => {
    var dtoWithAuthorLangId = {
      authorLangId: authorLangId,
      dto: {
        id: socialmedia?.id,
        ...data,
      },
    } as SocialMediaLinkDtoWithAuthorLangId;

    if (socialmedia) {
      editCall(dtoWithAuthorLangId);
    } else {
      call(dtoWithAuthorLangId);
    }
  };

  useEffect(() => {
    if (status === "finished" || editStatus === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, editStatus]);

  useEffect(() => {
    if (socialmedia !== null) {
      reset(socialmedia);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialmedia]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} direction="row" cols={1}>
      <SelectBox
        id="icon"
        label="Icon"
        validation_msg={errors?.icon?.message}
        required
        maxHeight={"md"}
        options={mapEnum(SocialMediaIconEnum, (x: SocialMediaIconEnum) => {
          return { id: x, label: SocialMediaIconEnum_Parser(x) };
        })}
        inputProps={{
          ...register("icon", Validators.required),
        }}
      />
      <TextInput
        id="name"
        label="Name"
        validation_msg={errors?.name?.message}
        required
        inputProps={{
          ...register("name", Validators.required),
        }}
      />
      <TextInput
        id="url"
        label="Url"
        validation_msg={errors?.url?.message}
        required
        inputProps={{
          ...register("url", Validators.required),
        }}
      />
      <br />
      <div className="ml-auto">
        {socialmedia ? (
          <LoadingBtn type="submit" status={editStatus} className="ml-auto">
            Save
          </LoadingBtn>
        ) : (
          <LoadingBtn type="submit" status={status} className="ml-auto">
            Add
          </LoadingBtn>
        )}
      </div>
    </Form>
  );
};

export default SocialMediaAddEditModal;
