import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { ArticleLangDto, ArticleRootDto } from "../../../../../../../../api/article/article.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import BreakTitle from "../../../../../../../components/BreakTitle/BreakTitle";
import { LangDto } from "../../../../../../../../api/lang/lang.dto";
import LangSwitch from "../../../../../../../components/LangSwitch/LangSwitch";
import MediaList from "./media-list/media-list";
import ArticleEditForm from "./forms/ArticleEditForm";
import ArticleLangEditForm from "./forms/ArticleLangEditForm";
import ArticleLangNonExistTile from "./forms/ArticleLangNonExistTile";
import RelatedTags from "./related-tags/related-tags-list";
import RelatedAuthors from "./related-authors/related-authors-list";
import RelatedProducts from "./related-products/related-products-list";

const ArticleEditPage = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data: articleRoot } = useQuery<ArticleRootDto, number>("article/getRoot", [Api.Article.GetRoot, Number(id)], {
    statusResetDelay: 1000,
  });
  const { data: articleLangs } = useQuery<Array<ArticleLangDto>, number>("article/listLangs", [Api.Article.ListLang, Number(id)], {
    statusResetDelay: 1050,
  });

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_EDIT, { id: id })} title="Edit article" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Edit article</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <BreakTitle size={"xl"}>Shared settings (no lang)</BreakTitle>
          </Col>
          <Col size={[4, 6, 10, 12]}>
            <Tile>
              <h2 className="title">Base informations</h2>
              <ArticleEditForm data={articleRoot} />
            </Tile>
          </Col>
          <Col size={[4, 6, 12, 12]} vif={!!articleRoot && !!articleRoot?.id}>
            <Tile>
              <RelatedAuthors articleRootId={articleRoot?.id ?? -1} />
            </Tile>
            <br />
            <Tile>
              <RelatedTags articleRootId={articleRoot?.id ?? -1} />
            </Tile>
          </Col>
          <Col size={[4, 6, 12, 12]} vif={!!articleRoot && !!articleRoot?.id}>
            <Tile>
              <RelatedProducts articleRootId={articleRoot?.id ?? -1} />
            </Tile>
          </Col>
        </PanelRow>
        <LangSwitch
          render={({ langs, activeLang }) =>
            articleRoot && activeLang
              ? langs?.map((lang: LangDto, index: number) => {
                  const articleLangIndex = articleLangs?.findIndex((x) => x.langId === lang.id) ?? -1;
                  const rootContainsLang = articleLangIndex !== -1;
                  if (!rootContainsLang) return <ArticleLangNonExistTile lang={lang} key={index} />;
                  const articleLang = articleLangs![articleLangIndex];
                  return rootContainsLang ? (
                    <PanelRow className={activeLang.id === lang.id ? "" : "d-none"} key={index}>
                      <Col size={[6, 6, 10, 12]}>
                        <Tile>
                          <h2 className="title">Base informations</h2>
                          <ArticleLangEditForm selectedLang={lang} />
                        </Tile>
                      </Col>
                      <Col size={[6, 6, 12, 12]}>
                        <Tile>{articleLang.id ? <MediaList scopeId={articleLang.id!} /> : null}</Tile>
                      </Col>
                    </PanelRow>
                  ) : (
                    <ArticleLangNonExistTile lang={lang} key={index} />
                  );
                })
              : null
          }
        />
      </PanelGrid>
    </div>
  );
};

export default ArticleEditPage;
