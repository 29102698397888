import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import { DeliveryMethodEnum_Parser, OrderDto, PaymentMethodEnum_Parser } from "../../../../../../../../api/order/order.dto";
import DateHelper from "../../../../../../../../features/date-helper";
import TextBlock from "../../../../../../../../libs/text-block/text-block";
import PanelRow from "src/libs/grid/panel-row";
import Col from "src/libs/grid/col";

interface BasicInfoFormProps {
  order: OrderDto;
}

interface BasicOrderInfo {
  id: number;
  createdDate: string;
  totalPrice: string;
  deliveryMethod?: string;
  deliveryPrice: string;
  paymentMethod?: string;
  currency?: string;
  currencyRatio?: string;
}

const BasicInfoForm: React.FC<BasicInfoFormProps> = (props) => {
  const { order } = props;
  const { register, reset } = useForm<BasicOrderInfo>({ defaultValues: {} });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const capitalize = (str: string) => {
    if (str && str.length > 1) return str.charAt(0).toUpperCase() + str.slice(1);
    else return "-";
  };

  useEffect(() => {
    const orderBasicInfo = {
      id: order.id,
      createdDate: DateHelper.ConvertZone__API_to_UI__formated(order.createdDate.toString()),
      totalPrice: order.totalPrice?.toFixed(2) + " zł",
      paymentMethod: PaymentMethodEnum_Parser(order.paymentMethod),
      deliveryPrice: order.deliveryPrice?.toFixed(2) + " zł",
      deliveryMethod: DeliveryMethodEnum_Parser(order.deliveryMethod),
      currency: order.currency?.toUpperCase(),
      currencyRatio: order.currencyRatio,
    } as BasicOrderInfo;
    reset(orderBasicInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return (
    <>
      <Form>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Basic informations</h3>
          <PanelRow>
            <Col size={[6, 6, 12]}>
              <TextInput
                id="id"
                label="Id"
                inputProps={{
                  ...register("id"),
                  disabled: true,
                }}
              />
            </Col>
            <Col size={[6, 6, 12]}>
              <TextInput
                id="createdDate"
                label="Order date"
                inputProps={{
                  ...register("createdDate"),
                  disabled: true,
                }}
              />
            </Col>
            <Col size={[6, 6, 12]}>
              <TextInput
                id="paymentMethod"
                label="Payment method"
                inputProps={{
                  ...register("paymentMethod"),
                  disabled: true,
                }}
              />
            </Col>
            <Col size={[6, 6, 12]}>
              <TextInput
                id="totalPrice"
                label="Total price"
                inputProps={{
                  ...register("totalPrice"),
                  disabled: true,
                }}
              />
            </Col>

            {order.currency?.toLowerCase() === "pln" ? null : (
              <>
                <Col size={[6, 6, 12]}>
                  <TextInput
                    id="currency"
                    label="Currency"
                    inputProps={{
                      ...register("currency"),
                      disabled: true,
                    }}
                  />
                </Col>
                <Col size={[6, 6, 12]}>
                  <TextInput
                    id="currencyRatio"
                    label="Currency ratio"
                    inputProps={{
                      ...register("currencyRatio"),
                      disabled: true,
                    }}
                  />
                </Col>
              </>
            )}

            <Col size={[6, 6, 12]}>
              <TextInput
                id="deliveryMethod"
                label="Delivery method"
                inputProps={{
                  ...register("deliveryMethod"),
                  disabled: true,
                }}
              />
            </Col>
            <Col size={[6, 6, 12]}>
              <TextInput
                id="deliveryPrice"
                label="Delivery price"
                inputProps={{
                  ...register("deliveryPrice"),
                  disabled: true,
                }}
              />
            </Col>
          </PanelRow>
          {order.totalPrice === null ? (
            <TextBlock>
              <p>
                <b className="red">+ individual order valuation</b>
              </p>
            </TextBlock>
          ) : null}
        </div>
      </Form>
    </>
  );
};

export default BasicInfoForm;
