import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Api from "src/api/Api";
import { NameLangPrefixDto, ProdVariantTradeUnitDto } from "src/api/prodVariant/prodVariant.dto";
import { useQuery } from "src/api/useQuerySlice";
import BreakTitle from "src/app/components/BreakTitle/BreakTitle";
import { useGlobalLangs } from "src/app/components/GlobalLangs/GlobalLangsSlice";
import { ModalContext } from "src/app/components/Modal/ModalContext";
import { emojiFlags } from "src/features/flags-emoji";
import { langNames_to_Jsons, langNames_to_Objects } from "src/features/lang-names-tools";
import LoadingBtn from "src/libs/buttons/Button/LoadingBtn";
import CheckBoxInput from "src/libs/forms/CheckBox/CheckBox";
import Form from "src/libs/forms/Form/Form";
import TextInput from "src/libs/forms/TextBox/TextInput";
import TextLangBox from "src/libs/forms/TextLangBox/TextLangBox";
import Validators from "src/libs/forms/validators/validators";
import Col from "src/libs/grid/col";
import Row from "src/libs/grid/row";

// import "./variant-manager-modal.scss";

interface Props {
  prodVariantId: number;
  prodVariantTradeUnit?: ProdVariantTradeUnitDto;
}

interface RawProdVariantTradeUnitDto extends Omit<ProdVariantTradeUnitDto, "id" | "prodVariantId" | "langNames"> {
  langNames: string[];
}

const AddEditVariantTradeUnitModal = (props: Props) => {
  const { prodVariantId, prodVariantTradeUnit } = props;
  const { langs: langsData } = useGlobalLangs();

  const { call: addCall, status: addStatus } = useQuery<void, ProdVariantTradeUnitDto>(
    "ProdVariant/AddVariantTradeUnit",
    [Api.ProdVariant.AddVariantTradeUnit],
    {
      callOnLoad: false,
    }
  );
  const { call: editCall, status: editStatus } = useQuery<void, ProdVariantTradeUnitDto>(
    "ProductVariant/UpdateVariantTradeUnit",
    [Api.ProdVariant.UpdateVariantTradeUnit],
    {
      callOnLoad: false,
    }
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<RawProdVariantTradeUnitDto>({ defaultValues: {} });

  const modifierType = watch("isPromotionActive");
  const { handleConfirm } = useContext(ModalContext);

  const onSubmit = (data: RawProdVariantTradeUnitDto) => {
    try {
    } catch (error) {}
    var dto = {
      ...data,
      langNames: langNames_to_Objects(data.langNames),
      prodVariantId: prodVariantId,
    } as ProdVariantTradeUnitDto;

    if (prodVariantTradeUnit && prodVariantTradeUnit.id) {
      dto.id = prodVariantTradeUnit.id;
      editCall(dto);
    } else {
      addCall(dto);
    }
  };

  useEffect(() => {
    if (addStatus === "finished" || editStatus === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, editStatus]);

  // useEffect(() => {
  //   if (tradeUnit !== null) {
  //     var langNamesJsons = langNames_to_Jsons(tradeUnit?.langNames??[]);
  //     if(langsData) {
  //       for (let i = 0; i < langsData.length; i++) {

  //       }
  //     }
  //     var formInitialData = {
  //       ...tradeUnit,
  //       langNames:
  //     } as RawTradeUnit;
  //     reset(formInitialData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tradeUnit]);

  useEffect(() => {
    var langNames;
    if (langsData !== null) {
      langNames = langsData?.map((x) => {
        var copyOfnameLang = prodVariantTradeUnit?.langNames?.find((y) => y.langPrefix === x.prefix);
        return { name: copyOfnameLang?.name ?? "", langPrefix: x.prefix } as NameLangPrefixDto;
      });
    }
    reset({ ...(prodVariantTradeUnit ?? {}), langNames: langNames_to_Jsons(langNames ?? []) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langsData, prodVariantTradeUnit]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="add-edit-modal">
      <BreakTitle>Names</BreakTitle>
      <Row gap={0}>
        <Col size={[3, 6, 12, 12]}>
          <TextInput
            id="rootName"
            label="Global"
            validation_msg={errors?.rootName?.message}
            required
            inputProps={{
              ...register("rootName", Validators.required),
            }}
          />
        </Col>
        {langsData
          ? langsData.map((item, index) => (
              <Col size={[3, 6, 12, 12]} key={index}>
                <TextLangBox
                  key={index}
                  id={`langNames.${index}`}
                  label={`${emojiFlags(item.prefix)} Name ${item.prefix}`}
                  validation_msg={errors?.langNames?.[index]?.message}
                  field="name"
                  inputProps={{
                    placeholder: "Name",
                    ...register(`langNames.${index}`),
                  }}
                />
              </Col>
            ))
          : null}
      </Row>

      {/* <div className="langs-wrapper d-flex">
        <div className="lang-wrapper d-flex">
          <TextInput
            id="rootName"
            label="Global"
            validation_msg={errors?.rootName?.message}
            required
            inputProps={{
              ...register("rootName", Validators.required),
            }}
          />

        </div>
      </div> */}
      <br />
      <br />
      <BreakTitle>Supply settings</BreakTitle>
      <Row gap={0}>
        <Col size={[3, 6, 12, 12]}>
          <TextInput
            id="supplyPL"
            label="SupplyPL"
            validation_msg={errors?.supplyPL?.message}
            required
            inputProps={{
              placeholder: "0",
              type: "number",
              step: 1,
              min: 0,
              ...register("supplyPL", Validators.required),
            }}
          />
        </Col>
      </Row>
      <Row gap={0}>
        <Col size={[3, 6, 12, 12]}>
          <TextInput
            id="supplyNL"
            label="SupplyNL"
            inputProps={{
              disabled: true,
              placeholder: "0",
              type: "number",
              step: 1,
              min: 0,
              ...register("supplyNL"),
            }}
          />
        </Col>
        <Col size={[3, 6, 12, 12]}>
          <TextInput
            id="idOfSupplyNL"
            label="Id of SupplyNL"
            inputProps={{
              ...register("idOfSupplyNL"),
            }}
          />
        </Col>
      </Row>
      <br />
      <br />
      <BreakTitle>Delivery policy</BreakTitle>
      <Row gap={0}>
        <Col size={[3, 6, 12, 12]}>
          <TextInput
            id="palleteTriggerCount"
            label="Pallete trigger count"
            validation_msg={errors?.palleteTriggerCount?.message}
            inputProps={{
              placeholder: "0",
              type: "number",
              step: 1,
              min: 0,
              ...register("palleteTriggerCount"),
            }}
          />
        </Col>
        <Col size={[3, 6, 12, 12]}>
          <TextInput
            id="maxCountPerOrder"
            label="Max count per order"
            validation_msg={errors?.maxCountPerOrder?.message}
            inputProps={{
              placeholder: "0",
              type: "number",
              step: 1,
              min: 0,
              ...register("maxCountPerOrder"),
            }}
          />
        </Col>
      </Row>
      <br />
      <br />
      <BreakTitle>Price policy</BreakTitle>
      <Row gap={0}>
        <Col size={[3, 6, 12, 12]}>
          <TextInput
            id="price"
            label="Base price [PLN]"
            validation_msg={errors?.price?.message}
            inputProps={{
              placeholder: "0",
              type: "number",
              step: 0.01,
              min: 0,
              ...register("price"),
            }}
          />
        </Col>
        <Col size={[3, 6, 12, 12]}>
          <CheckBoxInput
            id="isPromotionActive"
            label="Is promotion active?"
            validation_msg={errors?.isPromotionActive?.message}
            inputProps={{
              ...register("isPromotionActive"),
            }}
            description={"Promotion"}
          />
        </Col>

        {modifierType ? (
          <>
            <Col size={[3, 6, 12, 12]}>
              <TextInput
                id="promotionValue"
                label="Promotion value [%]"
                validation_msg={errors?.promotionValue?.message}
                inputProps={{
                  placeholder: "-10",
                  type: "number",
                  step: 0.01,
                  ...register("promotionValue", Validators.promotionPerecent),
                }}
              />
            </Col>
            <Col size={[3, 6, 12, 12]}>
              <TextInput
                id="last30DaysLowestPrice"
                label="Last 30 days min. price"
                validation_msg={errors?.last30DaysLowestPrice?.message}
                required
                inputProps={{
                  placeholder: "0",
                  type: "number",
                  step: 0.01,
                  min: 0,
                  ...register("last30DaysLowestPrice", Validators.required),
                }}
              />
            </Col>
          </>
        ) : null}
      </Row>
      <Row gap={0} align="center">
        <Col size={[3, 6, 12, 12]}>
          <TextInput
            id="minTradeUnitDivider"
            label="Trade unit divider"
            validation_msg={errors?.minTradeUnitDivider?.message}
            required
            inputProps={{
              placeholder: "1",
              type: "number",
              step: 0.001,
              ...register("minTradeUnitDivider", Validators.minOneRequired),
            }}
          />
        </Col>
        <Col size={[9, 6, 12, 12]}>
          <p className="pt-3">
            The <u>Trade unit divider</u> property is used to standardize the price relative to the smallest unit. For example, for a 20m hose reel, should be
            entered 20.
          </p>
        </Col>
      </Row>
      <br />
      <div className="ml-auto">
        {prodVariantTradeUnit ? (
          <LoadingBtn type="submit" status={editStatus} className="ml-auto">
            Save
          </LoadingBtn>
        ) : (
          <LoadingBtn type="submit" status={addStatus} className="ml-auto">
            Add
          </LoadingBtn>
        )}
      </div>
    </Form>
  );
};

export default AddEditVariantTradeUnitModal;
