import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import LoadingSpinner from "../../../../../../../components/LoadingSpinner/LoadingSpinner";
import Button from "src/libs/buttons/Button/Button";
import CopyToClipboard from "src/app/components/CopyToClipboard/CopyToClipboard";

const PaymentGateway: React.FC = () => {
  const { orderId = "" } = useParams<{ orderId: string }>();
  const [stripe, setStripe] = useState<any>(null);
  const { data, status, call } = useQuery<any, string>("Stripe/CreateCheckoutSession", [Api.Stripe.CreateCheckoutSession, orderId], { callOnLoad: false });

  useEffect(() => {
    const fetchStripe = async () => {
      const { loadStripe } = await import("@stripe/stripe-js");
      const stripeInstance = loadStripe(process.env.REACT_APP_STRIPE_PK_KEY ?? "");
      setStripe(stripeInstance);
    };

    fetchStripe();
  }, []);

  const redirectToPayment = () => {
    if (stripe && status === "finished" && data && data.id) {
      stripe.then((x: any) => {
        if (x) x.redirectToCheckout({ sessionId: data.id });
      });
    }
  };

  const createPaymentLink = () => {
    call(orderId);
  };

  return (
    <>
      <hr />
      <h3 className="pb-3">Payment gateway</h3>
      <div>
        {data && data.url ? (
          <div className="d-flex">
            {/* redirect using stripe official docs */}
            <Button onClick={redirectToPayment} size="xs" variant="outline">
              Redirect&nbsp;to&nbsp;gateway
            </Button>{" "}
            &nbsp;&nbsp;
            {/* redirect using unofficial method - just simple a with url from session object */}
            <a href={data.url} target="_blank" rel="noreferrer">
              <Button size="xs" variant="outline">
                Open&nbsp;gateway&nbsp;in&nbsp;new&nbsp;tab
              </Button>
            </a>
          </div>
        ) : (
          <Button onClick={createPaymentLink} size="xs" variant="outline">
            Create 24h payment link
          </Button>
        )}
        <br />
        <LoadingSpinner size="xs" active={status === "loading"} invert />
        {data && data.url ? CopyToClipboard(data.url) : null}
      </div>
    </>
  );
};

export default PaymentGateway;
