import React from "react";
import { generatePath } from "react-router-dom";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import ArticleAddForm from "./article-add-form/ArticleAddForm";

const ArticleAddPage = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_LIST} title="List" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_ADD)} title="New article" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>New article</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[4, 6, 10, 12]}>
            <Tile>
              <h2 className="title">Basic informations</h2>
              <ArticleAddForm />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default ArticleAddPage;
