import { useContext, useEffect } from "react";
import { useQuery, useQueryRefetch } from "../../../../../../../../../api/useQuerySlice";
import { EmptyAddCategoryLang } from "../../../../../../../../../api/category/category.dto";
import Api from "../../../../../../../../../api/Api";
import Button from "../../../../../../../../../libs/buttons/Button/Button";
import Tile from "../../../../../../../../components/Tile/Tile";
import { LangDto } from "../../../../../../../../../api/lang/lang.dto";
import { useParams } from "react-router-dom";
import PanelRow from "../../../../../../../../../libs/grid/panel-row";
import Col from "../../../../../../../../../libs/grid/col";
import { LangSwitchContext } from "../../../../../../../../components/LangSwitch/LangContext";
import ToastsStore from "src/features/toast-store/ToastsStore";

interface CategoryLangNonExistTileProps {
  lang: LangDto;
}

const CategoryLangNonExistTile = (props: CategoryLangNonExistTileProps) => {
  const { activeLang } = useContext(LangSwitchContext);
  const { lang } = props;
  const { id = "" } = useParams<{ id: string }>();
  const { refetch } = useQueryRefetch();
  const { call: callAddCatLang, status: statusAddCatLang } = useQuery<void, EmptyAddCategoryLang>("category/addEmptyLang", [Api.Category.AddEmptyLang], {
    statusResetDelay: 1000,
    callOnLoad: false,
  });
  const { call: callCopyCatLang, status: statusCopyCatLang } = useQuery<void, EmptyAddCategoryLang>("category/copyLang", [Api.Category.CopyLang], {
    statusResetDelay: 1000,
    callOnLoad: false,
  });

  useEffect(() => {
    if (statusAddCatLang === "finished" || statusCopyCatLang === "finished") {
      ToastsStore.success(`${lang.name} language has been added"`);
      refetch("category/getRoot");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCopyCatLang, statusAddCatLang]);

  return (
    <PanelRow className={activeLang?.id === lang.id ? "" : "d-none"}>
      <Col size={12}>
        <Tile>
          <h3 className="title">No translation in: {lang.name}</h3>
          <div className="d-flex pt-2">
            <Button variant="like-input" className="mr-2" onClick={() => callAddCatLang({ langId: lang.id!, categoryRootId: Number(id) })}>
              Add new empty
            </Button>
            <Button variant="like-input" onClick={() => callCopyCatLang({ langId: lang.id!, categoryRootId: Number(id) })}>
              Add and fill with defaults
            </Button>
          </div>
        </Tile>
      </Col>
    </PanelRow>
  );
};

export default CategoryLangNonExistTile;
