import { generatePath } from "react-router-dom";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { CategoryRootDto } from "../../../../../../../../../api/category/category.dto";
import { LangDto } from "../../../../../../../../../api/lang/lang.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import GenericList, { NumberStringTuple } from "../../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../../route-paths";
import Menu, { MenuItemType } from "../../../../../../../../components/Menu/menu";
import { showSlideOverlay } from "../../../../../../../../components/router-transition/routerTransitionSlice";
import TopLink from "src/app/components/top-link/top-link";

interface CategoriesListProps {}

const CategoriesList = (props: CategoriesListProps) => {
  const { data, status, call } = useQuery<Array<CategoryRootDto>>("category/listRoot", [Api.Category.ListRoot]);
  const { data: avaliableLangs } = useQuery<Array<LangDto>>("lang/list", [Api.Lang.List]);

  const { status: delStatus, call: callDeleteRoot } = useQuery<void, number>("category/deleteRoot", [Api.Category.DeleteRoot], { callOnLoad: false });
  // const { status: toggleActiveStatus, call: callToggleActive } = useQuery<void, string>("category/toggleActive", [Api.Category.ToggleActive], {
  //   callOnLoad: false,
  // });

  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    if (delStatus === "finished") {
      ToastsStore.success("Category has been deleted");
      call();
    }
  }, [delStatus]);

  const handleDeleteRoot = (categoryRoot: CategoryRootDto) => {
    Confirm(`Are you sure you want to delete category ${categoryRoot.rootName}?`, () => callDeleteRoot(categoryRoot.id));
  };

  // useEffectAfterMounted(() => {
  //   if (toggleActiveStatus === "finished") {
  //     ToastsStore.success("Category activated/deactivated");
  //     call();
  //   }
  // }, [toggleActiveStatus]);

  const standardCols: NumberStringTuple[] = [
    ["Id", "id", 1],
    ["Name", "", 5, x=><TopLink className="primary" to={generatePath(RoutePath.PANEL_ASSORTMENT_CATEGORIES_EDIT, { id: x.id?.toString() })}>{x.rootName}</TopLink>],
    [
      "Languages",
      "",
      2,
      (x: CategoryRootDto) => {
        const langPrefixes = avaliableLangs?.filter((y) => x.categoryLangs?.findIndex((z) => z.langId === y.id) !== -1).map((x) => x.prefix);
        return `[${langPrefixes?.map((y) => y)}]`.replaceAll(",", ", ").replace("[]", "-");
      },
    ],
    // [
    //   "Aktywny?",
    //   "",
    //   2,
    //   (dto) => (
    //     <div className="ml-2  d-inline-block clickable p-1" onClick={() => callToggleActive(dto.id)} title={dto.isActive ? "Aktywny" : "Nieaktywny"}>
    //       {dto.isActive ? "🟢" : "⚫️ Nieaktywny"}
    //     </div>
    //   ),
    // ],
  ];

  const standardActions = (actionsItems: (categoryRoot: CategoryRootDto) => Array<MenuItemType>): NumberStringTuple => [
    "",
    "",
    5,
    (categoryRoot: CategoryRootDto) => <Menu header="Options" items={actionsItems(categoryRoot)} />,
  ];

  const actionsItems__addSubcategory = (categoryRoot: CategoryRootDto) => {
    return {
      label: <>Add&nbsp;subcategory</>,
      onAction: () =>
        dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_ASSORTMENT_CATEGORIES_ADDSUBCATEGORY, { parentId: categoryRoot?.id?.toString() }))),
    };
  };
  // const actionsItems__toggleActive = (categoryRoot: CategoryRootDto) => {
  //   return {
  //     label: categoryRoot.isActive ? "Dezaktywuj" : "Aktywuj",
  //     onAction: () => callToggleActive(category.id),
  //   };
  // };
  const actionsItems__edit = (categoryRoot: CategoryRootDto) => {
    return {
      label: "Edit",
      onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_ASSORTMENT_CATEGORIES_EDIT, { id: categoryRoot.id?.toString() }))),
    };
  };
  const actionsItems__delete = (categoryRoot: CategoryRootDto) => {
    return { label: "Delete", onAction: () => handleDeleteRoot(categoryRoot) };
  };

  const standardActionsItems = (categoryRoot: CategoryRootDto): Array<MenuItemType> => {
    return [
      actionsItems__edit(categoryRoot),
      actionsItems__addSubcategory(categoryRoot),
      // actionsItems__toggleActive(categoryRoot),
      actionsItems__delete(categoryRoot),
    ];
  };
  const lastActionsItems = (categoryRoot: CategoryRootDto): Array<MenuItemType> => {
    return [actionsItems__edit(categoryRoot), actionsItems__delete(categoryRoot)];
  };
  return (
    <GenericList
      status={status}
      data={data}
      // rowClassName={(x: CategoryDto) => (x.isActive ? "" : "inactive")}
      rowChildren={(x: CategoryRootDto) =>
        x.subcategories?.length ? (
          <GenericList
            status={status}
            data={x.subcategories}
            hideHeader
            cols={[...standardCols]}
            actions={standardActions(standardActionsItems)}
            rowChildren={(y: CategoryRootDto) =>
              y.subcategories?.length ? (
                <GenericList
                  status={status}
                  hideHeader
                  data={y.subcategories}
                  cols={[...standardCols]}
                  actions={standardActions(standardActionsItems)}
                  rowChildren={(z: CategoryRootDto) =>
                    z.subcategories?.length ? (
                      <GenericList status={status} hideHeader data={z.subcategories} cols={[...standardCols]} actions={standardActions(lastActionsItems)} />
                    ) : null
                  }
                />
              ) : null
            }
          />
        ) : null
      }
      cols={[...standardCols]}
      actions={standardActions(standardActionsItems)}
    />
  );
};

export default CategoriesList;
