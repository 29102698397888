import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NameLangPrefixDto, ProdVariantDto } from "src/api/prodVariant/prodVariant.dto";
import { useQuery } from "src/api/useQuerySlice";
import { useGlobalLangs } from "src/app/components/GlobalLangs/GlobalLangsSlice";
import { ModalContext } from "src/app/components/Modal/ModalContext";
import { emojiFlags } from "src/features/flags-emoji";
import { langNames_to_Jsons, langNames_to_Objects } from "src/features/lang-names-tools";
import ToastsStore from "src/features/toast-store/ToastsStore";
import LoadingBtn from "src/libs/buttons/Button/LoadingBtn";
import Form from "src/libs/forms/Form/Form";
import TextLangBox from "src/libs/forms/TextLangBox/TextLangBox";
// import "./variant-manager-modal.scss";

interface Props {
  entity: any;
  propName?: "langNames" | "langStructuralNames";
  endpoint: (dto: any) => Promise<void>;
}

const EditLangNamesModal = (props: Props) => {
  const { entity, propName = "langNames", endpoint } = props;
  const { langs: langsData } = useGlobalLangs();
  const { call: editCall, status: editStatus } = useQuery<void, typeof entity>("ProductVariant/UpdateVariant", [endpoint], {
    callOnLoad: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{
    [propName: string]: string[];
  }>({ defaultValues: {} });

  const { handleConfirm } = useContext(ModalContext);

  const onSubmit = (data: { [propName: string]: string[] }) => {
    try {
    } catch (error) {}
    var dto = {
      ...entity,
      [propName]: langNames_to_Objects(data[propName]),
    } as ProdVariantDto;
    editCall(dto);
  };

  useEffect(() => {
    if (editStatus === "finished") {
      ToastsStore.success("Changes have been saved")
      handleConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  useEffect(() => {
    var langNames;
    if (langsData !== null && entity[propName]) {
      langNames = langsData?.map((x) => {
        var copyOfnameLang = entity[propName]?.find((y: any) => y.langPrefix === x.prefix);
        return { name: copyOfnameLang?.name ?? "", langPrefix: x.prefix } as NameLangPrefixDto;
      });
    }
    reset({ ...(entity ?? {}), [propName]: langNames_to_Jsons(langNames ?? []) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langsData, entity]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="add-edit-modal" direction="row" cols={1}>
      <div className="langs-wrapper d-flex">
        <div className="lang-wrapper d-flex">
          {langsData
            ? langsData.map((item, index) => (
                <TextLangBox
                  key={index}
                  id={`${propName}.${index}`}
                  label={`${emojiFlags(item.prefix)} Name ${item.prefix}`}
                  validation_msg={errors?.langNames?.[index]?.message}
                  field="name"
                  inputProps={{
                    placeholder: "Name",
                    ...register(`${propName}.${index}`),
                  }}
                />
              ))
            : null}
        </div>
      </div>
      <br />
      <div className="ml-auto">
        <LoadingBtn type="submit" status={editStatus} className="ml-auto">
          Save
        </LoadingBtn>
      </div>
    </Form>
  );
};

export default EditLangNamesModal;
