// export const emojiFlags = (langPrefix: string) => {
//   if (langPrefix === "pl") {
//     return "🇵🇱";
//   } else if (langPrefix === "nl") {
//     return "🇳🇱";
//   } else if (langPrefix === "en") {
//     return "🇬🇧";
//   } else if (langPrefix === "de") {
//     return "🇩🇪";
//   } else return "🌍" + langPrefix;
// };

type CountryCode = {
  [key: string]: string;
};

export const emojiFlags = (langPrefix: string): string => {
  const europeanCountries: CountryCode = {
    at: "🇦🇹",
    al: "🇦🇱",
    be: "🇧🇪",
    ba: "🇧🇦",
    bg: "🇧🇬",
    hr: "🇭🇷",
    cy: "🇨🇾",
    cz: "🇨🇿",
    dk: "🇩🇰",
    ee: "🇪🇪",
    fi: "🇫🇮",
    fr: "🇫🇷",
    de: "🇩🇪",
    gr: "🇬🇷",
    hu: "🇭🇺",
    is: "🇮🇸",
    ie: "🇮🇪",
    it: "🇮🇹",
    lv: "🇱🇻",
    lt: "🇱🇹",
    lu: "🇱🇺",
    mk: "🇲🇰",
    mt: "🇲🇹",
    md: "🇲🇩",
    me: "🇲🇪",
    nl: "🇳🇱",
    no: "🇳🇴",
    pl: "🇵🇱",
    pt: "🇵🇹",
    ro: "🇷🇴",
    rs: "🇷🇸",
    sk: "🇸🇰",
    si: "🇸🇮",
    es: "🇪🇸",
    se: "🇸🇪",
    ch: "🇨🇭",
    ua: "🇺🇦",
    gb: "🇬🇧",
    en: "🇬🇧",
    // other countries
    ar: "🇦🇷",
    au: "🇦🇺",
    br: "🇧🇷",
    ca: "🇨🇦",
    cn: "🇨🇳",
    in: "🇮🇳",
    id: "🇮🇩",
    jp: "🇯🇵",
    mx: "🇲🇽",
    ru: "🇷🇺",
    sa: "🇸🇦",
    za: "🇿🇦",
    kr: "🇰🇷",
    tr: "🇹🇷",
    us: "🇺🇸",
  };

  if (europeanCountries[langPrefix]) {
    return europeanCountries[langPrefix];
  } else {
    return "🌍" + langPrefix;
  }
};
