import React, { useState } from "react";
import { generatePath } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { ArrayResult } from "../../../../../../../../api/client";
import {
  CustomerDto,
  OrderDto,
  OrderStatusEnum,
  OrderStatusEnum_Inprogress as OrderStatusEnum__Inprogress,
  OrderStatusEnum_Parser,
  OrderStatusEnum_Parser_Archived,
  OrderStatusEnum_Parser_Inprogress,
  OrderStatusEnum_Parser_New,
  OrderStatusEnum__Archived,
  OrderStatusEnum__New,
  CartItemDto,
} from "../../../../../../../../api/order/order.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import DateHelper from "../../../../../../../../features/date-helper";
import { mapEnum } from "../../../../../../../../features/enumMap";
import { html } from "../../../../../../../../features/html";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import { BasicFilterDto, StandardFilterDto } from "../../../../../../../../libs/filters/filters.dto";
import Filters from "../../../../../../../../libs/filters/FiltersForm";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import { RoutePath } from "../../../../../../../../route-paths";
import Menu from "../../../../../../../components/Menu/menu";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import { PaginationFilterDto } from "../../../../../../../components/Pagination/pagination.dto";
import TopLink from "../../../../../../../components/top-link/top-link";
import { OrdersListVariant } from "../orders-list";

interface OrdersListProps {
  variant: OrdersListVariant;
}

const OrdersList: React.FC<OrdersListProps> = (props) => {
  const { variant } = props;
  const [filters, setFilters] = useState<StandardFilterDto>({});
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 20, skip: 0 });

  const selectEndpoint = (variant: OrdersListVariant) => {
    if (variant === "archived") return Api.Order.ListArchived;
    else if (variant === "new") return Api.Order.ListNew;
    else if (variant === "inprogress") return Api.Order.ListInprogress;
    else return Api.Order.List;
  };

  const { data, status, call } = useQuery<ArrayResult<OrderDto>, BasicFilterDto>("order/list" + variant, [
    selectEndpoint(variant),
    { ...filters, ...pagination },
  ]);

  useEffectAfterMounted(() => {
    call({ ...filters, ...pagination });
  }, [filters, pagination, variant]);

  // useEffectAfterMounted(() => {
  //   if (toggleActiveStatus === "finished" || toggleDeleteStatus === "finished" || permDeleteStatus === "finished") {
  //     call({ ...filters, ...pagination });
  //   }
  // }, [toggleActiveStatus, toggleDeleteStatus, permDeleteStatus]);

  const prodVariantName = (cartItem: CartItemDto) => cartItem.prodVariantRootName;
  const prodName = (cartItem: CartItemDto) => cartItem.productRootName;
  const tradeUnitName = (cartItem: CartItemDto) => cartItem.tradeUnitRootName;
  // const paramName = (param: ProdVariantParamCopyDto) => param.colRootName;
  // const paramValue = (param: ProdVariantParamCopyDto) => param.rootValue;

  const formatCustomerData = (customer: CustomerDto) => {
    return `${customer.address?.name} ${customer.address?.surname}, ${customer.company} <br>
    ${customer.address?.email}, ${customer.address?.phone}<br>
     ${customer.address?.street} ${customer.address?.homenumber}, ${customer.address?.city} ${customer.address?.postalcode}`.replaceAll("null", "");
  };

  const getStatusOptions = (variant: OrdersListVariant) => {
    if (variant === "archived") {
      return mapEnum(OrderStatusEnum__Archived, (key: any) => {
        return {
          id: key,
          label: OrderStatusEnum_Parser_Archived(key),
        };
      });
    } else if (variant === "inprogress") {
      return mapEnum(OrderStatusEnum__Inprogress, (key: any) => {
        return {
          id: key,
          label: OrderStatusEnum_Parser_Inprogress(key),
        };
      });
    } else if (variant === "new") {
      return mapEnum(OrderStatusEnum__New, (key: any) => {
        return {
          id: key,
          label: OrderStatusEnum_Parser_New(key),
        };
      });
    } else {
      return mapEnum(OrderStatusEnum, (key: any) => {
        return {
          id: key,
          label: OrderStatusEnum_Parser(key),
        };
      });
    }
  };

  const calcPrice = (x: OrderDto) => {
    if (x.totalPrice) return x.totalPrice.toFixed(2) + "zł";
    else return "wycena indywidualna";
  };

  return (
    <>
      <Filters
        onSubmit={setFilters}
        setPagination={setPagination}
        status={status}
        fields={[
          {
            field: "searchByColumn",
            component: "SelectBox",
            label: "Column",
            options: [
              { id: "id", label: "Id" },
              { id: "klient", label: "Client" },
              { id: "status", label: "Status" },
            ],
            conditionalFields: [
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "id",
                fields: [{ field: "searchText", component: "TextBox", placeholder: "Search", label: "Order id", validators: Validators.required }],
              },
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "klient",
                fields: [{ field: "searchText", component: "TextBox", placeholder: "Search", label: "Client data", validators: Validators.required }],
              },
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "status",
                fields: [
                  {
                    field: "searchEnum",
                    component: "SelectBox",
                    label: "Search",
                    validators: Validators.required,
                    options: getStatusOptions(variant),
                  },
                ],
              },
              // {
              //   basedOn: "searchByColumn",
              //   condition: (x: any) => x === "officeId",
              //   fields: [
              //     {
              //       field: "searchId",
              //       component: "SelectBox",
              //       label: "Wyszukaj",
              //       validators: Validators.required,
              //       options: officesNames.map((x) => {
              //         return {
              //           id: x.id,
              //           label: x.name,
              //         };
              //       }),
              //     },
              //   ],
              // },
            ],
          },
        ]}
      />
      <GenericList
        status={status}
        data={data?.list}
        // rowClassName={(x: OrderDto) => (x.isActive && !x.deletedDate ? "" : "inactive")}
        cols={[
          ["Id", "id", 1],
          ["Total price", "", 2, (x) => (x ? calcPrice(x) : "-")],
          ["Status", "orderStatus", 2, (status) => OrderStatusEnum_Parser(status)],
          ["Date", "createdDate", 2, (date) => DateHelper.ConvertZone__API_to_UI__formated(date)],
          [
            "Products",
            "",
            3,
            (order: OrderDto) => (
              <ul style={{ paddingInlineStart: 16 }}>
                {order.cartItems.map((cartItem: CartItemDto) => {
                  return (
                    <li key={cartItem.id} className="py-1">
                      <b className="light">
                        {prodName(cartItem)}&nbsp;[{cartItem.id}]
                      </b>
                      &nbsp;&nbsp;<b>&#215;&nbsp;&nbsp;{cartItem.count}</b>
                      <br />
                      <u>Variant</u>: {prodVariantName(cartItem)} <br />
                      <u>Trade unit</u>: {tradeUnitName(cartItem)} <br />
                      {/* // TODO te kilka zakomentowanych linijek niżej jest do szczegółów zamówienia, tu na liście tego nie trzeba */}
                      {/* {cartItem.prodVariantParamsCopy.map((param, index) => {
                                  return (
                                    <>
                                      <u>{paramName(param)}</u>: {paramValue(param)}{' '}
                                      {cartItem.prodVariantParamsCopy.length > index ? <br /> : null}
                                    </>
                                  );
                                })} */}
                    </li>
                  );
                })}
              </ul>
            ),
          ],
          ["Customer", "customerCopy", 3, (customer) => (customer ? <>{html(formatCustomerData(customer))}</> : "")],

          // ["Opis", "opis", 1, (opis) => (opis ? opis.substring(0, 100) + "..." : "-")],
          // ["Czy aktywny?", "isActive", 6, (isActive) => <div className="ml-3">{isActive ? "🟢" : "⚫️"}</div>],
        ]}
        actions={[
          "",
          "",
          1,
          (order: OrderDto) => (
            <Menu
              header="Opcje"
              items={[
                {
                  customRender: (
                    <TopLink to={generatePath(RoutePath.PANEL_ORDERS_MANAGE, { orderId: order.id?.toString() })}>
                      <div className="select-menu__modal__item">
                        <div>Zarządzanie</div>
                      </div>
                    </TopLink>
                  ),
                },
              ]}
            />
          ),
        ]}
      />
      <Pagination
        disableScroll
        totalCount={data?.totalCount}
        itemsPerPage={10}
        take={pagination.take!}
        skip={pagination.skip}
        setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
      />
    </>
  );
};

export default OrdersList;
