import React, { useState } from "react";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import Menu from "../../../../../../../components/Menu/menu";
import { ArrayResult } from "../../../../../../../../api/client";
import Pagination from "../../../../../../../components/Pagination/Pagination";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import { generatePath } from "react-router-dom";
import { StandardFilterDto, BasicFilterDto } from "../../../../../../../../libs/filters/filters.dto";
import Filters from "../../../../../../../../libs/filters/FiltersForm";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import { useAppDispatch } from "../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../route-paths";
import { PaginationFilterDto } from "../../../../../../../components/Pagination/pagination.dto";
import { showSlideOverlay } from "../../../../../../../components/router-transition/routerTransitionSlice";
import { CustomerDto } from "src/api/order/order.dto";
import { html } from "src/features/html";

interface CustomersListProps {}

const formatCustomerNameData = (customer: CustomerDto) => {
  return `${customer.address?.name} ${customer.address?.surname}, ${customer.company}<br> NIP: ${customer.nipPrefix}${customer.nip}`.replaceAll("null", "");
};

const formatCustomerAddressData = (customer: CustomerDto) => {
  return `${customer.address?.country}, ${customer.address?.city}, ${customer.address?.postalcode} <br> 
  ${customer.address?.street} ${customer.address?.homenumber} / ${customer.address?.flatnumber} `.replaceAll("null", "");
};

const formatCustomerContactData = (customer: CustomerDto) => {
  return `${customer.address?.email}<br> ${customer.address?.phone}`.replaceAll("null", "");
};

const CustomersList = (props: CustomersListProps) => {
  const [filters, setFilters] = useState<StandardFilterDto>({});
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 10, skip: 0 });

  const { data, status, call } = useQuery<ArrayResult<CustomerDto>, BasicFilterDto>("user/list", [Api.Customer.List, { ...filters, ...pagination }]);

  // const { status: delStatus, call: deleteCall } = useQuery<void, string>("user/delete", [Api.Customer.Delete], { callOnLoad: false });

  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    call({ ...filters, ...pagination });
  }, [filters, pagination]);

  // useEffectAfterMounted(() => {
  //   if (delStatus === "finished") {
  //     ToastsStore.success("Customer has been deleted");
  //     call({ ...filters, ...pagination });
  //   }
  // }, [delStatus]);

  // const handleDelete = (user: SafeCustomerDto) => {
  //   Confirm(`Are you sure you want to delete customer ${user.name}, ${user.email}?`, () => deleteCall(user.id));
  // };

  return (
    <>
      <Filters
        onSubmit={setFilters}
        setPagination={setPagination}
        status={status}
        fields={[
          {
            field: "searchByColumn",
            component: "SelectBox",
            label: "Search by",
            options: [
              { id: "nameOrSurname", label: "Name" },
              { id: "address", label: "Address" },
              { id: "company", label: "Company" },
            ],
            conditionalFields: [
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "nameOrSurname",
                fields: [{ field: "searchText", component: "TextBox", placeholder: "Search", label: "Search", validators: Validators.required }],
              },
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "address",
                fields: [{ field: "searchText", component: "TextBox", placeholder: "Search", label: "Search", validators: Validators.required }],
              },
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "company",
                fields: [{ field: "searchText", component: "TextBox", placeholder: "Search", label: "Search", validators: Validators.required }],
              },
            ],
          },
        ]}
      />
      <GenericList
        status={status}
        data={data?.list}
        cols={[
          ["Id", "id", 1],
          // ["Telefon", "phone", 2],
          // ["Email", "email", 3],
          ["Customer", "", 3, (customer) => (customer ? <>{html(formatCustomerNameData(customer))}</> : "")],
          ["Contact", "", 3, (customer) => (customer ? <>{html(formatCustomerContactData(customer))}</> : "")],
          ["Address", "", 3, (customer) => (customer ? <>{html(formatCustomerAddressData(customer))}</> : "")],
        ]}
        actions={[
          "",
          "",
          2,
          (customer: CustomerDto) => (
            <Menu
              header="Options"
              items={[
                { label: "Edit", onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_CUSTOMERS_MANAGE, { id: customer.id }))) },
                // { label: "Delete", onAction: () => handleDelete(user) },
              ]}
            />
          ),
        ]}
      />
      <Pagination
        disableScroll
        totalCount={data?.totalCount}
        itemsPerPage={10}
        take={pagination.take!}
        setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
      />
    </>
  );
};

export default CustomersList;
