import Button from "../../../../../../../../libs/buttons/Button/Button";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import TopLink from "../../../../../../../components/top-link/top-link";
import TagsList from "./TagsList/TagsList";


const TagsListPage = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_LIST} title="List" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Tags management</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[6, 8, 10, 12]}>
            <Tile>
              <div className="d-flex pb-3">
                <h2 className="title mr-auto">List of tags</h2>
                <TopLink to={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_ADD}>
                  <Button variant="like-input-primary">+ Add new</Button>
                </TopLink>
              </div>
              <hr />
              <TagsList />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default TagsListPage;
