import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { ProductRootDto } from "../../../../../../../../api/product/product.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import ProductEditForm from "./forms/ProductEditForm";
import BreakTitle from "../../../../../../../components/BreakTitle/BreakTitle";
import { LangDto } from "../../../../../../../../api/lang/lang.dto";
import ProductLangEditForm from "./forms/ProductLangEditForm";
import LangSwitch from "../../../../../../../components/LangSwitch/LangSwitch";
import ProductLangNonExistTile from "./forms/ProductLangNonExistTile";
import MediaList from "./media-list/media-list";
import ParamsManager from "./params-manager/ParamsManager";
import RelatedProducts from "./related-products/related-products-list";
import RelatedCategories from "./related-categories/related-categories-list";
import ProdVariantsManager from "./prod-variant-manager/prod-variants-manager";
import RelatedArticles from "./related-articles/related-articles-list";

const ProductEditPage = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data: productRoot } = useQuery<ProductRootDto, number>("product/getRoot", [Api.Product.GetRoot, Number(id)], {
    statusResetDelay: 1000,
  });

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_ASSORTMENT_PRODUCTS_LIST} title="List" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_ASSORTMENT_PRODUCTS_EDIT, { id: id })} title="Edit product" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Edit product</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <BreakTitle size={"xl"}>Shared settings (no langs)</BreakTitle>
          </Col>
          <Col size={[6, 6, 12, 12]} vif={!!productRoot && !!productRoot?.id}>
            <Tile>
              <h2 className="title pb-3">📄 Base informations</h2>
              <hr />
              <ProductEditForm data={productRoot} />
            </Tile>
          </Col>
          <Col size={[6, 6, 12, 12]} vif={!!productRoot && !!productRoot?.id}>
            <Tile>
              <RelatedCategories productRootId={productRoot?.id ?? -1} />
            </Tile>
            <br />
            <Tile>
              <RelatedArticles productRootId={productRoot?.id ?? -1} />
            </Tile>
            <br />
            <Tile>
              <RelatedProducts productRootId={productRoot?.id ?? -1} />
            </Tile>
          </Col>
          <Col size={12}>
            <BreakTitle size={"xl"}>Shared settings (with langs)</BreakTitle>
          </Col>
          <Col size={12}>
            {productRoot ? (
              <Tile>
                <ProdVariantsManager productRootId={productRoot!.id!} />{" "}
              </Tile>
            ) : null}
          </Col>
        </PanelRow>
        <LangSwitch
          render={({ langs, activeLang }) =>
            productRoot && activeLang
              ? langs?.map((lang: LangDto, index: number) => {
                  const productLangIndex = productRoot?.productLangs?.findIndex((x) => x.langId === lang.id);
                  const rootContainsLang = productLangIndex !== -1;
                  if (!rootContainsLang) return <ProductLangNonExistTile lang={lang} key={index} />;
                  const productLangId = productRoot.productLangs[productLangIndex]?.id;
                  return productLangId ? (
                    <PanelRow className={activeLang.id === lang.id ? "" : "d-none"} key={index}>
                      <Col size={[6, 6, 10, 12]}>
                        <Tile>
                          <h2 className="title pb-3">📃 Base informations</h2>
                          <hr />
                          <ProductLangEditForm selectedLang={lang} />
                        </Tile>
                      </Col>
                      <Col size={[6, 6, 10, 12]}>
                        <Tile>
                          {productRoot?.productLangs[productLangIndex]?.id ? <MediaList scopeId={productRoot.productLangs[productLangIndex].id!} /> : null}
                        </Tile>
                        <br />
                        <Tile>
                          <ParamsManager productLangId={productLangId} />
                        </Tile>
                      </Col>
                    </PanelRow>
                  ) : (
                    <ProductLangNonExistTile lang={lang} key={index} />
                  );
                })
              : null
          }
        />
      </PanelGrid>
    </div>
  );
};

// const ProductEditPage = (props: any) => <MagicFormProvider><ProductEditPageBody {...props} /></MagicFormProvider>

export default ProductEditPage;
