import React from "react";
import { generatePath } from "react-router-dom";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import UserAddForm from "./user-add-form/UserAddForm";

const UserAddPage = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_USERS_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_USERS_ADD)} title="Dodawanie użytkownika" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Dodawanie użytkownika</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={4}>
            <Tile>
              <h2 className="title">Dane podstawowe</h2>
              <UserAddForm />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div >
  );
};

export default UserAddPage;
