import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import ToastsStore from "src/features/toast-store/ToastsStore";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import { GeneralSettingsDto, PageSettingsSectionDto } from "../../../../../../../../api/generalSettings/generalSettings.dto";

interface Props {
  data?: GeneralSettingsDto;
  pageId: string;
}

interface FormDto {
  seoSection_Title: string;
  seoSection_Description_ColA: string;
  seoSection_Description_ColB: string;
}

const HomeSeoSectionForm = (props: Props) => {
  const { data } = props;

  const { call, status: updateStatus } = useQuery<void, PageSettingsSectionDto>(
    "generalSettings/updatePageSettingsSection-seoSection",
    [Api.GeneralSettings.UpdatePageSettingsSection],
    {
      callOnLoad: false,
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormDto>({ defaultValues: {} });

  const onSubmit = async (dto: FormDto) => {
    if (data && data.id) call({ section_items: { ...dto }, pageId: props.pageId, id: data?.id });
  };

  useEffectAfterMounted(() => {
    if (data) {
      var index = data.pagesSettings.findIndex((x) => x.pageId === props.pageId);
      var pageSettingsSection_items = data?.pagesSettings[index].sections ?? {};
      if (index !== -1) {
        reset({
          seoSection_Title: pageSettingsSection_items["seoSection_Title"] ?? "",
          seoSection_Description_ColA: pageSettingsSection_items["seoSection_Description_ColA"] ?? "",
          seoSection_Description_ColB: pageSettingsSection_items["seoSection_Description_ColB"] ?? "",
        });
      } else {
        reset({ seoSection_Title: "", seoSection_Description_ColA: "", seoSection_Description_ColB: "" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
      // refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
      <TextInput
        id="seoSection_Title"
        label="Seo section - title"
        validation_msg={errors?.seoSection_Title?.message}
        inputProps={{
          ...register("seoSection_Title"),
        }}
      />
      <TextInput
        id="seoSection_Description_ColA"
        label="Seo section - description (col left)"
        validation_msg={errors?.seoSection_Description_ColA?.message}
        inputProps={{
          type: "textarea",
          ...register("seoSection_Description_ColA"),
        }}
      />
      <TextInput
        id="seoSection_Description_ColB"
        label="Seo section - description (col right)"
        validation_msg={errors?.seoSection_Description_ColB?.message}
        inputProps={{
          type: "textarea",
          ...register("seoSection_Description_ColB"),
        }}
      />
      <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
        Save
      </LoadingBtn>
    </Form>
  );
};

export default HomeSeoSectionForm;
