import { useEffect } from "react";

import ToastsStore from "src/features/toast-store/ToastsStore";
import { useQuery, useQueryRefetch } from "../../../../../../../../../api/useQuerySlice";
import Api from "../../../../../../../../../api/Api";
import Button from "../../../../../../../../../libs/buttons/Button/Button";
import TextBlock from "../../../../../../../../../libs/text-block/text-block";
import { useParams } from "react-router-dom";

interface ReconfigureKeysProps {}

const ReconfigureKeys = (props: ReconfigureKeysProps) => {
  const { id } = useParams<{ id: string }>();
  const { call, status } = useQuery<void, string>("lang/reconfigureKeys", [Api.Lang.ReconfigureKeys], { callOnLoad: false });
  const { refetch } = useQueryRefetch();

  const handleReconfigureKeys = () => {
    let password = prompt(`Please type "Yes, delete current translations keys" to confirm.`, "");
    if (password?.toLowerCase() === "yes, delete current translations keys") {
      call(id);
    } else {
      alert("Confirm phrase isn't correct.");
    }
  };

  useEffect(() => {
    if (status === "finished") {
      ToastsStore.success("Keys have been reconfigured!");
      refetch("lang/listKeys");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <TextBlock>
      <p className="mr-auto">
        This operation will remove all current keys and insert a copy from the default language. All new keys will need to be translated. This is a dangerous
        function, use it only if you know what you are doing.
      </p>
      <Button variant="like-input-primary" onClick={handleReconfigureKeys}>
        Reconfigure keys
      </Button>
    </TextBlock>
  );
};

export default ReconfigureKeys;
