import { NameLangPrefixDto } from "src/api/prodVariant/prodVariant.dto";

export const langNames_to_Objects = (langNames: Array<string>) => {
  var parsedLangNames = langNames?.map((langName) => {
    try {
      return JSON.parse(langName);
    } catch (error) {
      return null;
    }
  });
  return (parsedLangNames.filter((n) => n) ?? []) as Array<NameLangPrefixDto>;
};

export const langNames_to_Jsons = (langNames?: Array<NameLangPrefixDto>) => {
  var parsedLangNames = langNames?.map((langName) => {
    try {
      return JSON.stringify(langName);
    } catch (error) {
      return null;
    }
  });
  return (parsedLangNames?.filter((n) => n) ?? []) as Array<string>;
};
