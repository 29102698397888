import React, { useEffect } from "react";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import GenericListIcon from "../../../../../../../../../libs/generic-list/generic-list-icon";
import Menu from "../../../../../../../../components/Menu/menu";
import Modal from "../../../../../../../../components/Modal/Modal";
import RelatedTagAddEditModal from "./related-tags-add-modal";
import { ArticleRelationTagDto, RelatedTagRootRelationDto } from "src/api/article/article.dto";


interface RelatedTagListProps {
  articleRootId: number;
}

const RelatedTags: React.FC<RelatedTagListProps> = (props) => {
  const { articleRootId } = props;
  const { call, data, status } = useQuery<Array<ArticleRelationTagDto>, number>(
    "tag/ListRelatedTags",
    [Api.Article.ListRelatedTags, articleRootId],
    {
      callOnLoad: true,
    }
  );
  const { call: callDeleteRelatedTag, status: deleteRelatedTagStatus } = useQuery<void, RelatedTagRootRelationDto>(
    "Tag/DeleteRelatedTag",
    [Api.Article.DeleteRelatedTag],
    {
      callOnLoad: false,
    }
  );

  useEffect(() => {
    if (deleteRelatedTagStatus === "finished") {
      call(articleRootId);
      ToastsStore.success("Related tag has been deleted");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteRelatedTagStatus]);

  return (
    <>
      <div className="d-flex pb-3">
        <div className="mr-auto">
          <h2 className="title pr-4">🔗 Related tags</h2>
        </div>
        <Modal
          title={`Add new related tag`}
          disableStandardButtons={true}
          button={{
            title: `+ Add new`,
            variant: "like-input-primary",
          }}
          maxWidth={1200}
          confirm={() => call(articleRootId)}
          className={"RelatedTagAddEditModal"}
        >
          <RelatedTagAddEditModal articleRootId={articleRootId} />
        </Modal>
      </div>
      <hr />
      <GenericList
        status={status}
        data={data}
        cols={[
          ["", "image", 2, (x) => x ? <GenericListIcon img={process.env.REACT_APP_API_URL + x.path} size="md" openOnClick={true} /> : null],
          ["Id", "tagRootId", 2],
          ["Name", "rootName", 7],
        ]}
        actions={[
          "",
          "",
          1,
          (item: ArticleRelationTagDto) => (
            <Menu
              header="Options"
              items={[
                {
                  label: "Delete",
                  onAction: () =>
                    Confirm(`Are you sure you want to delete relation with ${item.rootName}?`, () =>
                      callDeleteRelatedTag({ articleRootId: item.parentArticleRootId, relatedTagRootId: item.tagRootId })
                    ),
                },
              ]}
            />
          ),
        ]}
      />
    </>
  );
};

export default RelatedTags;
