import { useQuery } from "src/api/useQuerySlice";
import Button from "../../../../../../../../libs/buttons/Button/Button";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import TopLink from "../../../../../../../components/top-link/top-link";
import ProductsList from "./ProductsList/ProductsList";
import Api from "src/api/Api";
import LoadingBtn from "src/libs/buttons/Button/LoadingBtn";
import { Confirm } from "src/features/confirm";

const ProductsListPage = () => {
  const { status: updateAllProductRootViews_status, call: updateAllProductRootViews_call } = useQuery<void>(
    "ProdVariant/UpdateAllProductRootViews",
    [Api.ProdVariant.UpdateAllProductRootViews],
    { callOnLoad: false }
  );
  const { status: updateAllProdTradeUnitTotalSupplies_status, call: updateAllProdTradeUnitTotalSupplies_call } = useQuery<void>(
    "ProdVariant/UpdateAllProdTradeUnitTotalSupplies",
    [Api.ProdVariant.UpdateAllProdTradeUnitTotalSupplies],
    { callOnLoad: false }
  );

  const { status: syncNlSupply_status, call: syncNlSupply_call } = useQuery<void>("ProdVariant/SyncNLSupply", [Api.ProdVariant.SyncNLSupply], {
    callOnLoad: false,
  });

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_ASSORTMENT_PRODUCTS_LIST} title="List" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Products management</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <div className="d-flex pb-3">
                <h2 className="title mr-auto">List of products</h2>
                <TopLink to={RoutePath.PANEL_ASSORTMENT_PRODUCTS_ADD}>
                  <Button variant="like-input-primary">+ Add new</Button>
                </TopLink>
              </div>
              <hr />
              <ProductsList />
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <div className="pb-3">
                <h2 className="title">Collective operations</h2>
                <hr />
                <h3 className="subtitle">Min. prices, Min. promo prices, Total supply</h3>
                <br />
                <LoadingBtn variant="primary" onClick={() => updateAllProductRootViews_call()} status={updateAllProductRootViews_status}>
                  Refresh views
                </LoadingBtn>
                <br />
                <LoadingBtn variant="primary" onClick={() => updateAllProdTradeUnitTotalSupplies_call()} status={updateAllProdTradeUnitTotalSupplies_status}>
                  Refresh total supply
                </LoadingBtn>
                <br />
                <br />
                <h3 className="subtitle">Sync products</h3>
                <br />
                <LoadingBtn
                  variant="primary"
                  onClick={() => Confirm("This operation may take a few seconds. Please wait for it to complete.", () => syncNlSupply_call())}
                  status={syncNlSupply_status}
                >
                  Sync NL Supply
                </LoadingBtn>
              </div>
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default ProductsListPage;
