import { generatePath } from "react-router-dom";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { ProductRootDto } from "../../../../../../../../../api/product/product.dto";
import { LangDto } from "../../../../../../../../../api/lang/lang.dto";
import { QueryStatus, useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import GenericList, { NumberStringTuple } from "../../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../../route-paths";
import Menu, { MenuItemType } from "../../../../../../../../components/Menu/menu";
import { showSlideOverlay } from "../../../../../../../../components/router-transition/routerTransitionSlice";
import Pagination from "../../../../../../../../components/Pagination/Pagination";
import { useState } from "react";
import { BasicFilterDto, StandardFilterDto } from "../../../../../../../../../libs/filters/filters.dto";
import { PaginationFilterDto } from "../../../../../../../../components/Pagination/pagination.dto";
import { ArrayResult } from "../../../../../../../../../api/client";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import Filters from "../../../../../../../../../libs/filters/FiltersForm";
import { IdNameDto } from "../../../../../../../../../api/tools/tools.models.dto";
import TopLink from "src/app/components/top-link/top-link";

const combineStatus = (statuses: Array<QueryStatus>): QueryStatus => {
  if (statuses.find((x) => x === "loading")) return "loading";
  else if (statuses.filter((x) => x === "finished").length === statuses.length) return "finished";
  else if (statuses.find((x) => x === "failed")) return "failed";
  else return "default";
};

interface ProductsListProps {}

const ProductsList = (props: ProductsListProps) => {
  const [filters, setFilters] = useState<StandardFilterDto>({});
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 50, skip: 0 });
  const { data, status, call } = useQuery<ArrayResult<ProductRootDto>, BasicFilterDto>("product/listRoot", [Api.Product.ListRoot], { callOnLoad: false });
  const {
    data: copyRootId,
    status: copyRootStatus,
    call: copyRootCall,
  } = useQuery<{ productRootId: number }, number>("product/copyRoot", [Api.Product.CopyRoot], { callOnLoad: false });

  const { data: categoryNames } = useQuery<Array<IdNameDto>>("category/listRoot", [Api.Category.ListRootIdNames]);
  const { data: avaliableLangs } = useQuery<Array<LangDto>>("lang/list", [Api.Lang.List]);

  const { status: delStatus, call: callDeleteRoot } = useQuery<void, number>("product/deleteRoot", [Api.Product.DeleteRoot], { callOnLoad: false });
  // const { status: toggleActiveStatus, call: callToggleActive } = useQuery<void, string>("product/toggleActive", [Api.Product.ToggleActive], {
  //   callOnLoad: false,
  // });

  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    if (delStatus === "finished") {
      ToastsStore.success("Product has been deleted");
      call({ ...filters, ...pagination });
    }
  }, [delStatus]);

  useEffectAfterMounted(() => {
    if (copyRootStatus === "finished" && copyRootId?.productRootId !== null) {
      ToastsStore.success("Product copy created");
      dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_ASSORTMENT_PRODUCTS_EDIT, { id: copyRootId?.productRootId?.toString() })));
    }
  }, [copyRootStatus]);

  useEffectAfterMounted(() => {
    call({ ...filters, ...pagination });
  }, [categoryNames, filters, pagination]);

  const handleDeleteRoot = (productRoot: ProductRootDto) => {
    Confirm(`Are you sure you want to delete product ${productRoot.rootName}?`, () => callDeleteRoot(productRoot.id));
  };

  const handleCopyRoot = (productRoot: ProductRootDto) => {
    Confirm(`This operation may take a few seconds, are you sure you want to copy ${productRoot.rootName}?`, () => copyRootCall(productRoot.id));
  };

  // useEffectAfterMounted(() => {
  //   if (toggleActiveStatus === "finished") {
  //     ToastsStore.success("Produkt pomyślnie aktywowany/deaktywowany");
  //     call();
  //   }
  // }, [toggleActiveStatus]);

  const standardCols: NumberStringTuple[] = [
    [
      "Name",
      "",
      2,
      (x) => (
        <TopLink to={generatePath(RoutePath.PANEL_ASSORTMENT_PRODUCTS_EDIT, { id: x.id?.toString() })} className="primary" target="_blank">
          {x.rootName}
        </TopLink>
      ),
    ],
    [
      "Categories",
      "",
      5,
      (x: ProductRootDto) => (
        <>
          {x.categoryLine_FromRoot
            ? x.categoryLine_FromRoot.map((y: IdNameDto, index: number) => {
                if (x.categoryLine_FromRoot!.length === index + 1) return y.name;
                else return y.name + " 🠚 ";
              })
            : ""}
        </>
      ),
    ],
    [
      "Langs",
      "",
      2,
      (x: ProductRootDto) => {
        const langPrefixes = avaliableLangs?.filter((y) => x.productLangs?.findIndex((z) => z.langId === y.id) !== -1).map((x) => x.prefix);
        return `[${langPrefixes?.map((y) => y)}]`.replaceAll(",", ", ").replace("[]", "-");
      },
    ],
    ["Min. price", "view_MinPrice", 2],
    ["Min. price promo", "view_MinPricePromo", 2],
    ["Total Supply", "view_AnyVariantTotalSupply", 2],

    // [
    //   "Aktywny?",
    //   "",
    //   2,
    //   (dto) => (
    //     <div className="ml-2  d-inline-block clickable p-1" onClick={() => callToggleActive(dto.id)} title={dto.isActive ? "Aktywny" : "Nieaktywny"}>
    //       {dto.isActive ? "🟢" : "⚫️ Nieaktywny"}
    //     </div>
    //   ),
    // ],
  ];

  const standardActions = (actionsItems: (productRoot: ProductRootDto) => Array<MenuItemType>): NumberStringTuple => [
    "",
    "",
    1,
    (productRoot: ProductRootDto) => <Menu header="Options" items={actionsItems(productRoot)} />,
  ];

  // const actionsItems__toggleActive = (productRoot: ProductRootDto) => {
  //   return {
  //     label: productRoot.isActive ? "Dezaktywuj" : "Aktywuj",
  //     onAction: () => callToggleActive(product.id),
  //   };
  // };
  const actionsItems__edit = (productRoot: ProductRootDto) => {
    return {
      label: "Edit",
      onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_ASSORTMENT_PRODUCTS_EDIT, { id: productRoot.id?.toString() }))),
    };
  };
  const actionsItems__delete = (productRoot: ProductRootDto) => {
    return { label: "Delete", onAction: () => handleDeleteRoot(productRoot) };
  };

  const actionsItems__copy = (productRoot: ProductRootDto) => {
    return { label: "Copy", onAction: () => handleCopyRoot(productRoot) };
  };

  const standardActionsItems = (productRoot: ProductRootDto): Array<MenuItemType> => {
    return [
      actionsItems__edit(productRoot),
      actionsItems__copy(productRoot),
      // actionsItems__toggleActive(productRoot),
      actionsItems__delete(productRoot),
    ];
  };

  return (
    <>
      {categoryNames ? (
        <>
          <Filters
            onSubmit={setFilters}
            setPagination={setPagination}
            status={status}
            fields={[
              {
                field: "searchByColumn",
                component: "SelectBox",
                label: "Column",
                options: [
                  { id: "name", label: "Name" },
                  { id: "categoryId", label: "Category" },
                ],
                conditionalFields: [
                  {
                    basedOn: "searchByColumn",
                    condition: (x: any) => x === "name",
                    fields: [{ field: "searchText", component: "TextBox", placeholder: "Search", label: "Column contents", validators: Validators.required }],
                  },
                  {
                    basedOn: "searchByColumn",
                    condition: (x: any) => x === "categoryId",
                    fields: [
                      {
                        field: "searchEnum",
                        component: "SelectBox",
                        label: "Search",
                        validators: Validators.required,
                        options:
                          categoryNames?.map((x: IdNameDto) => {
                            return {
                              id: x.id,
                              label: x.name,
                              offset: x.metadata?.generation,
                            };
                          }) ?? [],
                      },
                    ],
                  },
                ],
              },
            ]}
          />
          <GenericList
            status={combineStatus([status, copyRootStatus])}
            data={data?.list}
            cols={[...standardCols]}
            actions={standardActions(standardActionsItems)}
          />
          <Pagination
            // disableScroll
            totalCount={data?.totalCount}
            itemsPerPage={50}
            take={pagination.take!}
            setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
          />
        </>
      ) : null}
    </>
  );
};

export default ProductsList;
