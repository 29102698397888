import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ToastsStore from "src/features/toast-store/ToastsStore";
import { useQuery, useQueryRefetch } from "../../../../../../../../../api/useQuerySlice";
import { AuthorRootDto } from "../../../../../../../../../api/author/author.dto";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";

interface AuthorEditFormProps {
  data: AuthorRootDto | undefined;
}

const AuthorEditForm = (props: AuthorEditFormProps) => {
  const { data } = props;
  const { refetch } = useQueryRefetch();
  const { call, status: updateStatus } = useQuery<void, AuthorRootDto>("author/updateRoot", [Api.Author.UpdateRoot], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AuthorRootDto>({ defaultValues: { ...data } });

  const onSubmit = async (dto: AuthorRootDto) => {
    call({ ...dto, id: data?.id } as AuthorRootDto);
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
      // refetch(Number(id));
      refetch("author/getRoot");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="rootName"
          label="Name"
          validation_msg={errors?.rootName?.message}
          required
          inputProps={{
            ...register("rootName", Validators.required),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Save
        </LoadingBtn>
      </Form>
    </>
  );
};

export default AuthorEditForm;
