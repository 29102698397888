/* eslint-disable @typescript-eslint/no-unused-vars */

import moment from "moment";

var options_date: Intl.DateTimeFormatOptions = { year: "numeric", month: "numeric", day: "2-digit" };
var options_time: Intl.DateTimeFormatOptions = { hour: "2-digit", minute: "2-digit", second: "2-digit" };
var options_date_time: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

class DateHelper {
  static ConvertZone__API_to_UI__formated = (stringDate: string | undefined) => {
    if (stringDate != null && stringDate.length > 0) {
      var date = new Date(stringDate + "z");
      return date.toLocaleDateString("pl-PL", options_date_time);
    } else return "";
  };
  static ConvertZone__API_to_UI = (date?: string | Date | undefined | null) => {
    return moment(date).utcOffset(0, true).toDate();
  };
  static ConvertZone__UI_to_API = (date?: string | Date | undefined | null) => {
    return moment(date).utcOffset(0, false).toDate();
  };
}

export default DateHelper;
