import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ToastsStore from "src/features/toast-store/ToastsStore";
import { useParams } from "react-router-dom";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { ProductLangDto } from "../../../../../../../../../api/product/product.dto";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { LangDto } from "../../../../../../../../../api/lang/lang.dto";
import WYSIWYGEditor from "../../../../../../../../../libs/forms/WysiwigEditor/WysiwigEditor";
import BreakTitle from "../../../../../../../../components/BreakTitle/BreakTitle";

interface ProductEditFormProps {
  selectedLang: LangDto;
}

const ProductLangEditForm = (props: ProductEditFormProps) => {
  const { selectedLang } = props;
  const { id = "" } = useParams<{ id: string }>();

  const { silentCall: callGet, data: productLang } = useQuery<ProductLangDto, { rootId: string; langPrefix: string }>(
    "product/getLangByRoot",
    [Api.Product.GetLangByRoot, { rootId: id, langPrefix: selectedLang.prefix }],
    { statusResetDelay: 1000, callOnLoad: false }
  );
  const { call: callUpdate, status: updateStatus } = useQuery<void, ProductLangDto>("product/updateLang", [Api.Product.UpdateLang], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<ProductLangDto>({ defaultValues: {} });

  const onSubmit = async (dto: ProductLangDto) => {
    callUpdate({ ...productLang, ...dto } as ProductLangDto);
  };

  useEffect(() => {
    reset(productLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productLang]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
      callGet({ rootId: id, langPrefix: selectedLang.prefix });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  useEffect(() => {
    if (productLang) return;
    else callGet({ rootId: id, langPrefix: selectedLang.prefix });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="ProductLangEditForm">
        <TextInput
          id="name"
          label="Product name"
          validation_msg={errors?.name?.message}
          required
          inputProps={{
            ...register("name", Validators.required),
          }}
        />
        <TextInput
          id="descriptionShort"
          label="Short description (top)"
          validation_msg={errors?.descriptionShort?.message}
          inputProps={{
            type: "textarea",
            ...register("descriptionShort"),
          }}
        />
        <WYSIWYGEditor
          id="descriptionMain"
          label="Main description"
          validation_msg={errors?.descriptionMain?.message}
          inputProps={{
            control: control,
            defaultValue: productLang?.descriptionMain,
          }}
        />
        <TextInput
          id="descriptionLong"
          label="Short description (bottom)"
          validation_msg={errors?.descriptionLong?.message}
          inputProps={{
            type: "textarea",
            ...register("descriptionLong"),
          }}
        />
        <BreakTitle className="pl-0">SEO Settings</BreakTitle>
        <TextInput
          id="meta_title"
          label="Title tag"
          validation_msg={errors?.meta_title?.message}
          inputProps={{
            ...register("meta_title"),
          }}
        />
        <TextInput
          id="meta_description"
          label="Description metatag"
          validation_msg={errors?.meta_description?.message}
          inputProps={{
            type: "textarea",
            ...register("meta_description"),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Save
        </LoadingBtn>
      </Form>
    </>
  );
};

export default ProductLangEditForm;
