import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import CustomersList from "./CustomersList/CustomersList";

const CustomersListPage = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_CUSTOMERS_LIST} title="List" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Customers management</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <div className="d-flex pb-3">
                <h2 className="title mr-auto">Customers list</h2>
                {/* <TopLink to={RoutePath.PANEL_CUSTOMERS_ADD}>
                  <Button variant="like-input-primary">+ Add new</Button>
                </TopLink> */}
              </div>
              <hr />
              <CustomersList />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default CustomersListPage;
