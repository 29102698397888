import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ToastsStore from "src/features/toast-store/ToastsStore";
import { useQuery, useQueryRefetch } from "../../../../../../../../../api/useQuerySlice";
import { ArticleRootDto, BlogArticleType, BlogArticleType_Parser } from "../../../../../../../../../api/article/article.dto";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import SelectBox from "src/libs/forms/SelectBox/SelectBox";
import { IdNameDto } from "src/api/tools/tools.models.dto";
import { mapEnum } from "src/features/enumMap";

interface ArticleEditFormProps {
  data: ArticleRootDto | undefined;
}

const ArticleEditForm = (props: ArticleEditFormProps) => {
  const { data } = props;
  const { refetch } = useQueryRefetch();
  const { call, status: updateStatus } = useQuery<void, ArticleRootDto>("article/updateRoot", [Api.Article.UpdateRoot], { callOnLoad: false });
  const { data: tagNames } = useQuery<Array<IdNameDto>>("tag/ListRootIdNames", [Api.Tag.ListRootIdNames]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ArticleRootDto>({ defaultValues: { ...data } });

  const onSubmit = async (dto: ArticleRootDto) => {
    call({ ...dto, id: data?.id, mainTagId: dto.mainTagId } as ArticleRootDto);
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
      // refetch(Number(id));
      refetch("article/getRoot");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="rootName"
          label="Name"
          validation_msg={errors?.rootName?.message}
          required
          inputProps={{
            ...register("rootName", Validators.required),
          }}
        />
        <SelectBox
          id="articleType"
          label="Article type"
          validation_msg={errors?.articleType?.message}
          required
          maxHeight="xl"
          inputProps={{
            ...register("articleType", Validators.required),
          }}
          options={
            mapEnum(BlogArticleType, (articleType: any) => {
              return {
                id: articleType,
                label: BlogArticleType_Parser(articleType),
              };
            }) ?? []
          }
        />
        <SelectBox
          id="mainTagId"
          label="Main tag"
          validation_msg={errors?.mainTagId?.message}
          cancelable={true}
          maxHeight="xl"
          inputProps={{
            ...register("mainTagId"),
          }}
          options={
            tagNames?.map((x: IdNameDto) => {
              return {
                id: x.id,
                label: x.name,
              };
            }) ?? []
          }
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Save
        </LoadingBtn>
      </Form>
    </>
  );
};

export default ArticleEditForm;
