import React, { useEffect, useState } from "react";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { SafeUserDto, UpdateUserRolesDto } from "../../../../../../../../api/user/user.dto";
import { useParams } from "react-router-dom";
import { UserRoleEnum, UserRoleEnum_Parser } from "../../../../../../../../api/auth/auth.dto";
import GenericList from "../../../../../../../../libs/generic-list/generic-list";
import { mapEnum } from "../../../../../../../../features/enumMap";
import ToastsStore from "src/features/toast-store/ToastsStore";

interface UserRolesEditFormProps { }

const UserRolesEditForm = (props: UserRolesEditFormProps) => {
  const { id = "" } = useParams<{ id: string }>();
  const { call: refetch, data, status: getStatus } = useQuery<SafeUserDto, string>('user/get', [Api.User.Get, id], { statusResetDelay: 1000 });
  const { call, status: updateStatus } = useQuery<void, UpdateUserRolesDto>('user/updateUserRoles', [Api.User.UpdateUserRoles], { callOnLoad: false });
  const [selectedRoles, setSelectedRoles] = useState<Array<{ role: UserRoleEnum }>>();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    call({
      id: data?.id ?? "",
      roles: selectedRoles?.filter(item => item).map(x => x.role) as [UserRoleEnum]
    });
  };

  useEffect(() => {
    if (updateStatus === 'finished') {
      ToastsStore.success("Data has been saved");
      refetch(id);
    } else if (updateStatus === 'failed') {
      // setData(undefined);
      refetch(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus])


  return (
    <>
      <Form onSubmit={onSubmit} cols={2} className="pt-2">
        <br />
        {data ?
          <GenericList
            status={getStatus}
            data={mapEnum(UserRoleEnum, (role: any) => {
              return {
                role: role,
              }
            })}
            cols={[
              ["Rola", "role", 10, x => UserRoleEnum_Parser(x)]
            ]}
            inputProps={{
              multiselect: true,
              onChange: (x: any) => setSelectedRoles(x),
              initValues: data.roles.map((x) => {
                return { role: x }
              })
            }}
          />

          : null}
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">Save</LoadingBtn>
      </Form>
    </>
  );
};

export default UserRolesEditForm;
