import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { AuthorLangDto, AuthorRootDto } from "../../../../../../../../api/author/author.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import BreakTitle from "../../../../../../../components/BreakTitle/BreakTitle";
import { LangDto } from "../../../../../../../../api/lang/lang.dto";
import LangSwitch from "../../../../../../../components/LangSwitch/LangSwitch";
import MediaList from "./media-list/media-list";
import AuthorEditForm from "./forms/AuthorEditForm";
import AuthorLangEditForm from "./forms/AuthorLangEditForm";
import AuthorLangNonExistTile from "./forms/AuthorLangNonExistTile";
import SocialMediaList from "./socialmedia-list/socialmedia-list";

const AuthorEditPage = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data: authorRoot } = useQuery<AuthorRootDto, number>("author/getRoot", [Api.Author.GetRoot, Number(id)], {
    statusResetDelay: 1000,
  });
  const { data: authorLangs } = useQuery<Array<AuthorLangDto>, number>("author/listLangs", [Api.Author.ListLang, Number(id)], {
    statusResetDelay: 1050,
  });


  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_KNOWLEDGEBASE_AUTHORS_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_KNOWLEDGEBASE_AUTHORS_EDIT, { id: id })} title="Edit author" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Edit author</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <BreakTitle size={"xl"}>Shared settings (no lang)</BreakTitle>
          </Col>
          <Col size={[6, 8, 10, 12]}>
            <Tile>
              <h2 className="title">Base informations</h2>
              <AuthorEditForm data={authorRoot} />
            </Tile>
          </Col>
        </PanelRow>
        <LangSwitch
          render={({ langs, activeLang }) =>
            authorRoot && activeLang
              ? langs?.map((lang: LangDto, index: number) => {
                const authorLangIndex = authorLangs?.findIndex((x) => x.langId === lang.id) ?? -1;
                const rootContainsLang = authorLangIndex !== -1;
                if (!rootContainsLang) return <AuthorLangNonExistTile lang={lang} key={index} />;
                const authorLang = authorLangs![authorLangIndex];
                return rootContainsLang ? (
                  <PanelRow className={activeLang.id === lang.id ? "" : "d-none"} key={index}>
                    <Col size={[6, 6, 10, 12]}>
                      <Tile>
                        <h2 className="title">Base informations</h2>
                        <AuthorLangEditForm selectedLang={lang} />
                      </Tile>
                    </Col>
                    <Col size={[6, 6, 12, 12]}>
                      <Tile>{authorLang.id ? <MediaList scopeId={authorLang.id!} /> : null}</Tile>
                      <br />
                      <Tile>{authorLang.id ? <SocialMediaList scopeId={authorLang.id!} /> : null}</Tile>
                    </Col>
                  </PanelRow>
                ) : (
                  <AuthorLangNonExistTile lang={lang} key={index} />
                );
              })
              : null
          }
        />
      </PanelGrid>
    </div>
  );
};

export default AuthorEditPage;