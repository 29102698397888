import React, { useEffect } from "react";
import "./param-cell.scss";
import { NameLangPrefixDto, ProdVariantTradeUnitDto } from "src/api/prodVariant/prodVariant.dto";
import TextInput from "src/libs/forms/TextBox/TextInput";
import Api from "src/api/Api";
import { useQuery, useQueryRefetch } from "src/api/useQuerySlice";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Modal from "src/app/components/Modal/Modal";
import { useTrigger } from "src/app/components/GlobalTrigger/GlobalTriggerSlice";
import EditLangNamesModal from "./edit-langNames-modal";

interface Props {
  prodVariantTradeUnit: ProdVariantTradeUnitDto;
  propEdit: "rootName" | "supplyPL" | "supplyNL" | "idOfSupplyNL";
  langNames?: boolean;
  label?: string;
}

const VariantTradeUnitCellEdit: React.FC<Props> = (props) => {
  const { prodVariantTradeUnit, propEdit, label } = props;

  const { call: editCall, status } = useQuery<void, ProdVariantTradeUnitDto>("ProdVariant/UpdateVariantTradeUnit", [Api.ProdVariant.UpdateVariantTradeUnit], {
    callOnLoad: false,
  });

  const { refetch } = useQueryRefetch();
  const { setTrigger, triggerId } = useTrigger();

  const handleBlur = (e: any) => {
    console.log(`${prodVariantTradeUnit[propEdit]} --- ${e.target!.value}`);
    if (prodVariantTradeUnit[propEdit]?.toString() !== e.target!.value?.toString()) {
      if (propEdit === "supplyPL" && (e.target!.value === 0 || e.target!.value === null || e.target!.value === undefined || e.target!.value === "")) {
        ToastsStore.error("SupplyPL is not defined, changes have not been saved");
        return;
      }
      const dto = {
        ...prodVariantTradeUnit,
        [propEdit]: e.target!.value ?? "",
      } as ProdVariantTradeUnitDto;
      editCall(dto);
    }
  };

  useEffect(() => {
    if (status === "finished") {
      refetch("ProdVariant/ListProdVariants");
      ToastsStore.success("Changes have been saved");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className={`param-cell ${label ? "--with-label" : ""}`}>
      <Modal
        title={`Language settings - ${prodVariantTradeUnit[propEdit]}`}
        openTrigger={triggerId === `edit-tradeUnit_langNames_${prodVariantTradeUnit.id}_${propEdit}`}
        disableStandardButtons={true}
        button={{
          hidden: true,
        }}
        maxWidth={900}
        confirm={() => refetch("ProdVariant/ListProdVariants")}
        className={"VariantManagerModal"}
      >
        <EditLangNamesModal entity={prodVariantTradeUnit} endpoint={Api.ProdVariant.UpdateVariantTradeUnit} />
      </Modal>
      <TextInput
        label={label ? label : undefined}
        inputProps={{
          onBlurCapture: handleBlur,
          defaultValue: prodVariantTradeUnit[propEdit]??"",
        }}
      />
      {propEdit === "rootName" ? (
        <span
          className={`lang-btn ${prodVariantTradeUnit.langNames === null ||
              prodVariantTradeUnit.langNames === undefined ||
              prodVariantTradeUnit.langNames.length === 0 ||
              prodVariantTradeUnit.langNames?.findIndex((x) => !(x.name === null || x.name === undefined || x.name === "" || x.name?.length === 0)) === -1
              ? "gray-scale"
              : ""
            }`}
          onClick={() => setTrigger(`edit-tradeUnit_langNames_${prodVariantTradeUnit.id}_${propEdit}`)}
        >
          {prodVariantTradeUnit.langNames &&
            !(
              prodVariantTradeUnit.langNames === null ||
              prodVariantTradeUnit.langNames === undefined ||
              prodVariantTradeUnit.langNames.length === 0 ||
              prodVariantTradeUnit.langNames?.findIndex((x) => !(x.name === null || x.name === undefined || x.name === "" || x.name?.length === 0)) === -1
            )
            ? prodVariantTradeUnit.langNames.map((x: NameLangPrefixDto, index: number) => {
              if (x.name === null || x.name === undefined || x.name === "" || x.name?.length === 0) return <b className="lang-dots">.</b>;
              return null;
            })
            : null}
          🌎
        </span>
      ) : null}
    </div>
  );
};

export default VariantTradeUnitCellEdit;
