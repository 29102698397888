import React, { useContext, useEffect, useState } from "react";
import Api from "../../../../../../../../../api/Api";
import { ProductRootDto, RelatedCategoryRootRelationDto } from "../../../../../../../../../api/product/product.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import { ModalContext } from "../../../../../../../../components/Modal/ModalContext";
import "./related-categories-add-modal.scss";
import { IdNameDto } from "../../../../../../../../../api/tools/tools.models.dto";

interface MediaAddEditModalProps {
  productRootId: number;
}

const RelatedCategoryAddModal: React.FC<MediaAddEditModalProps> = (props) => {
  const { productRootId } = props;
  const { handleConfirm } = useContext(ModalContext);
  const [selectedItem, setSelectedItem] = useState<ProductRootDto>();

  const { data, call: callList, status: listStatus } = useQuery<Array<IdNameDto>>("category/listRoot", [Api.Category.ListRootIdNames]);
  const { call, status } = useQuery<void, RelatedCategoryRootRelationDto>("product/AddRelatedCategory", [Api.Product.AddRelatedCategory], {
    callOnLoad: false,
  });

  useEffect(() => {
    callList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (selectedItem && selectedItem?.id) {
      var addDto = {
        productRootId: productRootId,
        relatedCategoryRootId: selectedItem.id,
      } as RelatedCategoryRootRelationDto;
      call(addDto);
    }
  };

  useEffect(() => {
    if (status === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const drawName = (x: IdNameDto) => {
    var generation = x?.metadata?.generation;
    if (generation) {
      if (generation === "1")
        return (
          <div className="space-wrapper">
            <div className="space pr-2 pl-2" />
            {x.name}
          </div>
        );
      if (generation === "2")
        return (
          <div className="space-wrapper">
            <div className="space pr-2 pl-4" />
            {x.name}
          </div>
        );
      if (generation === "3")
        return (
          <div className="space-wrapper">
            <div className="space pr-2 pl-5" />
            {x.name}
          </div>
        );
    }
    return <div>{x.name}</div>;
  };

  return (
    <div className="related-categories-add-modal">
      {data ? (
        <>
          <div className="list-wrapper">
            <GenericList
              status={listStatus}
              data={data}
              cols={[
                // ["Id", "id", 1],
                ["Name", "", 10, (x) => drawName(x)],
              ]}
              inputProps={{
                multiselect: false,
                onChange: (x: any) => setSelectedItem(x),
              }}
            />
          </div>
        </>
      ) : null}

      <div className="ml-auto pt-3">
        <LoadingBtn onClick={() => onSubmit()} status={status} className="ml-auto">
          Add
        </LoadingBtn>
      </div>
    </div>
  );
};

export default RelatedCategoryAddModal;
