import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ToastsStore from "src/features/toast-store/ToastsStore";
import { useQuery, useQueryRefetch } from "../../../../../../../../../api/useQuerySlice";
import { CategoryRootDto, FlatCategoryRootDto } from "../../../../../../../../../api/category/category.dto";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import CheckBoxInput from "../../../../../../../../../libs/forms/CheckBox/CheckBox";

interface CategoryEditFormProps {
  data: CategoryRootDto | undefined;
}

const CategoryEditForm = (props: CategoryEditFormProps) => {
  const { data } = props;
  // const { id = "" } = useParams<{ id: string }>();
  // const { call: refetch } = useQuery<CategoryRootDto, number>("category/getRoot", [Api.Category.GetRoot, Number(id)], { statusResetDelay: 1000 });
  const { refetch } = useQueryRefetch();
  const { call, status: updateStatus } = useQuery<void, CategoryRootDto>("category/updateRoot", [Api.Category.UpdateRoot], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FlatCategoryRootDto>({ defaultValues: {} });

  const onSubmit = async (dto: FlatCategoryRootDto) => {
    call({ ...data, ...dto } as CategoryRootDto);
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
      // refetch(Number(id));
      refetch("category/getRoot");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="rootName"
          label="Nazwa"
          validation_msg={errors?.rootName?.message}
          required
          inputProps={{
            ...register("rootName", Validators.required),
          }}
        />
        <CheckBoxInput
          id="isFavourite"
          label="Czy wyróżniona?"
          validation_msg={errors?.isFavourite?.message}
          inputProps={{
            ...register("isFavourite"),
          }}
          description={"Wyróżniona"}
        />
        <TextInput
          id="rootMeta_head"
          label="Sekcja <head> [SEO]"
          validation_msg={errors?.rootMeta_head?.message}
          required
          inputProps={{
            type: "textarea",
            ...register("rootMeta_head"),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Save
        </LoadingBtn>
      </Form>
    </>
  );
};

export default CategoryEditForm;
