import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import ToastsStore from "src/features/toast-store/ToastsStore";
import { AccountInfoDto } from "../../../../../../../../api/auth/auth.dto";
import { useSession } from "../../../../../../../../api/auth/sessionSlice";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";

interface SignInFormProps { }

const AccountEditForm = (props:SignInFormProps) => {
  const { call: refetch, data } = useQuery<AccountInfoDto>('auth/get-account-info', [Api.Auth.GetAccountInfo], { statusResetDelay: 1000 });
  const { call, status: updateStatus } = useQuery<void, AccountInfoDto>('auth/update-accont-info', [Api.Auth.UpdateAccountInfo], { callOnLoad: false });
  const { register, handleSubmit, formState: { errors }, reset } = useForm<AccountInfoDto>({ defaultValues: {} });
  const { updateSessionDetails } = useSession();
  const onSubmit = async (dto: any) => {
    call(dto);
  };

  useEffectAfterMounted(() => {
    reset(data);
    updateSessionDetails({ name: data?.name });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (updateStatus === 'finished') {
      ToastsStore.success("Data has been saved");
      refetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus])


  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="name"
          label="Nazwa"
          validation_msg={errors?.name?.message}
          inputProps={{
            ...register("name", Validators.required)
          }}
        />
        <TextInput
          id="email"
          label="Email"
          validation_msg={errors?.email?.message}
          inputProps={{
            ...register("email", Validators.required),
            type: "email"
          }}
        />
        <TextInput
          id="phone"
          label="Telefon"
          validation_msg={errors?.phone?.message}
          inputProps={{
            ...register("phone", Validators.required)
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">Save</LoadingBtn>
      </Form>
    </>
  );
};

export default AccountEditForm;
