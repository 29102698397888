import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ToastsStore from "src/features/toast-store/ToastsStore";
import { useQuery, useQueryRefetch } from "../../../../../../../../../api/useQuerySlice";
import { FlatProductRootDto, ProductRootDto } from "../../../../../../../../../api/product/product.dto";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import CheckBoxInput from "../../../../../../../../../libs/forms/CheckBox/CheckBox";
import SelectBox from "../../../../../../../../../libs/forms/SelectBox/SelectBox";
import { IdNameDto } from "../../../../../../../../../api/tools/tools.models.dto";
import BreakTitle from "src/app/components/BreakTitle/BreakTitle";

interface ProductEditFormProps {
  data: ProductRootDto | undefined;
}

const ProductEditForm = (props: ProductEditFormProps) => {
  const { data } = props;
  const { refetch } = useQueryRefetch();
  const { call, status: updateStatus } = useQuery<void, ProductRootDto>("product/updateRoot", [Api.Product.UpdateRoot], { callOnLoad: false });
  const { data: categoryNames } = useQuery<Array<IdNameDto>>("category/listRoot", [Api.Category.ListRootIdNames]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FlatProductRootDto>({ defaultValues: {} });
  const onSubmit = async (dto: FlatProductRootDto) => {
    call({ ...data, ...dto, categoryId: (dto.categoryId as any) === "undefined" ? null : dto.categoryId } as ProductRootDto);
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
      refetch("product/getRoot");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="rootName"
          label="Product name in panel"
          validation_msg={errors?.rootName?.message}
          required
          inputProps={{
            ...register("rootName", Validators.required),
          }}
        />
        <CheckBoxInput
          id="isFavourite"
          label="Is favourite?"
          validation_msg={errors?.isFavourite?.message}
          inputProps={{
            ...register("isFavourite"),
          }}
          description={"Product is listed in favourites products"}
        />
        <CheckBoxInput
          id="isActive"
          label="Is active?"
          validation_msg={errors?.isActive?.message}
          inputProps={{
            ...register("isActive"),
          }}
          description={"Product is avaliable in  search results"}
        />
        <SelectBox
          id="categoryId"
          label="Breadcrumbs category"
          validation_msg={errors?.categoryId?.message}
          cancelable={true}
          maxHeight="xl"
          inputProps={{
            ...register("categoryId"),
          }}
          options={
            categoryNames?.map((x: IdNameDto) => {
              return {
                id: x.id,
                label: x.name,
                offset: x.metadata?.generation,
              };
            }) ?? []
          }
        />
        <BreakTitle className="pl-0">SEO Settings</BreakTitle>
        <TextInput
          id="rootMeta_head"
          label="Head section [html]"
          validation_msg={errors?.rootMeta_head?.message}
          required
          inputProps={{
            type: "textarea",
            ...register("rootMeta_head"),
          }}
        />
        <TextInput
          id="redirectUrl"
          label="Redirect url"
          validation_msg={errors?.redirectUrl?.message}
          inputProps={{
            ...register("redirectUrl"),
          }}
        />
        <TextInput
          id="priorityOrder"
          label="Priority order"
          inputProps={{
            ...register("priorityOrder"),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Save
        </LoadingBtn>
      </Form>
    </>
  );
};

export default ProductEditForm;
