import React, { useContext, useEffect, useState } from "react";
import Api from "../../../../../../../../../api/Api";
import { ArrayResult } from "../../../../../../../../../api/client";
import { ProductRootDto, RelatedProductRootRelationDto } from "../../../../../../../../../api/product/product.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import { StandardFilterDto, BasicFilterDto } from "../../../../../../../../../libs/filters/filters.dto";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
// import WYSIWYGEditor from "../../../../../../../../libs/forms/WysiwigEditor/WysiwigEditor";
import { ModalContext } from "../../../../../../../../components/Modal/ModalContext";
import Pagination from "../../../../../../../../components/Pagination/Pagination";
import { PaginationFilterDto } from "../../../../../../../../components/Pagination/pagination.dto";
import Filters from "../../../../../../../../../libs/filters/FiltersForm";
import "./related-products-add-modal.scss";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import { IdNameDto } from "../../../../../../../../../api/tools/tools.models.dto";

interface MediaAddEditModalProps {
  productRootId: number;
}

const RelatedProductAddModal: React.FC<MediaAddEditModalProps> = (props) => {
  const { productRootId } = props;
  const { handleConfirm } = useContext(ModalContext);
  const [filters, setFilters] = useState<StandardFilterDto>({});
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 30, skip: 0 });
  const [selectedItem, setSelectedItem] = useState<ProductRootDto>();

  const {
    data,
    status: listStatus,
    call: callList,
  } = useQuery<ArrayResult<ProductRootDto>, BasicFilterDto>("product/listRoot", [Api.Product.ListRoot], { callOnLoad: false });
  const { data: categoryNames } = useQuery<Array<IdNameDto>>("category/listRoot", [Api.Category.ListRootIdNames]);
  const { call, status } = useQuery<void, RelatedProductRootRelationDto>("product/AddRelatedProduct", [Api.Product.AddRelatedProduct], { callOnLoad: false });

  useEffect(() => {
    callList({ ...filters, ...pagination });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectAfterMounted(() => {
    callList({ ...filters, ...pagination });
  }, [categoryNames, filters, pagination]);

  const onSubmit = () => {
    if (selectedItem && selectedItem?.id) {
      var addDto = {
        productRootId: productRootId,
        relatedProductRootId: selectedItem.id,
      } as RelatedProductRootRelationDto;
      call(addDto);
    }
  };

  useEffect(() => {
    if (status === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="related-products-add-modal">
      {data && categoryNames ? (
        <>
          <Filters
            onSubmit={setFilters}
            setPagination={setPagination}
            status={status}
            fields={[
              {
                field: "searchByColumn",
                component: "SelectBox",
                label: "Column",
                options: [
                  { id: "name", label: "Name" },
                  { id: "categoryId", label: "Category" },
                ],
                conditionalFields: [
                  {
                    basedOn: "searchByColumn",
                    condition: (x: any) => x === "name",
                    fields: [{ field: "searchText", component: "TextBox", placeholder: "Search", label: "Column contents", validators: Validators.required }],
                  },
                  {
                    basedOn: "searchByColumn",
                    condition: (x: any) => x === "categoryId",
                    fields: [
                      {
                        field: "searchEnum",
                        component: "SelectBox",
                        label: "Search",
                        validators: Validators.required,
                        options:
                          categoryNames?.map((x: IdNameDto) => {
                            return {
                              id: x.id,
                              label: x.name,
                              offset: x.metadata?.generation,
                            };
                          }) ?? [],
                      },
                    ],
                  },
                ],
              },
            ]}
          />
          <div className="list-wrapper">
            <GenericList
              status={listStatus}
              data={data.list}
              cols={[
                ["Id", "id", 1],
                ["Name", "rootName", 3],
                [
                  "Categories",
                  "",
                  7,
                  (x: ProductRootDto) => (
                    <>
                      {x.categoryLine_FromRoot
                        ? x.categoryLine_FromRoot.map((y: IdNameDto, index: number) => {
                            if (x.categoryLine_FromRoot!.length === index + 1) return y.name;
                            else return y.name + " 🠚 ";
                          })
                        : ""}
                    </>
                  ),
                ],
              ]}
              inputProps={{
                multiselect: false,
                onChange: (x: any) => setSelectedItem(x),
              }}
            />
          </div>
          <Pagination
            disableScroll
            totalCount={data?.totalCount}
            itemsPerPage={30}
            take={pagination.take!}
            setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
          />
        </>
      ) : null}

      <div className="ml-auto pt-3">
        <LoadingBtn onClick={() => onSubmit()} status={status} className="ml-auto">
          Add
        </LoadingBtn>
      </div>
    </div>
  );
};

export default RelatedProductAddModal;
