import React from "react";
import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { CategoryRootDto } from "../../../../../../../../api/category/category.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import SubcategoryAddForm from "./subcategory-add-form/SubcategoryAddForm";

const SubcategoryAddPage = () => {
  const { parentId = "" } = useParams<{ parentId: string }>();
  const { data: parent, status } = useQuery<CategoryRootDto, number>("category/get", [Api.Category.GetRoot, Number(parentId)]);

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_ASSORTMENT_CATEGORIES_LIST} title="List" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_ASSORTMENT_CATEGORIES_ADDSUBCATEGORY, { parentId: parentId })} title="New subcategory" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Add subcategory {parent ? "to - " + parent.rootName : ""}</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
        <Col size={[6, 8, 10, 12]}>
            {parent ? (
              <Tile>
                <h2 className="title">Basic informations</h2>
                <SubcategoryAddForm parent={parent} />
              </Tile>
            ) : null}
            {status === "failed" || (parent === null && status === "finished") ? (
              <Tile>
                <h2 className="title">Category not found.</h2>
              </Tile>
            ) : null}
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default SubcategoryAddPage;
