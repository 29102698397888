import React, { useEffect } from "react";
import "./param-cell.scss";
import { NameLangPrefixDto, ProdVariantDto } from "src/api/prodVariant/prodVariant.dto";
import TextInput from "src/libs/forms/TextBox/TextInput";
import Api from "src/api/Api";
import { useQuery, useQueryRefetch } from "src/api/useQuerySlice";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Modal from "src/app/components/Modal/Modal";
import { useTrigger } from "src/app/components/GlobalTrigger/GlobalTriggerSlice";
import EditLangNamesModal from "./edit-langNames-modal";

interface Props {
  prodVariant: ProdVariantDto;
  propEdit: "rootName" | "rootStructuralName";
  langNamesProp?: "langNames" | "langStructuralNames";
  langNames?: boolean;
  label?: string;
  className?: string;
}

const VariantCellEdit: React.FC<Props> = (props) => {
  const { prodVariant, propEdit, label, className = "", langNamesProp = "langNames" } = props;

  const { call: editCall, status } = useQuery<void, ProdVariantDto>("ProdVariant/EditVariant", [Api.ProdVariant.UpdateVariant], {
    callOnLoad: false,
  });

  const { refetch } = useQueryRefetch();
  const { setTrigger, triggerId } = useTrigger();

  const handleBlur = (e: any) => {
    console.log(`${prodVariant[propEdit]} --- ${e.target!.value}`);
    if (prodVariant[propEdit]?.toString() !== e.target!.value?.toString()) {
      const dto = {
        ...prodVariant,
        [propEdit]: e.target!.value ?? "",
      } as ProdVariantDto;
      editCall(dto);
    }
  };

  useEffect(() => {
    if (status === "finished") {
      refetch("ProdVariant/ListProdVariants");
      ToastsStore.success("Changes have been saved");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className={`param-cell ${className} ${label ? "--with-label" : ""}`}>
      <Modal
        title={`Language settings${prodVariant[propEdit] ? "- " + prodVariant[propEdit] : ""}`}
        openTrigger={triggerId === `edit-${[langNamesProp]}_${prodVariant.id}_${propEdit}`}
        disableStandardButtons={true}
        button={{
          hidden: true,
        }}
        maxWidth={900}
        confirm={() => refetch("ProdVariant/ListProdVariants")}
        className={"VariantManagerModal"}
      >
        <EditLangNamesModal entity={prodVariant} endpoint={Api.ProdVariant.UpdateVariant} propName={langNamesProp} />
      </Modal>
      <TextInput
        label={label ? label : undefined}
        inputProps={{
          onBlurCapture: handleBlur,
          defaultValue: prodVariant[propEdit],
        }}
      />
      {propEdit === "rootName" || propEdit === "rootStructuralName" ? (
        <span
          className={`lang-btn ${
            prodVariant[langNamesProp] === null ||
            prodVariant[langNamesProp] === undefined ||
            prodVariant[langNamesProp].length === 0 ||
            prodVariant[langNamesProp]?.findIndex((x) => !(x.name === null || x.name === undefined || x.name === "" || x.name?.length === 0)) === -1
              ? "gray-scale"
              : ""
          }`}
          onClick={() => setTrigger(`edit-${[langNamesProp]}_${prodVariant.id}_${propEdit}`)}
        >
          {prodVariant[langNamesProp] &&
          !(
            prodVariant[langNamesProp] === null ||
            prodVariant[langNamesProp] === undefined ||
            prodVariant[langNamesProp].length === 0 ||
            prodVariant[langNamesProp]?.findIndex((x) => !(x.name === null || x.name === undefined || x.name === "" || x.name?.length === 0)) === -1
          )
            ? prodVariant[langNamesProp].map((x: NameLangPrefixDto, index: number) => {
                if (x.name === null || x.name === undefined || x.name === "" || x.name?.length === 0) return <b className="lang-dots">.</b>;
                return null;
              })
            : null}
          🌎
        </span>
      ) : null}
    </div>
  );
};

export default VariantCellEdit;
