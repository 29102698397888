import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { RoutePath } from "../../../../../../../../../route-paths";
import { ArticleRootDto, BlogArticleType, BlogArticleType_Parser } from "src/api/article/article.dto";
import SelectBox from "src/libs/forms/SelectBox/SelectBox";
import { mapEnum } from "src/features/enumMap";

const ArticleAddForm = () => {
  const { call, status: updateStatus } = useQuery<void, ArticleRootDto>("article/add", [Api.Article.AddRoot], { callOnLoad: false });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ArticleRootDto>({ defaultValues: {} });
  const navigate = useNavigate();

  const onSubmit = async (dto: ArticleRootDto) => {
    call({ ...dto, mainTagId: dto.mainTagId ?? null });
  };

  useEffect(() => {
    if (updateStatus === "finished") {
      reset();
      ToastsStore.success("Article added successfuly");
      navigate(RoutePath.PANEL_KNOWLEDGEBASE_ARTICLES_LIST as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
      <TextInput
        id="rootName"
        label="Name"
        validation_msg={errors?.rootName?.message}
        required
        inputProps={{
          ...register("rootName", Validators.required),
        }}
      />
      <SelectBox
        id="articleType"
        label="Article type"
        validation_msg={errors?.articleType?.message}
        required
        maxHeight="xl"
        inputProps={{
          ...register("articleType", Validators.required),
        }}
        options={
          mapEnum(BlogArticleType, (articleType: any) => {
            return {
              id: articleType,
              label: BlogArticleType_Parser(articleType),
            };
          }) ?? []
        }
      />
      <br />
      <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
        Save
      </LoadingBtn>
    </Form>
  );
};

export default ArticleAddForm;
