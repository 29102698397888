import { useEffect, useRef } from "react";

export const useComponentDidMount = () => {
  const ref = useRef();

  useEffect(() => {
    (ref as any).current = true;
    return () => {
      (ref as any).current = undefined;
    }
  }, []);
  
  return ref.current;
}

export default function useEffectAfterMounted(func: () => void, array: Array<any>) {

  const isComponentMounted = useComponentDidMount();

  useEffect(() => {
    if (isComponentMounted) {
      func();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, array);

}