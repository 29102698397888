import React from "react";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Api from "src/api/Api";
import { NameLangPrefixDto, ProdVariantDto } from "src/api/prodVariant/prodVariant.dto";
import { useQuery } from "src/api/useQuerySlice";
import { useGlobalLangs } from "src/app/components/GlobalLangs/GlobalLangsSlice";
import { ModalContext } from "src/app/components/Modal/ModalContext";
import { emojiFlags } from "src/features/flags-emoji";
import { langNames_to_Jsons, langNames_to_Objects } from "src/features/lang-names-tools";
import LoadingBtn from "src/libs/buttons/Button/LoadingBtn";
import Form from "src/libs/forms/Form/Form";
import TextInput from "src/libs/forms/TextBox/TextInput";
import TextLangBox from "src/libs/forms/TextLangBox/TextLangBox";
import Validators from "src/libs/forms/validators/validators";

// import "./variant-manager-modal.scss";

interface Props {
  productRootId: number;
  tradeUnit?: ProdVariantDto;
}

interface RawProdVariantDto extends Omit<ProdVariantDto, "id" | "productRootId" | "langNames"> {
  langNames: string[];
}

const AddEditVariantModal = (props: Props) => {
  const { productRootId, tradeUnit: prodVariant } = props;
  const { langs: langsData } = useGlobalLangs();
  const { call: addCall, status: addStatus } = useQuery<void, ProdVariantDto>("ProdVariant/AddVariant", [Api.ProdVariant.AddVariant], {
    callOnLoad: false,
  });
  const { call: editCall, status: editStatus } = useQuery<void, ProdVariantDto>("ProductVariant/UpdateVariant", [Api.ProdVariant.UpdateVariant], {
    callOnLoad: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<RawProdVariantDto>({ defaultValues: {} });

  const { handleConfirm } = useContext(ModalContext);

  const onSubmit = (data: RawProdVariantDto) => {
    try {
    } catch (error) {}
    var dto = {
      ...data,
      langNames: langNames_to_Objects(data.langNames),
      productRootId: productRootId,
    } as ProdVariantDto;

    if (prodVariant && prodVariant.id) {
      dto.id = prodVariant.id;
      editCall(dto);
    } else {
      addCall(dto);
    }
  };

  useEffect(() => {
    if (addStatus === "finished" || editStatus === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus, editStatus]);

  // useEffect(() => {
  //   if (tradeUnit !== null) {
  //     var langNamesJsons = langNames_to_Jsons(tradeUnit?.langNames??[]);
  //     if(langsData) {
  //       for (let i = 0; i < langsData.length; i++) {

  //       }
  //     }
  //     var formInitialData = {
  //       ...tradeUnit,
  //       langNames:
  //     } as RawTradeUnit;
  //     reset(formInitialData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tradeUnit]);

  useEffect(() => {
    var langNames;
    if (langsData !== null) {
      langNames = langsData?.map((x) => {
        var copyOfnameLang = prodVariant?.langNames?.find((y) => y.langPrefix === x.prefix);
        return { name: copyOfnameLang?.name ?? "", langPrefix: x.prefix } as NameLangPrefixDto;
      });
    }
    reset({ ...(prodVariant ?? {}), langNames: langNames_to_Jsons(langNames ?? []) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langsData, prodVariant]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="add-edit-modal" direction="row" cols={1}>
      <div className="langs-wrapper d-flex">
        <div className="lang-wrapper d-flex">
          <TextInput
            id="rootName"
            label="Global name"
            validation_msg={errors?.rootName?.message}
            required
            inputProps={{
              ...register("rootName", Validators.required),
            }}
          />
          {langsData
            ? langsData.map((item, index) => (
                <TextLangBox
                  key={index}
                  id={`langNames.${index}`}
                  label={`${emojiFlags(item.prefix)} Name ${item.prefix}`}
                  validation_msg={errors?.langNames?.[index]?.message}
                  field="name"
                  inputProps={{
                    placeholder: "Name",
                    ...register(`langNames.${index}`),
                  }}
                />
              ))
            : null}
        </div>
      </div>
      {/* <TextInput
        id="weight"
        label="Weight [kg]"
        validation_msg={errors?.weight?.message}
        inputProps={{
          type: "number",
          placeholder: "0...",
          step: 0.001,
          ...register("weight", Validators.minOne),
        }}
      />
      <TextInput
        id="volume"
        label="Volume [cm³]"
        validation_msg={errors?.volume?.message}
        inputProps={{
          type: "number",
          placeholder: "0...",
          step: 0.001,
          ...register("volume", Validators.minOne),
        }}
      /> */}
      <br />
      <div className="ml-auto">
        {prodVariant ? (
          <LoadingBtn type="submit" status={editStatus} className="ml-auto">
            Save
          </LoadingBtn>
        ) : (
          <LoadingBtn type="submit" status={addStatus} className="ml-auto">
            Add
          </LoadingBtn>
        )}
      </div>
    </Form>
  );
};

export default AddEditVariantModal;
