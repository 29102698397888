import React from "react";
import Button from "../../../../../../../libs/buttons/Button/Button";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import TopLink from "../../../../../../components/top-link/top-link";
import UsersList from "./UsersList/UsersList";

const UsersListPage = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_USERS_LIST} title="Lista" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Users management</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <div className="d-flex pb-3">
                <h2 className="title mr-auto">Users list</h2>
                <TopLink to={RoutePath.PANEL_USERS_ADD}>
                  <Button variant="like-input-primary">+ Add new</Button>
                </TopLink>
              </div>
              <hr />
              <UsersList />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default UsersListPage;
