import { generatePath } from "react-router-dom";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { LangDto } from "../../../../../../../../../api/lang/lang.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../../route-paths";
import Menu from "../../../../../../../../components/Menu/menu";
import { showSlideOverlay } from "../../../../../../../../components/router-transition/routerTransitionSlice";

interface LangsListProps {}

const LangsList = (props: LangsListProps) => {
  const { data, status, call } = useQuery<Array<LangDto>>("lang/list", [Api.Lang.List]);

  const { status: deleteStatus, call: callDelete } = useQuery<void, string>("lang/delete", [Api.Lang.Delete], { callOnLoad: false });
  const { status: setDefaultStatus, call: callSetDefault } = useQuery<void, string>("lang/setDefault", [Api.Lang.SetDefault], { callOnLoad: false });
  const { status: toggleActiveStatus, call: callToggleActive } = useQuery<void, number>("lang/toggleActive", [Api.Lang.ToggleActive], { callOnLoad: false });

  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    if (deleteStatus === "finished") {
      ToastsStore.success("Language has been deleted");
      call();
    }
  }, [deleteStatus]);

  const handleDelete = (lang: LangDto) => {
    Confirm(`Are you sure you want to delete lang ${lang.name}?`, () => callDelete(lang.id?.toString()));
  };

  useEffectAfterMounted(() => {
    if (setDefaultStatus === "finished") {
      ToastsStore.success("Language has been changed");
      call();
    }
  }, [setDefaultStatus]);

  useEffectAfterMounted(() => {
    if (toggleActiveStatus === "finished") {
      ToastsStore.success("Language has been changed");
      call();
    }
  }, [toggleActiveStatus]);

  const handleSetDefault = (lang: LangDto) => {
    Confirm(`Are you sure you want to set ${lang.name} as default?`, () => callSetDefault(lang.id?.toString()));
  };
  const handleToggleActive = (lang: LangDto) => {
    callToggleActive(lang.id);
  };

  return (
    <GenericList
      status={status}
      data={data}
      rowClassName={(x: LangDto) => (x.isActive ? "" : "inactive")}
      cols={[
        ["Name", "name", 2],
        ["Country", "country", 3],
        ["Prefix", "prefix", 3],
        ["Is active?", "", 2, (dto) => <div className="ml-2  d-inline-block clickable p-1">{dto.isActive ? "🟢" : "⚫️ Inactive"}</div>],
        ["", "isDefault", 2, (x) => (x ? "⭐ Default" : "")],
      ]}
      actions={[
        "",
        "",
        3,
        (lang: LangDto) => (
          <Menu
            header="Options"
            items={[
              {
                label: "Edit",
                onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_SETTINGS_LANGUAGES_EDIT, { id: lang.id?.toString() }))),
              },
              {
                label: lang.isActive ? "Deactivate" : "Activate",
                onAction: () => handleToggleActive(lang),
              },
              lang.isDefault
                ? null
                : {
                    label: "Default",
                    onAction: () => handleSetDefault(lang),
                  },
              { label: "Delete", onAction: () => handleDelete(lang) },
            ]}
          />
        ),
      ]}
    />
  );
};

export default LangsList;
