import { Navigate, Outlet } from "react-router-dom";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import ExactPathSwitch from "../../../../../../components/ExactPathSwitch/ExactPathSwitch";

const CategoriesLayout = () => {

  return (
    <>
      <BreadcrumbsItem to={RoutePath.PANEL_ASSORTMENT_CATEGORIES} title="Categories" isNotLink={true} />
      <ExactPathSwitch
        path={RoutePath.PANEL_ASSORTMENT_CATEGORIES}
        if_exact={<Navigate to={RoutePath.PANEL_ASSORTMENT_CATEGORIES_LIST} />}
        else={<Outlet />}
      />
    </>
  );
};

export default CategoriesLayout;
