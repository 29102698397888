import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Api from '../../../../../api/Api';
import { SessionDataDto, SignInDto } from '../../../../../api/auth/auth.dto';
import { useSession } from '../../../../../api/auth/sessionSlice';
import { generateExtraReducers } from '../../../../../redux/redux-tools';
import { RootState } from '../../../../../redux/store';
import { useAppSelector } from '../../../../../redux/store';

export interface authDataState {
  data?: SessionDataDto;
  status: 'default' | 'finished' | 'loading' | 'failed';
}

const initialState: authDataState = {
  data: undefined,
  status: 'default'
};

export const signInAsync = createAsyncThunk(
  'auth/signIn',
  async (dto: SignInDto) => {
    const response = await Api.Auth.SignIn(dto);
    return response;
  }
);

export const authDataSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    cleanData: (state) => {
      state.data = undefined;
    },
  },
  extraReducers: (builder) => generateExtraReducers<authDataState>(builder, [
    {
      call: signInAsync, logs: true, dataField: 'data', statusField: 'status'
    }
  ])
});

// eslint-disable-next-line no-empty-pattern
export default authDataSlice.reducer;

export function useSignIn() {
  const data = useAppSelector((state: RootState) => state.authData.data);
  const status = useAppSelector((state: RootState) => state.authData.status);
  const dispatch = useDispatch();
  const { setSession } = useSession();
  useEffect(() => {
    if (data) {
      setSession(data);
      dispatch(authDataSlice.actions.cleanData())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const signIn = (dto: SignInDto) => dispatch<any>(signInAsync(dto));

  return { status, signIn };
}