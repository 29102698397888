import React from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../libs/forms/validators/validators";
import TextBlock from "../../../../../libs/text-block/text-block";
import { RoutePath } from "../../../../../route-paths";
import TopLink from "../../../../components/top-link/top-link";
import { useResetPassword } from "./ResetPasswordDataSlice";

interface SignInFormProps { }
interface FormDto {
  email: string,
}

const RequestResetPasswordForm = (props: SignInFormProps) => {
  const { requestResetPassword, requestResetPassword_status } = useResetPassword();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDto>();


  const onSubmit = (data: FormDto) => {
    requestResetPassword(data.email)
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextInput
        id="email"
        label="Email"
        validation_msg={errors?.email?.message}
        inputProps={{
          ...register("email", Validators.email),
          type: "email",
        }}
      />
      <LoadingBtn type="submit" status={requestResetPassword_status}>Resetuj hasło</LoadingBtn>
      <TextBlock className="text-right mt-4">
        <TopLink to={RoutePath.SIGN_IN} className="weight-500 primary">
          Wróć do logowania
        </TopLink>
      </TextBlock>
    </Form>
  );
};

export default RequestResetPasswordForm;
