import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import ToastsStore from "src/features/toast-store/ToastsStore";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import { GeneralSettingsDto, PageSettingsSectionDto } from "../../../../../../../../api/generalSettings/generalSettings.dto";
import Validators from "src/libs/forms/validators/validators";

interface Props {
  pageId: string;
  data?: GeneralSettingsDto;
}

interface FormDto {
  freeDeliverySection_LangPL_undefinedDestination: string;
  freeDeliverySection_LangNonPL_undefinedDestination: string;
  freeDeliverySection_LangAny_DestinationPL: string;
  freeDeliverySection_LangAny_DestinationNonPL: string;
}

const FreeDeliveryPolicyForm = (props: Props) => {
  const { data } = props;

  const { call, status: updateStatus } = useQuery<void, PageSettingsSectionDto>(
    "generalSettings/updatePageSettingsSection-cart",
    [Api.GeneralSettings.UpdatePageSettingsSection],
    {
      callOnLoad: false,
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormDto>({ defaultValues: {} });

  const onSubmit = async (dto: FormDto) => {
    if (data && data.id) call({ section_items: { ...dto }, pageId: props.pageId, id: data?.id });
  };

  useEffectAfterMounted(() => {
    if (data) {
      var index = data.pagesSettings.findIndex((x) => x.pageId === props.pageId);
      var pageSettingsSection_items = data?.pagesSettings[index]?.sections ?? {};
      if (index !== -1) {
        reset({
          freeDeliverySection_LangPL_undefinedDestination: pageSettingsSection_items["freeDeliverySection_LangPL_undefinedDestination"] ?? "",
          freeDeliverySection_LangNonPL_undefinedDestination: pageSettingsSection_items["freeDeliverySection_LangNonPL_undefinedDestination"] ?? "",
          freeDeliverySection_LangAny_DestinationPL: pageSettingsSection_items["freeDeliverySection_LangAny_DestinationPL"] ?? "",
          freeDeliverySection_LangAny_DestinationNonPL: pageSettingsSection_items["freeDeliverySection_LangAny_DestinationNonPL"] ?? "",
        });
      } else {
        reset({
          freeDeliverySection_LangPL_undefinedDestination: "",
          freeDeliverySection_LangNonPL_undefinedDestination: "",
          freeDeliverySection_LangAny_DestinationPL: "",
          freeDeliverySection_LangAny_DestinationNonPL: "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
      // refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
      <h3 className="title form-child">Free delivery rules</h3>
      <TextInput
        label="Lang PL, undefined destination"
        validation_msg={errors?.freeDeliverySection_LangPL_undefinedDestination?.message}
        inputProps={{
          ...register("freeDeliverySection_LangPL_undefinedDestination", Validators.FreeDeliveryJSON),
        }}
      />
      <TextInput
        label="Lang non-PL, undefined destination"
        validation_msg={errors?.freeDeliverySection_LangNonPL_undefinedDestination?.message}
        inputProps={{
          ...register("freeDeliverySection_LangNonPL_undefinedDestination", Validators.FreeDeliveryJSON),
        }}
      />
      <TextInput
        label="Lang any, destination PL"
        validation_msg={errors?.freeDeliverySection_LangAny_DestinationPL?.message}
        inputProps={{
          ...register("freeDeliverySection_LangAny_DestinationPL", Validators.FreeDeliveryJSON),
        }}
      />
      <TextInput
        label="Lang any, destination non-PL"
        validation_msg={errors?.freeDeliverySection_LangAny_DestinationNonPL?.message}
        inputProps={{
          ...register("freeDeliverySection_LangAny_DestinationNonPL", Validators.FreeDeliveryJSON),
        }}
      />
      <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
        Save
      </LoadingBtn>
    </Form>
  );
};

export default FreeDeliveryPolicyForm;
