import React, { useEffect, useState } from "react";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import { useTrigger } from "../../../../../../../../components/GlobalTrigger/GlobalTriggerSlice";
import Menu from "../../../../../../../../components/Menu/menu";
import Modal from "../../../../../../../../components/Modal/Modal";
import { NameLangPrefixDto, ProdVariantColDto, ProdVariantDto, ProdVariantParamDto, ProdVariantTradeUnitDto } from "src/api/prodVariant/prodVariant.dto";
import { mixStatus } from "src/features/mix-status";
import ParamCellEdit from "./components/param-cell-edit";
import ParamCellAdd from "./components/param-cell-add";
import VariantCellEdit from "./components/variant-cell-edit";
import AddEditVariantModal from "./components/addEdit-variant-modal";
import AddEditVariantColModal from "./components/addEdit-variantCol-modal";
import "./prod-variants-manager.scss";
import { Confirm } from "src/features/confirm";
import ToastsStore from "src/features/toast-store/ToastsStore";
import { emojiFlags } from "src/features/flags-emoji";
import Button from "src/libs/buttons/Button/Button";
import AddEditVariantTradeUnitModal from "./components/addEdit-prodVariantTradeUnit-modal";
import VariantTradeUnitCellEdit from "./components/tradeUnit-cell-edit";
import Row from "src/libs/grid/row";
import Col from "src/libs/grid/col";
import LoadingSpinner from "src/app/components/LoadingSpinner/LoadingSpinner";

interface RelatedCategoryListProps {
  productRootId: number;
}

const ProdVariantsManager: React.FC<RelatedCategoryListProps> = (props) => {
  const { productRootId } = props;
  const [colVariantLoaderId, setColVariantLoaderId] = useState<string | undefined>(undefined);
  const { setTrigger, triggerId } = useTrigger();
  const [nextRerenderVariantTradeUnitTrigger, setNextRerenderVariantTradeUnitTrigger] = useState("");

  const {
    call: listProdVariantCols_call,
    data: listProdVariantCols_data,
    status: listProdVariantCols_status,
  } = useQuery<Array<ProdVariantColDto>, number>("ProdVariant/ListProdVariantCols", [Api.ProdVariant.ListProdVariantCols, productRootId], {
    callOnLoad: true,
  });

  const {
    call: listProdVariants_call,
    data: listProdVariants_data,
    status: listProdVariants_status,
  } = useQuery<Array<ProdVariantDto>, number>("ProdVariant/ListProdVariants", [Api.ProdVariant.ListProdVariants, productRootId], {
    callOnLoad: true,
  });

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    call: listProdVariantParams_call,
    data: listProdVariantParams_data,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    status: listProdVariantParams_status,
  } = useQuery<Array<ProdVariantParamDto>, number>("ProdVariant/ListProdVariantParams", [Api.ProdVariant.ListProdVariantParams, productRootId], {
    callOnLoad: true,
  });

  // const { call: addProdVariant_call, status: addProdVariant_status } = useQuery<void, ProdVariantDto>("ProdVariant/AddVariant", [Api.ProdVariant.AddVariant], {
  //   callOnLoad: false,
  // });

  const { call: replaceVariantPriorityOrder, status: replaceVariantPriorityOrderStatus } = useQuery<void, { firstId: number; secondId: number }>(
    "ProdVariant/ReplaceVariantPriorityOrder",
    [Api.ProdVariant.ReplaceVariantPriorityOrder],
    {
      callOnLoad: false,
    }
  );

  const { call: replaceProdVariantColPriorityOrder, status: replaceProdVariantColPriorityOrderStatus } = useQuery<void, { firstId: number; secondId: number }>(
    "ProdVariant/ReplaceProdVariantColPriorityOrder",
    [Api.ProdVariant.ReplaceProdVariantColPriorityOrder],
    {
      callOnLoad: false,
    }
  );

  const { call: copyProdVariant, status: copyProdVariantStatus } = useQuery<void, { productRootId: number; prodVariantId: number }>(
    "ProdVariant/CopyProdVariant",
    [Api.ProdVariant.CopyProdVariant],
    {
      callOnLoad: false,
    }
  );

  const { call: deleteProdVariant, status: deleteProdVariantStatus } = useQuery<void, number>(
    "ProdVariant/DeleteProdVariant",
    [Api.ProdVariant.DeleteProdVariant],
    {
      callOnLoad: false,
    }
  );

  const { call: deleteProdVariantCol, status: deleteProdVariantColStatus } = useQuery<void, number>(
    "ProdVariant/DeleteProdVariant",
    [Api.ProdVariant.DeleteProdVariantCol],
    {
      callOnLoad: false,
    }
  );

  const { call: deleteProdVariantTradeUnit, status: deleteProdVariantTradeUnitStatus } = useQuery<void, number>(
    "ProdVariant/DeleteProdVariantTradeUnit",
    [Api.ProdVariant.DeleteProdVariantTradeUnit],
    {
      callOnLoad: false,
    }
  );

  const { call: editVariantCol, status: editVariantColStatus } = useQuery<void, ProdVariantColDto>(
    "ProductVariant/UpdateVariantCol",
    [Api.ProdVariant.UpdateVariantCol],
    {
      callOnLoad: false,
    }
  );

  useEffect(() => {
    if (listProdVariants_status === "finished") {
      if (nextRerenderVariantTradeUnitTrigger && nextRerenderVariantTradeUnitTrigger.length > 0) {
        setTrigger(nextRerenderVariantTradeUnitTrigger);
        setNextRerenderVariantTradeUnitTrigger("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProdVariants_status]);

  useEffect(() => {
    if (replaceProdVariantColPriorityOrderStatus === "finished") {
      listProdVariants_call(productRootId);
      listProdVariantCols_call(productRootId);
      ToastsStore.success("Changes has been saved");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replaceProdVariantColPriorityOrderStatus]);

  useEffect(() => {
    if (replaceVariantPriorityOrderStatus === "finished") {
      listProdVariants_call(productRootId);
      ToastsStore.success("Changes has been saved");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replaceVariantPriorityOrderStatus]);

  useEffect(() => {
    if (copyProdVariantStatus === "finished") {
      listProdVariants_call(productRootId);
      listProdVariantCols_call(productRootId);
      listProdVariantParams_call(productRootId);
      ToastsStore.success("Variant has been copied");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copyProdVariantStatus]);

  useEffect(() => {
    if (deleteProdVariantStatus === "finished") {
      listProdVariants_call(productRootId);
      ToastsStore.success("Variant has been deleted");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProdVariantStatus]);

  useEffect(() => {
    if (editVariantColStatus === "finished") {
      setColVariantLoaderId(undefined);
      listProdVariants_call(productRootId);
      listProdVariantCols_call(productRootId);
      ToastsStore.success("Changes has been saved");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editVariantColStatus]);

  useEffect(() => {
    if (deleteProdVariantColStatus === "finished") {
      listProdVariants_call(productRootId);
      listProdVariantCols_call(productRootId);
      ToastsStore.success("Column has been deleted");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProdVariantColStatus]);

  useEffect(() => {
    if (deleteProdVariantTradeUnitStatus === "finished") {
      listProdVariants_call(productRootId);
      ToastsStore.success("Variant trade unit with supply has been deleted");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProdVariantTradeUnitStatus]);

  const listVariantParams = (variant: ProdVariantDto, prodVariantColsList?: Array<ProdVariantColDto>, prodVariantParamsList?: Array<ProdVariantParamDto>) => {
    // const showParams = (prodVariantColsList?.filter((y:ProdVariantColDto) => y.productRootId === variant.id)?.length ?? -1) > 0;
    if (!(prodVariantColsList?.length ?? -1 > 0)) return null;
    return (
      <>
        <h3 className="pb-2 subtitle">🎛️ Params</h3>
        <div className="d-flex params-inputs pb-2" key={variant.id}>
          {prodVariantColsList?.map((col: ProdVariantColDto) => {
            const prodVariantParam = prodVariantParamsList?.find((x) => x.prodVariantId === variant.id && x.prodVariantColId === col.id);
            return (
              <span key={productRootId + "-" + col.id + "-" + variant.id} className="mr-3" style={{ width: 150 }}>
                {prodVariantParam ? (
                  <ParamCellEdit prodVariantParam={prodVariantParam} label={col.rootName} />
                ) : (
                  <ParamCellAdd prodVariantId={variant.id!} prodVariantColId={col.id!} label={col.rootName} />
                )}
              </span>
            );
          })}
        </div>
        <h3 className="pb-2 pt-2 subtitle">📈 SEO settings</h3>
        <div className="d-flex params-inputs pb-2">
          <span className="mr-3" style={{ width: 150 }}>
            <VariantCellEdit
              className="glued-variant-struct-name"
              label="Struct. name (optional)"
              propEdit="rootStructuralName"
              prodVariant={variant}
              langNamesProp="langStructuralNames"
              langNames
            />
          </span>
        </div>
      </>
    );
  };

  function prevOrNextVariant(prevOrNext: "prev" | "next", variant: ProdVariantDto, variants: ProdVariantDto[] | undefined): ProdVariantDto | undefined {
    if (variant.id && variants) {
      const variantIndex = variants?.findIndex((x) => x.id === variant.id);
      if (variantIndex === -1 || (variantIndex === 0 && prevOrNext === "prev") || (variantIndex === variants.length && prevOrNext === "next")) return undefined;
      if (prevOrNext === "prev") return variants[variantIndex - 1];
      else if (prevOrNext === "next") return variants[variantIndex + 1];
    }

    return undefined;
  }

  function prevOrNextProdVariantCol(
    prevOrNext: "prev" | "next",
    variant: ProdVariantColDto,
    variants: ProdVariantColDto[] | undefined
  ): ProdVariantColDto | undefined {
    if (variant.id && variants) {
      const variantIndex = variants?.findIndex((x) => x.id === variant.id);
      if (variantIndex === -1 || (variantIndex === 0 && prevOrNext === "prev") || (variantIndex === variants.length && prevOrNext === "next")) return undefined;
      if (prevOrNext === "prev") return variants[variantIndex - 1];
      else if (prevOrNext === "next") return variants[variantIndex + 1];
    }

    return undefined;
  }

  return (
    <div className="prod-variant-manager">
      <div className="d-flex pb-3">
        <h2 className="title pr-4">🗄️ Variant manager</h2>
        <Modal
          title={`Add variant`}
          openTrigger={triggerId === "add-variant_" + productRootId}
          disableStandardButtons={true}
          button={{
            hidden: true,
          }}
          maxWidth={900}
          confirm={() => listProdVariants_call(productRootId)}
          className={"VariantManagerModal"}
        >
          <AddEditVariantModal productRootId={productRootId} />
        </Modal>
        <Modal
          title={`Add column`}
          openTrigger={triggerId === "add-variantCol_" + productRootId}
          disableStandardButtons={true}
          button={{
            hidden: true,
          }}
          maxWidth={900}
          confirm={() => listProdVariantCols_call(productRootId)}
          className={"VariantManagerModal"}
        >
          <AddEditVariantColModal productRootId={productRootId} />
        </Modal>
      </div>
      <hr />
      <div>
        {listProdVariants_data && listProdVariants_data?.length > 0 ? (
          <>
            <h2 className="pb-3 d-flex">
              <span className="my-auto mr-2">Variants</span>
              <Button variant="like-input-primary" size="xs" onClick={() => setTrigger("add-variant_" + productRootId)}>
                Add variant
              </Button>
            </h2>

            <GenericList
              status={mixStatus([listProdVariants_status, listProdVariantCols_status])}
              data={listProdVariants_data}
              rowClassName={(x) => "red-bg"}
              hideHeader
              // colsSizingMethod="absolute"
              cols={[
                [
                  "",
                  "",
                  12,
                  (variant: ProdVariantDto) => {
                    return (
                      <>
                        <div className="d-flex align-center center-y y-center">
                          <h4 className="subtitle my-auto pr-2">🗃️ Variant: </h4>
                          <VariantCellEdit propEdit="rootName" prodVariant={variant} langNames />
                          <div className="mx-auto"></div>
                        </div>
                      </>
                    );
                  },
                ],
              ]}
              rowChildren={(variant: ProdVariantDto) => {
                return (
                  <div className="p-2 mb-2" key={variant.id}>
                    <Row>
                      <Col size={[6, 6, 12, 12]}>
                        {/* <h3 className="pb-2">Supply</h3> */}
                        {variant.tradeUnits && variant.tradeUnits.length > 0 ? (
                          <GenericList
                            data={variant.tradeUnits}
                            verticalLines
                            colsSizingMethod="absolute"
                            cols={
                              triggerId === "rerender-variant-trade-units-" + variant.id
                                ? []
                                : [
                                    [
                                      "Trade unit",
                                      "",
                                      2,
                                      (tradeUnit: ProdVariantTradeUnitDto) => (
                                        <VariantTradeUnitCellEdit prodVariantTradeUnit={tradeUnit} propEdit={"rootName"} />
                                      ),
                                    ],
                                    [
                                      "Price [pln]",
                                      "",
                                      2,
                                      (tradeUnit: ProdVariantTradeUnitDto) => (
                                        <>
                                          {tradeUnit.isPromotionActive ? (
                                            tradeUnit.price ? (
                                              <>
                                                {tradeUnit.price.toFixed(2)}&nbsp;|&nbsp;
                                                <span className="color-primary-fonts">{tradeUnit.promotionValue + "%"}</span>
                                              </>
                                            ) : (
                                              "-"
                                            )
                                          ) : tradeUnit.price ? (
                                            tradeUnit.price.toFixed(2)
                                          ) : (
                                            "-"
                                          )}
                                        </>
                                      ),
                                    ],
                                    [
                                      "Supply PL (NL)",
                                      "",
                                      2,
                                      (tradeUnit: ProdVariantTradeUnitDto) => (
                                        <>
                                          <VariantTradeUnitCellEdit prodVariantTradeUnit={tradeUnit} propEdit={"supplyPL"} />
                                          &nbsp;
                                          <span style={{ display: "inline-block", minWidth: 42, textAlign: "center", fontSize: "1.2rem" }}>
                                            ({tradeUnit.supplyNL})
                                          </span>
                                        </>
                                      ),
                                    ],
                                    [
                                      "Id of NL supply",
                                      "",
                                      2,
                                      (tradeUnit: ProdVariantTradeUnitDto) => (
                                        <VariantTradeUnitCellEdit prodVariantTradeUnit={tradeUnit} propEdit={"idOfSupplyNL"} />
                                      ),
                                    ],
                                    // ["Supply", "", 3, (tradeUnit) => <VariantCellEdit propEdit="rootName" prodVariant={variant} langNames />],
                                    // ["Id", "", 5, (tradeUnit: ProdVariantTradeUnitDto) => <VariantCellEdit propEdit="rootName" prodVariant={variant} langNames />],
                                  ]
                            }
                            actions={[
                              "",
                              "",
                              1,
                              (tradeUnit: ProdVariantTradeUnitDto) => (
                                <>
                                  <Menu
                                    header="Options"
                                    items={[
                                      {
                                        label: "Edit",
                                        onAction: () => setTrigger("edit_prodVariant_" + tradeUnit.prodVariantId + "_tradeUnit_" + tradeUnit.id),
                                        // onAction: () => Confirm(`Are you sure you want to delete ${item.rootName ?? item.id}?`, () => deleteVariant(item.id!)),
                                      },
                                      {
                                        label: "Delete",
                                        onAction: () =>
                                          Confirm(`Are you sure you want to delete ${tradeUnit.rootName}?`, () => deleteProdVariantTradeUnit(tradeUnit.id!)),
                                        // onAction: () => Confirm(`Are you sure you want to delete ${item.rootName ?? item.id}?`, () => deleteVariant(item.id!)),
                                      },
                                    ]}
                                  />
                                  <div className="reset-actions-styles">
                                    <Modal
                                      key={tradeUnit.id}
                                      title={`Edit trade unit with supply`}
                                      openTrigger={triggerId === "edit_prodVariant_" + tradeUnit.prodVariantId + "_tradeUnit_" + tradeUnit.id}
                                      disableStandardButtons={true}
                                      button={{
                                        hidden: true,
                                      }}
                                      maxWidth={900}
                                      confirm={() => {
                                        setNextRerenderVariantTradeUnitTrigger("rerender-variant-trade-units-" + tradeUnit.prodVariantId);
                                        listProdVariants_call(productRootId);
                                      }}
                                      className={"VariantManagerModal"}
                                    >
                                      <AddEditVariantTradeUnitModal prodVariantId={tradeUnit.prodVariantId} prodVariantTradeUnit={tradeUnit} />
                                    </Modal>
                                  </div>
                                </>
                              ),
                            ]}
                          />
                        ) : null}
                        <br />
                        <Button
                          style={{ lineHeight: 1.5 }}
                          variant="like-input-primary"
                          size="xs"
                          onClick={() => setTrigger("add_prodVariant_tradeUnit_" + variant.id)}
                        >
                          Add trade unit
                        </Button>
                      </Col>

                      <Col size={[6, 6, 12, 12]} className="params-col">
                        {listVariantParams(variant, listProdVariantCols_data, listProdVariantParams_data)}
                      </Col>
                    </Row>
                  </div>
                );
              }}
              actions={[
                "",
                "",
                3,
                (variant: ProdVariantDto) => {
                  const prevVariant = prevOrNextVariant("prev", variant, listProdVariants_data);
                  const nextVariant = prevOrNextVariant("next", variant, listProdVariants_data);

                  return (
                    <>
                      {variant.id ? (
                        <div className="d-flex ml-auto mr-2">
                          <Button
                            className="mx-1"
                            style={{ filter: "grayscale(1)", boxShadow: "none", opacity: prevVariant?.id ? 1 : 0.25 }}
                            size="xs"
                            variant="like-input"
                            disabled={prevVariant?.id ? false : true}
                            onClick={() => replaceVariantPriorityOrder({ firstId: variant.id!, secondId: prevVariant?.id! })}
                          >
                            🔺
                          </Button>
                          <Button
                            className="mx-1"
                            style={{ filter: "grayscale(1)", boxShadow: "none", opacity: nextVariant?.id ? 1 : 0.25 }}
                            size="xs"
                            variant="like-input"
                            disabled={nextVariant?.id ? false : true}
                            onClick={() => replaceVariantPriorityOrder({ firstId: variant.id!, secondId: nextVariant?.id! })}
                          >
                            🔻
                          </Button>
                        </div>
                      ) : null}
                      <Menu
                        header="Options"
                        items={[
                          {
                            label: "Add supply",
                            onAction: () => setTrigger("add_prodVariant_tradeUnit_" + variant.id),
                          },
                          {
                            label: "Copy",
                            onAction: () =>
                              Confirm(
                                `The copy operation will take a short while, please wait until it is finished. Are you sure you want copy ${
                                  variant.rootName ?? variant.id
                                }?`,
                                () => copyProdVariant({ productRootId: productRootId, prodVariantId: variant.id ?? -1 })
                              ),
                          },
                          {
                            label: "Delete",
                            onAction: () => Confirm(`Are you sure you want to delete ${variant.rootName ?? variant.id}?`, () => deleteProdVariant(variant.id!)),
                          },
                        ]}
                      />
                    </>
                  );
                },
              ]}
            />
          </>
        ) : (
          <>
            <h2 className="pb-3 d-flex">
              <span className="my-auto mr-auto">Variants</span>
              <Button variant="like-input-primary" onClick={() => setTrigger("add-variant_" + productRootId)}>
                Add variant
              </Button>
            </h2>
          </>
        )}
        {listProdVariantCols_data && listProdVariantCols_data.length > 0 ? (
          <>
            <div className="pb-3" />
            <hr />
            <h2 className="pb-3 d-flex">
              <span className="my-auto mr-2">Param types</span>
              <Button variant="like-input-primary" size={"xs"} onClick={() => setTrigger("add-variantCol_" + productRootId)}>
                Add param type
              </Button>
            </h2>
            <GenericList
              status={listProdVariantCols_status}
              data={listProdVariantCols_data}
              cols={[
                ["Column root name", "rootName", 3],
                [
                  "Column locale names",
                  "langNames",
                  7,
                  (langNames: Array<NameLangPrefixDto>) => (
                    <div>
                      {langNames.map((nameLang: NameLangPrefixDto, index: number) => {
                        if (nameLang.name)
                          return (
                            <span className="badge py-1 px-2 mr-1" key={index}>
                              {emojiFlags(nameLang.langPrefix) + " " + nameLang.name}
                            </span>
                          );
                        else return null;
                      })}
                    </div>
                  ),
                ],
                [
                  "Filter",
                  "",
                  2,
                  (col: ProdVariantColDto) => (
                    <span
                      className="cursor-pointer"
                      title="filter"
                      onClick={() => {
                        setColVariantLoaderId(col.id + "_filter");
                        editVariantCol({ ...col, isFilter: !col.isFilter });
                      }}
                    >
                      {col.isFilter ? "🟢" : "⚫"}
                      <LoadingSpinner
                        active={editVariantColStatus === "loading" && colVariantLoaderId === col.id + "_filter"}
                        invert
                        style={{ marginTop: 1.5 }}
                      />
                    </span>
                  ),
                ],
                [
                  "Variant selector",
                  "",
                  2,
                  (col: ProdVariantColDto) => (
                    <span
                      className="cursor-pointer"
                      title="dropdown"
                      onClick={() => {
                        setColVariantLoaderId(col.id + "_dropdown");
                        editVariantCol({ ...col, isDropdown: !col.isDropdown });
                      }}
                    >
                      {col.isDropdown ? "🟢" : "⚫"}
                      <LoadingSpinner
                        active={editVariantColStatus === "loading" && colVariantLoaderId === col.id + "_dropdown"}
                        invert
                        style={{ marginTop: 1.5 }}
                      />
                    </span>
                  ),
                ],
                [
                  "Structural",
                  "",
                  2,
                  (col: ProdVariantColDto) => (
                    <span
                      className="cursor-pointer"
                      title="dropdown"
                      onClick={() => {
                        setColVariantLoaderId(col.id + "_structural");
                        editVariantCol({ ...col, isStructuralVariant: !col.isStructuralVariant });
                      }}
                    >
                      {col.isStructuralVariant ? "🟢" : "⚫"}
                      <LoadingSpinner
                        active={editVariantColStatus === "loading" && colVariantLoaderId === col.id + "_structural"}
                        invert
                        style={{ marginTop: 1.5 }}
                      />
                    </span>
                  ),
                ],
              ]}
              actions={[
                "",
                "",
                3,
                (col: ProdVariantColDto) => {
                  const prevVariantCol = prevOrNextProdVariantCol("prev", col, listProdVariantCols_data);
                  const nextVariantCol = prevOrNextProdVariantCol("next", col, listProdVariantCols_data);

                  return (
                    <>
                      {col.id ? (
                        <div className="d-flex ml-auto mr-2">
                          <Button
                            className="mx-1"
                            style={{ filter: "grayscale(1)", boxShadow: "none", opacity: prevVariantCol?.id ? 1 : 0.25 }}
                            size="xs"
                            variant="like-input"
                            disabled={prevVariantCol?.id ? false : true}
                            onClick={() => replaceProdVariantColPriorityOrder({ firstId: col.id!, secondId: prevVariantCol?.id! })}
                          >
                            🔺
                          </Button>
                          <Button
                            className="mx-1"
                            style={{ filter: "grayscale(1)", boxShadow: "none", opacity: nextVariantCol?.id ? 1 : 0.25 }}
                            size="xs"
                            variant="like-input"
                            disabled={nextVariantCol?.id ? false : true}
                            onClick={() => replaceProdVariantColPriorityOrder({ firstId: col.id!, secondId: nextVariantCol?.id! })}
                          >
                            🔻
                          </Button>
                        </div>
                      ) : null}
                      <Menu
                        header="Options"
                        items={[
                          {
                            label: "Edit",
                            onAction: () => setTrigger("update-variantCol_" + productRootId + "_" + col.id),
                            // onAction: () => Confirm(`Are you sure you want to delete ${item.rootName ?? item.id}?`, () => deleteVariant(item.id!)),
                          },
                          {
                            label: "Delete",
                            onAction: () => Confirm(`Are you sure you want to delete ${col.rootName}?`, () => deleteProdVariantCol(col.id)),
                            // onAction: () => Confirm(`Are you sure you want to delete ${item.rootName ?? item.id}?`, () => deleteVariant(item.id!)),
                          },
                        ]}
                      />
                    </>
                  );
                },
              ]}
            />
          </>
        ) : (
          <>
            <div className="pb-3" />
            <hr />
            <h2 className="pb-3 d-flex">
              <span className="my-auto mr-auto">Param types</span>
              <Button variant="like-input-primary" onClick={() => setTrigger("add-variantCol_" + productRootId)}>
                Add param type
              </Button>
            </h2>
          </>
        )}
        <div className="mr-1 pr-1" />
        {listProdVariantCols_data?.map((col: ProdVariantColDto, colIndex: number) => {
          return (
            <Modal
              key={colIndex}
              title={`Edit column`}
              openTrigger={triggerId === "update-variantCol_" + productRootId + "_" + col.id}
              disableStandardButtons={true}
              button={{
                hidden: true,
              }}
              maxWidth={900}
              confirm={() => {
                listProdVariantCols_call(productRootId);
                listProdVariants_call(productRootId);
              }}
              className={"VariantManagerModal"}
            >
              <AddEditVariantColModal productRootId={productRootId} prodVariantCol={col} />
            </Modal>
          );
        })}
        {listProdVariants_data?.map((prodVariant: ProdVariantDto, colIndex: number) => {
          return (
            <Modal
              key={colIndex}
              title={`Add trade unit with supply`}
              openTrigger={triggerId === "add_prodVariant_tradeUnit_" + prodVariant.id}
              disableStandardButtons={true}
              button={{
                hidden: true,
              }}
              maxWidth={900}
              confirm={() => listProdVariants_call(productRootId)}
              className={"VariantManagerModal"}
            >
              <AddEditVariantTradeUnitModal prodVariantId={prodVariant.id!} />
            </Modal>
          );
        })}
      </div>
    </div>
  );
};

export default ProdVariantsManager;
