import React from "react";
import { generatePath, useParams } from "react-router-dom";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import CustomerBasicInformationsEditForm from "./customer-edit-form/CustomerBasicInformationsEditForm";
import CustomerEditMainAddressForm from "./customer-edit-form/CustomerEditMainAddressForm";
import CustomerEditShippingAddressForm from "./customer-edit-form/CustomerEditShippingAddressForm";
import EditShippingAddressWrapper from "./customer-edit-form/EditShippingAddressWrapper";

const CustomerEditPage = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_CUSTOMERS_LIST} title="List" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_CUSTOMERS_MANAGE, { id: id })} title="Customer manage" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Customer manage</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={6}>
            <Tile>
              <h2 className="title">Edit customer</h2>
              <CustomerBasicInformationsEditForm />
            </Tile>
          </Col>
          <Col size={6}></Col>
          <Col size={6}>
            <Tile>
              <h2 className="title">Edit main address</h2>
              <CustomerEditMainAddressForm />
            </Tile>
          </Col>
          <Col size={6}>
            <EditShippingAddressWrapper>
              <Tile>
                <h2 className="title">Edit delivery address</h2>
                <CustomerEditShippingAddressForm />
              </Tile>
            </EditShippingAddressWrapper>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default CustomerEditPage;
