import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { LangDto } from "../../../../../../../../../api/lang/lang.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { RoutePath } from "../../../../../../../../../route-paths";

interface SignInFormProps {}

const LangAddForm = (props: SignInFormProps) => {
  const { call, status: updateStatus } = useQuery<void, LangDto>("lang/add", [Api.Lang.Add], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LangDto>({ defaultValues: {} });
  const navigate = useNavigate();

  const onSubmit = async (dto: LangDto) => {
    call({ ...dto });
  };

  useEffect(() => {
    if (updateStatus === "finished") {
      reset();
      ToastsStore.success("Language has been added");
      navigate(RoutePath.PANEL_SETTINGS_LANGUAGES_LIST as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="name"
          label="Language"
          validation_msg={errors?.name?.message}
          required
          inputProps={{
            ...register("name", Validators.required),
          }}
        />
        <TextInput
          id="country"
          label="Country"
          validation_msg={errors?.country?.message}
          required
          inputProps={{
            ...register("country", Validators.required),
          }}
        />
        <TextInput
          id="prefix"
          label="Prefix"
          validation_msg={errors?.prefix?.message}
          required
          inputProps={{
            ...register("prefix", Validators.required),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Save
        </LoadingBtn>
      </Form>
    </>
  );
};

export default LangAddForm;
