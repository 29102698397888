import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { useParams } from "react-router-dom";
import { AddressDto, CustomerDto } from "../../../../../../../../api/order/order.dto";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Col from "../../../../../../../../libs/grid/col";
import Row from "../../../../../../../../libs/grid/row";
import { UpdateCustomerAddress } from "src/api/customer/Customer";
import LoadingBtn from "src/libs/buttons/Button/LoadingBtn";
import ToastsStore from "src/features/toast-store/ToastsStore";

interface CustomerFormProps {}

const CustomerEditShippingAddressForm: React.FC<CustomerFormProps> = (props) => {
  const { id = "" } = useParams<{ id: string }>();
  const { data } = useQuery<CustomerDto, string>("Customer/get", [Api.Customer.Get, id], {
    statusResetDelay: 1000,
  });

  const { call: update, status: updateStatus } = useQuery<void, UpdateCustomerAddress>("Customer/UpdateShippingAddress", [Api.Customer.UpdateShippingAddress], {
    callOnLoad: false,
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressDto>({ defaultValues: {} });

  useEffect(() => {
    if (data && data.shippingAddress) reset({ ...data.shippingAddress } as AddressDto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = async (dto: AddressDto) => {
    if (data && data.id)
      update({
        customerId: data.id!,
        dto: dto,
      });
  };

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Second delivery address</h3>
          <Row gap={0}>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="name"
                label="Name"
                validation_msg={errors?.name?.message}
                required
                inputProps={{
                  ...register("name", Validators.required),
                }}
              />
              <TextInput
                id="surname"
                label="Surname"
                required
                validation_msg={errors?.surname?.message}
                inputProps={{
                  ...register("surname", Validators.required),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="email"
                label="Email"
                required
                validation_msg={errors?.email?.message}
                inputProps={{
                  type: "email",
                  ...register("email", Validators.email),
                }}
              />
              <TextInput
                id="phonePrefix"
                label="Phone prefix"
                required
                validation_msg={errors?.phonePrefix?.message}
                inputProps={{
                  maxLength: 20,
                  ...register("phonePrefix", Validators.required),
                }}
              />
              <TextInput
                id="phone"
                label="Phone"
                required
                validation_msg={errors?.phone?.message}
                inputProps={{
                  maxLength: 20,
                  ...register("phone", Validators.required),
                }}
              />
            </Col>
          </Row>
          <h3 className="title form-child">Customer address</h3>
          <Row gap={0}>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="country"
                label="Country"
                validation_msg={errors?.country?.message}
                required
                inputProps={{
                  ...register("country", Validators.required),
                }}
              />
              <TextInput
                id="street"
                label="Street"
                required
                validation_msg={errors?.street?.message}
                inputProps={{
                  ...register("street", Validators.required),
                }}
              />
              <TextInput
                id="homenumber"
                label="Home number"
                validation_msg={errors?.homenumber?.message}
                required
                inputProps={{
                  ...register("homenumber", Validators.required),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="city"
                label="City"
                required
                validation_msg={errors?.city?.message}
                inputProps={{
                  ...register("city", Validators.required),
                }}
              />

              <TextInput
                id="postalcode"
                label="Postal code"
                required
                validation_msg={errors?.postalcode?.message}
                inputProps={{
                  maxLength: 6,
                  ...register("postalcode", Validators.required),
                }}
              />
              <TextInput
                id="flatnumber"
                label="Flat number"
                validation_msg={errors?.flatnumber?.message}
                inputProps={{
                  ...register("flatnumber"),
                }}
              />
            </Col>
          </Row>
          <h3 className="title form-child">Other informations</h3>
          <Row gap={0}>
            <Col size={12}>
              <TextInput
                id="notepad"
                label="Note"
                inputProps={{
                  type: "textarea",
                  ...register("notepad"),
                }}
              />
            </Col>
          </Row>
          <Row gap={0}>
            <br />
            <br />
            <Col size={12}>
              <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
                Save
              </LoadingBtn>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default CustomerEditShippingAddressForm;
