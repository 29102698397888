import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ToastsStore from "src/features/toast-store/ToastsStore";
import { useParams } from "react-router-dom";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { CategoryLangDto } from "../../../../../../../../../api/category/category.dto";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { LangDto } from "../../../../../../../../../api/lang/lang.dto";
import WYSIWYGEditor from "../../../../../../../../../libs/forms/WysiwigEditor/WysiwigEditor";
import BreakTitle from "../../../../../../../../components/BreakTitle/BreakTitle";
import EditorJsInput from "src/libs/forms/EditorJsBox/EditorJsInput";

interface CategoryEditFormProps {
  selectedLang: LangDto;
}

const CategoryLangEditForm = (props: CategoryEditFormProps) => {
  const { selectedLang } = props;
  const { id = "" } = useParams<{ id: string }>();

  const { silentCall: callGet, data: categoryLang } = useQuery<CategoryLangDto, { rootId: string; langPrefix: string }>(
    "category/getLangByRoot",
    [Api.Category.GetLangByRoot, { rootId: id, langPrefix: selectedLang.prefix }],
    { statusResetDelay: 1000, callOnLoad: false }
  );
  const { call: callUpdate, status: updateStatus } = useQuery<void, CategoryLangDto>("category/updateLang", [Api.Category.UpdateLang], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<CategoryLangDto>({ defaultValues: {} });

  const onSubmit = async (dto: CategoryLangDto) => {
    callUpdate({ ...categoryLang, ...dto } as CategoryLangDto);
  };

  useEffect(() => {
    reset(categoryLang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryLang]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
      callGet({ rootId: id, langPrefix: selectedLang.prefix });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  useEffect(() => {
    if (categoryLang) return;
    else callGet({ rootId: id, langPrefix: selectedLang.prefix });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          id="name"
          label="Short name"
          validation_msg={errors?.name?.message}
          required
          inputProps={{
            ...register("name", Validators.required),
          }}
        />
        <TextInput
          id="fullName"
          label="Full name"
          validation_msg={errors?.fullName?.message}
          required
          inputProps={{
            ...register("fullName", Validators.required),
          }}
        />
        <TextInput
          id="descriptionShort"
          label="Short description (tile)"
          validation_msg={errors?.descriptionShort?.message}
          inputProps={{
            type: "textarea",
            ...register("descriptionShort"),
          }}
        />
        <WYSIWYGEditor
          id="descriptionLong"
          label="Long description (top)"
          validation_msg={errors?.descriptionLong?.message}
          inputProps={{
            control: control,
            defaultValue: categoryLang?.descriptionMain,
          }}
        />
        <EditorJsInput
          id="descriptionMain"
          label="Main description (bottom)"
          validation_msg={errors?.descriptionMain?.message}
          inputProps={{
            type: "textarea",
            ...register("descriptionMain"),
          }}
        />
        <BreakTitle className="pl-0">SEO Tags</BreakTitle>
        <TextInput
          id="meta_title"
          label="[SEO] Tag <title>"
          validation_msg={errors?.meta_title?.message}
          inputProps={{
            ...register("meta_title"),
          }}
        />
        <TextInput
          id="meta_description"
          label="[SEO] Tag <description>"
          validation_msg={errors?.meta_description?.message}
          inputProps={{
            type: "textarea",
            ...register("meta_description"),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Save
        </LoadingBtn>
      </Form>
    </>
  );
};

export default CategoryLangEditForm;
