import React, { useContext, useEffect, useState } from "react";
import Api from "../../../../../../../../../api/Api";
import { ArticleRootDto, RelatedAuthorRootRelationDto } from "../../../../../../../../../api/article/article.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import { ModalContext } from "../../../../../../../../components/Modal/ModalContext";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import { IdNameDto } from "../../../../../../../../../api/tools/tools.models.dto";
import "./related-authors-add-modal.scss";

interface MediaAddEditModalProps {
  articleRootId: number;
}

const RelatedArticleAddModal: React.FC<MediaAddEditModalProps> = (props) => {
  const { articleRootId } = props;
  const { handleConfirm } = useContext(ModalContext);
  const [selectedItem, setSelectedItem] = useState<ArticleRootDto>();


  const { data: authorNames, call: callList, status: listStatus } = useQuery<Array<IdNameDto>>("author/ListRootIdNames", [Api.Author.ListRootIdNames]);
  const { call, status } = useQuery<void, RelatedAuthorRootRelationDto>("article/AddRelatedAuthor", [Api.Article.AddRelatedAuthor], { callOnLoad: false });

  useEffect(() => {
    callList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectAfterMounted(() => {
    callList();
  }, [authorNames]);

  const onSubmit = () => {
    if (selectedItem && selectedItem?.id) {
      var addDto = {
        articleRootId: articleRootId,
        relatedAuthorRootId: selectedItem.id,
      } as RelatedAuthorRootRelationDto;
      call(addDto);
    }
  };

  useEffect(() => {
    if (status === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="related-authors-add-modal">
      {authorNames ? (
        <>
          <div className="list-wrapper">
            <GenericList
              status={listStatus}
              data={authorNames}
              cols={[
                ["Id", "id", 1],
                ["Name", "name", 3],
              ]}
              inputProps={{
                multiselect: false,
                onChange: (x: any) => setSelectedItem(x),
              }}
            />
          </div>
        </>
      ) : null}

      <div className="ml-auto pt-3">
        <LoadingBtn onClick={() => onSubmit()} status={status} className="ml-auto">
          Add
        </LoadingBtn>
      </div>
    </div>
  );
};

export default RelatedArticleAddModal;
