import ToastsStore from "src/features/toast-store/ToastsStore";
import { useParams } from "react-router-dom";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { LangKeyDto } from "../../../../../../../../../api/lang/lang.dto";
import Api from "../../../../../../../../../api/Api";
import GenericList, { NumberStringTuple } from "../../../../../../../../../libs/generic-list/generic-list";
import Menu, { MenuItemType } from "../../../../../../../../components/Menu/menu";
import { Confirm } from "../../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import AddEditKeyForm from "./AddEditKeyForm";

interface LangEditFormProps {}

const LangKeysManager = (props: LangEditFormProps) => {
  const { id = "" } = useParams<{ id: string }>();
  const { call, status, data: keysData } = useQuery<Array<LangKeyDto>, string>("lang/listKeys", [Api.Lang.ListKeys, id], { statusResetDelay: 1000 });
  const { status: deleteStatus, call: deleteCall } = useQuery<void, string>("lang/deleteKey", [Api.Lang.DeleteKey], { callOnLoad: false });

  const standardCols: NumberStringTuple[] = [
    [
      "Name : Value",
      "",
      6,
      (x: LangKeyDto) => (
        <>
          <AddEditKeyForm langId={id} parentId={x.parentId} langKey={x}>
            {x.name} : {x.value}
          </AddEditKeyForm>
        </>
      ),
    ],
  ];

  useEffectAfterMounted(() => {
    if (deleteStatus === "finished") {
      ToastsStore.success("Key has been deleted");
      call(id);
    }
  }, [deleteStatus]);

  const standardActions = (actionsItems: (categoryRoot: LangKeyDto) => Array<MenuItemType>): NumberStringTuple => [
    "",
    "",
    7,
    (categoryRoot: LangKeyDto) => <Menu header="Options" items={actionsItems(categoryRoot)} />,
  ];

  const actionsItems__delete = (dto: LangKeyDto) => {
    let id = dto.id?.toString();
    return { label: "Delete", onAction: () => Confirm(`Are you sure you want to delete the ${dto.name} key?`, () => deleteCall(id)) };
  };

  const standardActionsItems = (dto: LangKeyDto): Array<MenuItemType> => {
    return [actionsItems__delete(dto)];
  };

  return (
    <>
      <GenericList
        status={status}
        data={keysData}
        cols={[...standardCols]}
        actions={standardActions(standardActionsItems)}
        // rowClassName={(x: CategoryDto) => (x.isActive ? "" : "inactive")}
        rowChildren={(x: LangKeyDto) =>
          x.children?.length ? (
            <>
              <GenericList
                status={status}
                data={x.children}
                hideHeader
                cols={[...standardCols]}
                actions={standardActions(standardActionsItems)}
                rowChildren={(z: LangKeyDto) =>
                  z.children?.length ? (
                    <>
                      <GenericList status={status} hideHeader data={z.children} cols={[...standardCols]} actions={standardActions(standardActionsItems)} />
                      <AddEditKeyForm langId={id} parentId={z.id} />
                    </>
                  ) : (
                    <AddEditKeyForm langId={id} parentId={z.id} />
                  )
                }
              />
              <AddEditKeyForm langId={id} parentId={x.id} />
            </>
          ) : (
            <AddEditKeyForm langId={id} parentId={x.id} />
          )
        }
      />
      <AddEditKeyForm langId={id} parentId={undefined} />
    </>
  );
};

export default LangKeysManager;
