import { useEffect } from "react";
import { useAppSelector } from "../../redux/store";
import { selectTheme } from "./themeSlice";

export default function useTheme() {
  const theme = useAppSelector(selectTheme);

  useEffect(() => {
    var html = document.getElementsByTagName('html')[0];

    if (theme === 'dark') html.classList.add('theme-dark');
    else html.classList.remove('theme-dark');


  }, [theme]);

  return theme;
}