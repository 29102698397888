import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import ToastsStore from "src/features/toast-store/ToastsStore";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import { GeneralSettingsDto, PageSettingsSectionDto } from "../../../../../../../../api/generalSettings/generalSettings.dto";

interface GenSettHomeSettingsFormProps {
  pageId: string;
  data?: GeneralSettingsDto;
}

interface FormDto {
  deliverySection_LocalPalletePrice_PLN: string;
  deliverySection_LocalCourierPrice_PLN: string;
  deliverySection_EuropeCourierPrice_PLN: string;
  deliverySection_EuropePalletePrice_PLN: string;
}

const DeliveryPricesPolicyForm = (props: GenSettHomeSettingsFormProps) => {
  const { data } = props;

  const { call, status: updateStatus } = useQuery<void, PageSettingsSectionDto>(
    "generalSettings/updatePageSettingsSection-cart",
    [Api.GeneralSettings.UpdatePageSettingsSection],
    {
      callOnLoad: false,
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormDto>({ defaultValues: {} });

  const onSubmit = async (dto: FormDto) => {
    if (data && data.id) call({ section_items: { ...dto }, pageId: props.pageId, id: data?.id });
  };

  useEffectAfterMounted(() => {
    if (data) {
      var index = data.pagesSettings.findIndex((x) => x.pageId === props.pageId);
      var pageSettingsSection_items = data?.pagesSettings[index]?.sections ?? {};
      if (index !== -1) {
        reset({
          deliverySection_LocalPalletePrice_PLN: pageSettingsSection_items["deliverySection_LocalPalletePrice_PLN"] ?? "",
          deliverySection_LocalCourierPrice_PLN: pageSettingsSection_items["deliverySection_LocalCourierPrice_PLN"] ?? "",
          deliverySection_EuropeCourierPrice_PLN: pageSettingsSection_items["deliverySection_EuropeCourierPrice_PLN"] ?? "",
          deliverySection_EuropePalletePrice_PLN: pageSettingsSection_items["deliverySection_EuropePalletePrice_PLN"] ?? "",
        });
      } else {
        reset({
          deliverySection_LocalPalletePrice_PLN: "",
          deliverySection_LocalCourierPrice_PLN: "",
          deliverySection_EuropeCourierPrice_PLN: "",
          deliverySection_EuropePalletePrice_PLN: "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
      // refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
      <h3 className="title form-child">Local prices</h3>
      <TextInput
        id="deliverySection_LocalCourierPrice_PLN"
        label="Local courier [PLN]"
        validation_msg={errors?.deliverySection_LocalCourierPrice_PLN?.message}
        inputProps={{
          type: "number",
          step: 0.01,
          min: 0,
          ...register("deliverySection_LocalCourierPrice_PLN"),
        }}
      />
      <TextInput
        id="deliverySection_LocalPalletePrice_PLN"
        label="Local pallete [PLN]"
        validation_msg={errors?.deliverySection_LocalPalletePrice_PLN?.message}
        inputProps={{
          type: "number",
          step: 0.01,
          min: 0,
          ...register("deliverySection_LocalPalletePrice_PLN"),
        }}
      />
      <h3 className="title form-child">Europe prices</h3>
      <TextInput
        id="deliverySection_EuropeCourierPrice_PLN"
        label="Europe courier [PLN]"
        validation_msg={errors?.deliverySection_EuropeCourierPrice_PLN?.message}
        inputProps={{
          type: "number",
          step: 0.01,
          min: 0,
          ...register("deliverySection_EuropeCourierPrice_PLN"),
        }}
      />
      <TextInput
        id="deliverySection_EuropePalletePrice_PLN"
        label="Europe pallete [PLN]"
        validation_msg={errors?.deliverySection_EuropePalletePrice_PLN?.message}
        inputProps={{
          type: "number",
          step: 0.01,
          min: 0,
          ...register("deliverySection_EuropePalletePrice_PLN"),
        }}
      />
      <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
        Save
      </LoadingBtn>
    </Form>
  );
};

export default DeliveryPricesPolicyForm;
