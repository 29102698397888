import React from "react";
import BoxTile from "../../../../libs/containers/BoxTile/BoxTile";
import TextBlock from "../../../../libs/text-block/text-block";
import RequestResetPasswordForm from "./ResetPasswordForm/RequestResetPasswordForm";
import { useResetPassword } from "./ResetPasswordForm/ResetPasswordDataSlice";

const RequestResetPasswordPage = () => {

  const { requestResetPassword_status } = useResetPassword();

  return (
    requestResetPassword_status === 'finished' ?
      <BoxTile maxW={540} title={<h1>Sprawdź skrzynkę pocztową</h1>}>
        <TextBlock>
          <p>Na podany adres email, została wysłana wiadomość z&nbsp;linkiem umożliwiającym ustawienie nowego hasła.</p>
        </TextBlock>
      </BoxTile> :
      <BoxTile maxW={440} title={<h1>Przypomnij hasło</h1>}>
        <RequestResetPasswordForm />
      </BoxTile>
  );
};

export default RequestResetPasswordPage;
