import ToastsStore from "src/features/toast-store/ToastsStore";

const CopyToClipboard = (text: string) => {
  const copyToClipboard = (strText: string) => {
    navigator.clipboard
      .writeText(strText)
      .then(() => {
        ToastsStore.success("Copied to clipboard!");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };

  return (
    <p
      className="p-3"
      onClick={() => copyToClipboard(text)}
      style={{ cursor: "pointer", opacity: 0.8, wordBreak: "break-all", fontSize: "1.6rem", letterSpacing: "0.02em", lineHeight: "1.4", fontStyle: "italic" }}
    >
      {text}
    </p>
  );
};

export default CopyToClipboard;
