import toast from "react-simple-toasts";
import "./ToastsStore.scss";

class ToastsStore {
  static async error(msg: string) {
    return toast(msg, {
      //   time: 5000,
      //   position: "top-right",
      //   clickable: true,
      //   clickClosable: true,
      position: "top-center",
      className: "error-toast",
      //   render: (message) => <CustomToast message={message} />,
      //   onClick: (event) => console.log("Toast clicked!"),
    });
  }
  static async success(msg: string) {
    return toast(msg, {
      position: "top-center",
      className: "success-toast",
    });
  }
}

export default ToastsStore;
