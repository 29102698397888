import React from "react";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import OrdersList from "./OrdersList/OrdersList";

export type OrdersListVariant = "all" | "archived" | "new" | "inprogress";

interface Props {
  variant: OrdersListVariant;
}

const OrdersListPage: React.FC<Props> = (props: Props) => {
  const { variant } = props;

  var getRoutePath = (variant: OrdersListVariant) => {
    return (
      (variant === "archived" ? RoutePath.PANEL_ORDERS_LIST_ARCHIVED : "") +
      (variant === "new" ? RoutePath.PANEL_ORDERS_LIST_NEW : "") +
      (variant === "inprogress" ? RoutePath.PANEL_ORDERS_LIST_INPROGRESS : "") +
      (variant === "all" ? RoutePath.PANEL_ORDERS_LIST : "")
    );
  };

  var getListName = (variant: OrdersListVariant) => {
    return (
      (variant === "archived" ? "Order archive" : "") +
      (variant === "new" ? "New orders" : "") +
      (variant === "inprogress" ? "Orders in progress" : "") +
      (variant === "all" ? "All orders" : "")
    );
  };

  return (
    <div>
      <BreadcrumbsItem to={getRoutePath(variant)} title={getListName(variant)} />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Orders management</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <div className="d-flex pb-3">
                <h2 className="title mr-auto">{getListName(variant)}</h2>
                {/* <TopLink to={RoutePath.PANEL_PROJECTS_ADD}>
                  <Button variant="like-input-primary">+ Dodaj nowy</Button>
                </TopLink> */}
              </div>
              <hr />
              {variant === "all" ? <OrdersList variant={variant} /> : null}
              {variant === "new" ? <OrdersList variant={variant} /> : null}
              {variant === "inprogress" ? <OrdersList variant={variant} /> : null}
              {variant === "archived" ? <OrdersList variant={variant} /> : null}
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default OrdersListPage;

export const OrdersListAllPage = () => {
  return (
    <>
      <OrdersListPage variant={"all"} />;
    </>
  );
};

export const OrdersListNewsPage = () => {
  return (
    <>
      <OrdersListPage variant={"new"} />;
    </>
  );
};

export const OrdersListInprogressPage = () => {
  return (
    <>
      <OrdersListPage variant={"inprogress"} />;
    </>
  );
};

export const OrdersListArchivedPage = () => {
  return (
    <>
      <OrdersListPage variant={"archived"} />
    </>
  );
};
