import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { TagLangDto, TagRootDto } from "../../../../../../../../api/tag/tag.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import BreakTitle from "../../../../../../../components/BreakTitle/BreakTitle";
import { LangDto } from "../../../../../../../../api/lang/lang.dto";
import LangSwitch from "../../../../../../../components/LangSwitch/LangSwitch";
import MediaList from "./media-list/media-list";
import TagEditForm from "./forms/TagEditForm";
import TagLangEditForm from "./forms/TagLangEditForm";
import TagLangNonExistTile from "./forms/TagLangNonExistTile";

const TagEditPage = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data: tagRoot } = useQuery<TagRootDto, number>("tag/getRoot", [Api.Tag.GetRoot, Number(id)], {
    statusResetDelay: 1000,
  });
  const { data: tagLangs } = useQuery<Array<TagLangDto>, number>("tag/listLangs", [Api.Tag.ListLang, Number(id)], {
    statusResetDelay: 1050,
  });


  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_KNOWLEDGEBASE_TAGS_EDIT, { id: id })} title="Edit tag" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Edit tag</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <BreakTitle size={"xl"}>Shared settings (no lang)</BreakTitle>
          </Col>
          <Col size={[6, 8, 10, 12]}>
            <Tile>
              <h2 className="title">Base informations</h2>
              <TagEditForm data={tagRoot} />
            </Tile>
          </Col>
        </PanelRow>
        <LangSwitch
          render={({ langs, activeLang }) =>
            tagRoot && activeLang
              ? langs?.map((lang: LangDto, index: number) => {
                const tagLangIndex = tagLangs?.findIndex((x) => x.langId === lang.id) ?? -1;
                const rootContainsLang = tagLangIndex !== -1;
                if (!rootContainsLang) return <TagLangNonExistTile lang={lang} key={index} />;
                const tagLang = tagLangs![tagLangIndex];
                return rootContainsLang ? (
                  <PanelRow className={activeLang.id === lang.id ? "" : "d-none"} key={index}>
                    <Col size={[6, 6, 10, 12]}>
                      <Tile>
                        <h2 className="title">Base informations</h2>
                        <TagLangEditForm selectedLang={lang} />
                      </Tile>
                    </Col>
                    <Col size={[6, 6, 12, 12]}>
                      <Tile>{tagLang.id ? <MediaList scopeId={tagLang.id!} /> : null}</Tile>
                    </Col>
                  </PanelRow>
                ) : (
                  <TagLangNonExistTile lang={lang} key={index} />
                );
              })
              : null
          }
        />
      </PanelGrid>
    </div>
  );
};

export default TagEditPage;