// tools.js
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
// import Paragraph from '@editorjs/paragraph'
import List from "@editorjs/list";
// import Warning from '@editorjs/warning'
// import Code from '@editorjs/code'
// import LinkTool from '@editorjs/link'
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Api, { apiURL } from "src/api/Api";
// import Quote from '@editorjs/quote'
// import Marker from '@editorjs/marker'
// import CheckList from '@editorjs/checklist'
// import Delimiter from '@editorjs/delimiter'
// import InlineCode from '@editorjs/inline-code'
// import SimpleImage from '@editorjs/simple-image'

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  //   paragraph: Paragraph,
  embed: Embed,
  table: Table,
  header: {
    class: Header,
    inlineToolbar : true
  },
  list: {
    class: List,
    inlineToolbar : true
  },
  //   warning: Warning,
  //   code: Code,
  //   linkTool: LinkTool,
  image: {
    class: Image,
    config: {
      uploader: {
        async uploadByFile(file: any) {
          const result = await Api.Image.UploadFile({ file: file });
          console.log(result);
          return {
            success: 1,
            file: {
              url: apiURL + result.imageUrl,
            },
          };
        },
      },
    },
  },
  raw: Raw,
  //   quote: Quote,
  //   marker: Marker,
  //   checklist: CheckList,
  //   delimiter: Delimiter,
  //   inlineCode: InlineCode,
  //   simpleImage: SimpleImage,
};
