import React from "react";
import { Outlet } from "react-router-dom";
import { RoutePath } from "../../../../../route-paths";
import ExactPathSwitch from "../../../../components/ExactPathSwitch/ExactPathSwitch";
import BreadcrumbsItem from "../../../../components/Breadcrumbs/BreadcrumbsItem";
import "./panel-page.scss";

const PanelPage = () => {

  return (
    <>
      <ExactPathSwitch
        path={RoutePath.PANEL}
        if_exact={<>
          <BreadcrumbsItem to={RoutePath.PANEL} title={"Start"} />
        </>}
        else={
          <div className="page-wrapper">
            <BreadcrumbsItem to={RoutePath.PANEL} title={"Start"} />
            <Outlet />
          </div>
        }
      />
    </>
  );
};

export default PanelPage;
