import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import Api from "../../../../../../../../api/Api";
import { useQuery, useQueryRefetch } from "../../../../../../../../api/useQuerySlice";
import { useParams } from "react-router-dom";
import { CustomerDto } from "../../../../../../../../api/order/order.dto";
import Col from "../../../../../../../../libs/grid/col";
import Row from "../../../../../../../../libs/grid/row";
import { PanelCustomerUpdateDto } from "src/api/customer/customer.dto";
import CheckBoxInput from "src/libs/forms/CheckBox/CheckBox";
import LoadingBtn from "src/libs/buttons/Button/LoadingBtn";
import ToastsStore from "src/features/toast-store/ToastsStore";

interface CustomerFormProps {}

const CustomerBasicInformationsEditForm: React.FC<CustomerFormProps> = (props) => {
  const { id = "" } = useParams<{ id: string }>();
  const { data } = useQuery<CustomerDto, string>("Customer/get", [Api.Customer.Get, id], {
    statusResetDelay: 1000,
  });
  const { refetch } = useQueryRefetch();
  const { call: update, status: updateStatus } = useQuery<void, PanelCustomerUpdateDto>(
    "Customer/UpdateBasicInformations",
    [Api.Customer.UpdateBasicInformations],
    { callOnLoad: false }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<PanelCustomerUpdateDto>({ defaultValues: {} });

  useEffect(() => {
    if (data)
      reset({
        isPrepaymentAvaliable: data.isPrepaymentAvaliable,
        // deliveryMethod: data.deliveryMethod, //TODO
        // paymentMethod: data.paymentMethod, // TODO
        company: data.company,
        nipPrefix: data.nipPrefix,
        nip: data.nip,
        isShippingAddress: data.isShippingAddress,
        accept__marketing: data.accept__marketing,
      } as PanelCustomerUpdateDto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = async (dto: PanelCustomerUpdateDto) => {
    if (data && data.id)
      update({
        id: data.id!,
        deliveryMethod: data.deliveryMethod, //TODO
        paymentMethod: data.paymentMethod, // TODO
        company: dto.company,
        nipPrefix: dto.nipPrefix,
        nip: dto.nip,
        isPrepaymentAvaliable: dto.isPrepaymentAvaliable,
        isShippingAddress: dto.isShippingAddress,
        accept__marketing: dto.accept__marketing,
      });
  };

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
      refetch("Customer/get--checkShippmentAvaliability");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Basic informations</h3>
          <Row gap={0} align="center">
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="company"
                label="Company name"
                validation_msg={errors?.company?.message}
                inputProps={{
                  ...register("company"),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="nip"
                label="TAX number"
                validation_msg={errors?.nip?.message}
                inputProps={{
                  maxLength: 10,
                  ...register("nip"),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="nipPrefix"
                label="Tax number prefix"
                validation_msg={errors?.nipPrefix?.message}
                inputProps={{
                  maxLength: 10,
                  ...register("nipPrefix"),
                }}
              />
            </Col>

            <Col size={[6, 6, 12, 12]}>
              <CheckBoxInput
                id="isPrepaymentAvaliable"
                label="Is prepayment avaliable?"
                validation_msg={errors?.isPrepaymentAvaliable?.message}
                inputProps={{
                  ...register("isPrepaymentAvaliable"),
                }}
                description={"Customer has avaliable prepayment option"}
              />
            </Col>

            <Col size={[6, 6, 12, 12]}>
              <CheckBoxInput
                id="isShippingAddress"
                label="Is second address active?"
                validation_msg={errors?.isShippingAddress?.message}
                inputProps={{
                  ...register("isShippingAddress"),
                }}
                description={"Order should be send to second address"}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <CheckBoxInput
                id="accept__marketing"
                label="Is marketing accepted"
                validation_msg={errors?.accept__marketing?.message}
                inputProps={{
                  ...register("accept__marketing"),
                }}
                description={"Customer accepted e-marketing"}
              />
            </Col>
            <Col size={12}>
              <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
                Save
              </LoadingBtn>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default CustomerBasicInformationsEditForm;
