import React from "react";
import { generatePath } from "react-router-dom";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import TagAddForm from "./tag-add-form/TagAddForm";

const TagAddPage = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_KNOWLEDGEBASE_TAGS_LIST} title="List" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_KNOWLEDGEBASE_TAGS_ADD)} title="New tag" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>New tag</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[6, 8, 10, 12]}>
            <Tile>
              <h2 className="title">Basic informations</h2>
              <TagAddForm />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default TagAddPage;
