import { generatePath, useParams } from "react-router-dom";
import { UserRoleEnum } from "../../../../../../../../api/auth/auth.dto";
import { useSession } from "../../../../../../../../api/auth/sessionSlice";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import LangEditForm from "./lang-edit-form/LangEditForm";
import LangKeysManager from "./lang-keys-manager/LangKeysManager";
import ReconfigureKeys from "./reconfigure-keys/ReconfigureKeys";

const LangEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { rolesFilter } = useSession();

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_SETTINGS_LANGUAGES_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_SETTINGS_LANGUAGES_EDIT, { id: id })} title="Edit language" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Edit language</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={4}>
            <Tile>
              <h2 className="title">Base informations</h2>
              <LangEditForm />
            </Tile>
          </Col>
          {rolesFilter([UserRoleEnum.RootAdmin]) ? (
            <Col size={4} offset={[4]}>
              <Tile>
                <h2 className="title mr-auto">Reconfiguration</h2>
                <ReconfigureKeys />
              </Tile>
            </Col>
          ) : null}
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h2 className="title mr-auto">Lang keys</h2>
              <LangKeysManager />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default LangEditPage;
