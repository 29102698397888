import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { RoutePath } from "../../../../../../../../../route-paths";
import { TagRootDto } from "src/api/tag/tag.dto";

const TagAddForm = () => {
  const { call, status: updateStatus } = useQuery<void, TagRootDto>("tag/add", [Api.Tag.AddRoot], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TagRootDto>({ defaultValues: {} });
  const navigate = useNavigate();

  const onSubmit = async (dto: TagRootDto) => {
    call(dto);
  };

  useEffect(() => {
    if (updateStatus === "finished") {
      reset();
      ToastsStore.success("Tag added successfuly");
      navigate(RoutePath.PANEL_KNOWLEDGEBASE_TAGS_LIST as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
      <TextInput
        id="rootName"
        label="Name"
        validation_msg={errors?.rootName?.message}
        required
        inputProps={{
          ...register("rootName", Validators.required),
        }}
      />
      <br />
      <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
        Save
      </LoadingBtn>
    </Form>
  );
};

export default TagAddForm;
