import { generatePath } from "react-router-dom";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { TagRootDto } from "../../../../../../../../../api/tag/tag.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import GenericList, { NumberStringTuple } from "../../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../../route-paths";
import Menu, { MenuItemType } from "../../../../../../../../components/Menu/menu";
import { showSlideOverlay } from "../../../../../../../../components/router-transition/routerTransitionSlice";

interface CategoriesListProps { }

const CategoriesList = (props: CategoriesListProps) => {
  const { data, status, call } = useQuery<Array<TagRootDto>>("tag/listRoot", [Api.Tag.ListRoot]);
  const { status: delStatus, call: callDeleteRoot } = useQuery<void, number>("tag/deleteRoot", [Api.Tag.DeleteRoot], { callOnLoad: false });

  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    if (delStatus === "finished") {
      ToastsStore.success("Tag has been deleted");
      call();
    }
  }, [delStatus]);

  const handleDeleteRoot = (tagRoot: TagRootDto) => {
    Confirm(`Are you sure you want to delete tag ${tagRoot.rootName}?`, () => callDeleteRoot(tagRoot.id));
  };

  const standardCols: NumberStringTuple[] = [
    ["Name", "rootName", 11],
  ];

  const standardActions = (actionsItems: (tagRoot: TagRootDto) => Array<MenuItemType>): NumberStringTuple => [
    "",
    "",
    1,
    (tagRoot: TagRootDto) => <Menu header="Options" items={actionsItems(tagRoot)} />,
  ];

  const actionsItems__edit = (tagRoot: TagRootDto) => {
    return {
      label: "Edit",
      onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_KNOWLEDGEBASE_TAGS_EDIT, { id: tagRoot.id?.toString() }))),
    };
  };
  const actionsItems__delete = (tagRoot: TagRootDto) => {
    return { label: "Delete", onAction: () => handleDeleteRoot(tagRoot) };
  };

  const standardActionsItems = (tagRoot: TagRootDto): Array<MenuItemType> => {
    return [
      actionsItems__edit(tagRoot),
      actionsItems__delete(tagRoot),
    ];
  };
  return (
    <GenericList
      status={status}
      data={data}
      cols={[...standardCols]}
      actions={standardActions(standardActionsItems)}
    />
  );
};

export default CategoriesList;
