import React, { useEffect } from "react";
import "./param-cell.scss";
import { ProdVariantParamDto } from "src/api/prodVariant/prodVariant.dto";
import TextInput from "src/libs/forms/TextBox/TextInput";
import Api from "src/api/Api";
import { useQuery, useQueryRefetch } from "src/api/useQuerySlice";
import ToastsStore from "src/features/toast-store/ToastsStore";

interface Props {
  prodVariantId: number;
  prodVariantColId: number;
  label?: string;
}

const ParamCellAdd: React.FC<Props> = (props) => {
  const { prodVariantId, prodVariantColId, label } = props;

  const { call: addCall, status } = useQuery<void, ProdVariantParamDto>("ProdVariant/AddVariantParam", [Api.ProdVariant.AddVariantParam], {
    callOnLoad: false,
  });

  const { refetch } = useQueryRefetch();

  const handleBlur = (e: any) => {
    if (e.target!.value) {
      const dto = {
        langNames: [],
        prodVariantColId: prodVariantColId,
        prodVariantId: prodVariantId,
        rootValue: e.target!.value,
      } as ProdVariantParamDto;
      addCall(dto);
      
    }
  };

  useEffect(() => {
    if (status === "finished") {
      refetch("ProdVariant/ListProdVariantParams");
      ToastsStore.success("Changes have been saved");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className={`param-cell ${label ? "--with-label" : ""}`}>
      <TextInput
        label={label ? label : undefined}
        inputProps={{
          onBlurCapture: handleBlur,
          defaultValue: "",
        }}
      />
    </div>
  );
};

export default ParamCellAdd;
