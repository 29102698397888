import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import ToastsStore from "src/features/toast-store/ToastsStore";
import { useQuery, useQueryRefetch } from "../../../../../../../../../api/useQuerySlice";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
import { ParamType, ParamType_Parser, ProductParamDto } from "../../../../../../../../../api/productParam/productParam.dto";
import Button from "../../../../../../../../../libs/buttons/Button/Button";
import TextListInput from "../../../../../../../../../libs/forms/TextListBox/TextListInput";
import SelectBox from "../../../../../../../../../libs/forms/SelectBox/SelectBox";
import { mapEnum } from "../../../../../../../../../features/enumMap";

interface Props {
  productLangId: number;
  productParam?: ProductParamDto;
  children?: React.ReactNode;
}
interface AddProductParamDto {
  productLangId: number;
  dto: ProductParamDto;
}
interface FlatProductParamDto {
  name: string;
  paramType: ParamType;
  values: string;
  value: string;
}
const AddEditParamForm = (props: Props) => {
  const { productParam, productLangId, children } = props;

  const { call: addCall, status: addStatus } = useQuery<void, AddProductParamDto>("productParam/add", [Api.ProductParam.Add], { callOnLoad: false });
  const { call: editCall, status: editStatus } = useQuery<void, ProductParamDto>("productParam/update", [Api.ProductParam.Update], { callOnLoad: false });

  const [isAddActive, setIsAddActive] = useState<boolean>(false);
  const { refetch } = useQueryRefetch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<FlatProductParamDto>({ defaultValues: {} });
  const watch_paramType = watch("paramType");

  const onSubmit = async (dto: FlatProductParamDto) => {
    var list = [];
    if (typeof dto.values == "string" && dto.paramType.toString() === ParamType.ValuesList.toString()) {
      list = JSON.parse(dto.values);
    }
    if (productParam) {
      if (dto.paramType.toString() === ParamType.ValuesList.toString()) {
        editCall({ ...dto, id: productParam.id, values: list });
      } else editCall({ ...dto, id: productParam.id, values: [dto.value] });
    } else {
      if (dto.paramType.toString() === ParamType.ValuesList.toString()) {
        addCall({ dto: { ...dto, values: list }, productLangId: productLangId! });
      } else addCall({ dto: { ...dto, values: [dto.value] }, productLangId: productLangId! });
    }
  };

  useEffect(() => {
    if (addStatus === "finished") {
      reset({ name: "", paramType: undefined, values: "[]", value: "" });
      setIsAddActive(false);
      ToastsStore.success("Your param has been added");
      setTimeout(() => {
        refetch("productParam/list");
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addStatus]);

  useEffect(() => {
    if (editStatus === "finished") {
      reset({ name: "", paramType: undefined, values: "[]", value: "" });
      setIsAddActive(false);
      ToastsStore.success("Your param has been updated");
      setTimeout(() => {
        refetch("productParam/list");
      }, 50);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  useEffect(() => {
    if (productParam && isAddActive) {
      if (productParam.paramType === ParamType.ValuesList) {
        reset({ name: productParam.name, paramType: productParam.paramType, values: productParam.values ? JSON.stringify(productParam.values) : "[]" });
      } else {
        reset({ name: productParam.name, paramType: productParam.paramType, values: "[]", value: productParam.values[0] ?? "" });
      }
    } else {
      reset({ name: "", paramType: undefined, values: "[]", value: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddActive]);

  const handleCancel = (e: any) => {
    e.preventDefault();
    setIsAddActive(false);
  };

  return (
    <>
      {isAddActive ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextInput
              id="name"
              label="Nazwa"
              validation_msg={errors?.name?.message}
              required
              inputProps={{
                ...register("name", Validators.required),
              }}
            />
            <SelectBox
              id="paramType"
              label="Type"
              validation_msg={errors?.paramType?.message}
              required
              options={mapEnum(ParamType, (x: ParamType) => {
                return { id: x, label: ParamType_Parser(x) };
              })}
              inputProps={{
                ...register("paramType", Validators.required),
              }}
            />
            {watch_paramType?.toString() === ParamType.Html.toString() ? (
              <TextInput
                id="value"
                label="Value"
                inputProps={{
                  type: "textarea",
                  ...register("value"),
                }}
              />
            ) : null}
            {watch_paramType?.toString() === ParamType.Value.toString() ? (
              <TextInput
                id="value"
                label="Value"
                inputProps={{
                  type: "text",
                  ...register("value"),
                }}
              />
            ) : null}
            {watch_paramType?.toString() === ParamType.ValuesList.toString() ? (
              <TextListInput
                id="values"
                label="Values"
                inputProps={{
                  ...register("values"),
                }}
              />
            ) : null}
            <div className="d-flex">
              <LoadingBtn variant="like-input" type="submit" status={addStatus} style={{ minWidth: "auto" }}>
                Save
              </LoadingBtn>{" "}
              &nbsp;{" "}
              <Button variant="like-text" style={{ minWidth: "auto" }} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <>
          {children}
          <div className="addEdit-new-item d-inline-block" onClick={() => setIsAddActive(true)}>
            {productParam ? <> &nbsp; Edit</> : "Add new"}
          </div>
        </>
      )}
    </>
  );
};

export default AddEditParamForm;
