import { generatePath } from "react-router-dom";
import ToastsStore from "src/features/toast-store/ToastsStore";
import Api from "../../../../../../../../../api/Api";
import { AuthorDto } from "../../../../../../../../../api/author/author.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { Confirm } from "../../../../../../../../../features/confirm";
import useEffectAfterMounted from "../../../../../../../../../features/useEffectAfterMounted";
import GenericList, { NumberStringTuple } from "../../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../../route-paths";
import Menu, { MenuItemType } from "../../../../../../../../components/Menu/menu";
import { showSlideOverlay } from "../../../../../../../../components/router-transition/routerTransitionSlice";
import { ImageFieldEnum } from "src/api/image/image.dto";
import GenericListIcon from "src/libs/generic-list/generic-list-icon";
import { ProductDto } from "src/api/product/product.dto";
import { truncateString } from "src/features/string-helpers";

interface CategoriesListProps {}

const CategoriesList = (props: CategoriesListProps) => {
  const { data, status, call } = useQuery<Array<AuthorDto>>("author/listRoot", [Api.Author.List]);
  const { status: delStatus, call: callDeleteRoot } = useQuery<void, number>("author/deleteRoot", [Api.Author.DeleteRoot], { callOnLoad: false });

  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    if (delStatus === "finished") {
      ToastsStore.success("Author has been deleted");
      call();
    }
  }, [delStatus]);

  const handleDeleteRoot = (authorRoot: AuthorDto) => {
    Confirm(`Are you sure you want to delete author ${authorRoot.rootName}?`, () => callDeleteRoot(authorRoot.authorRootId));
  };

  const extractPath = (x: ProductDto) => {
    var path = x.images.find((y) => y.field === ImageFieldEnum.MainPicture)?.path;
    if (path) return path;
    else return "/system-images/avatar_placeholder.png";
  };

  const standardCols: NumberStringTuple[] = [
    ["Avatar", "", 1, (x) => <GenericListIcon img={process.env.REACT_APP_API_URL + extractPath(x)} size="md" openOnClick={true} />],
    ["Name", "rootName", 2],
    ["Subtitle", "subtitle", 8, (x) => truncateString(x, 100)],
  ];

  const standardActions = (actionsItems: (authorRoot: AuthorDto) => Array<MenuItemType>): NumberStringTuple => [
    "",
    "",
    1,
    (authorRoot: AuthorDto) => <Menu header="Options" items={actionsItems(authorRoot)} />,
  ];

  const actionsItems__edit = (authorRoot: AuthorDto) => {
    return {
      label: "Edit",
      onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_KNOWLEDGEBASE_AUTHORS_EDIT, { id: authorRoot.authorRootId?.toString() }))),
    };
  };
  const actionsItems__delete = (authorRoot: AuthorDto) => {
    return { label: "Delete", onAction: () => handleDeleteRoot(authorRoot) };
  };

  const standardActionsItems = (authorRoot: AuthorDto): Array<MenuItemType> => {
    return [actionsItems__edit(authorRoot), actionsItems__delete(authorRoot)];
  };
  return <GenericList status={status} data={data} cols={[...standardCols]} actions={standardActions(standardActionsItems)} />;
};

export default CategoriesList;
