import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import ToastsStore from "src/features/toast-store/ToastsStore";
import { useParams } from "react-router-dom";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import { LangDto } from "../../../../../../../../../api/lang/lang.dto";
import Api from "../../../../../../../../../api/Api";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import Validators from "../../../../../../../../../libs/forms/validators/validators";

interface LangEditFormProps {}

const LangEditForm = (props: LangEditFormProps) => {
  const { id = "" } = useParams<{ id: string }>();
  const { call: refetch, data } = useQuery<LangDto, string>("lang/get", [Api.Lang.Get, id], { statusResetDelay: 1000 });
  const { call, status: updateStatus } = useQuery<void, LangDto>("lang/update", [Api.Lang.Update], { callOnLoad: false });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LangDto>({ defaultValues: {} });

  const onSubmit = async (dto: LangDto) => {
    call({ ...data, ...dto });
  };

  useEffect(() => {
    reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (updateStatus === "finished") {
      ToastsStore.success("Data has been saved");
      refetch(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} cols={2}>
        <TextInput
          id="prefix"
          label="Prefix"
          inputProps={{
            readOnly: true,
            disabled: true,
            ...register("prefix"),
          }}
        />
        <TextInput
          id="name"
          label="Nazwa"
          validation_msg={errors?.name?.message}
          required
          inputProps={{
            ...register("name", Validators.required),
          }}
        />
        <TextInput
          id="country"
          label="Kraj"
          required
          validation_msg={errors?.country?.message}
          inputProps={{
            ...register("country", Validators.required),
          }}
        />
        <br />
        <LoadingBtn type="submit" status={updateStatus} className="ml-auto">
          Save
        </LoadingBtn>
      </Form>
    </>
  );
};

export default LangEditForm;
