import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingBtn from "../../../../../../../../libs/buttons/Button/LoadingBtn";
import Form from "../../../../../../../../libs/forms/Form/Form";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import { mapEnum } from "../../../../../../../../features/enumMap";
import SelectBox from "../../../../../../../../libs/forms/SelectBox/SelectBox";
import { OrderDto, OrderStatusEnum, OrderStatusEnum_Parser } from "../../../../../../../../api/order/order.dto";
import ToastsStore from "src/features/toast-store/ToastsStore";

interface OrderManageFormProps {
  order: OrderDto;
}

interface StatusManageDto {
  status: OrderStatusEnum;
}

const StatusManageForm: React.FC<OrderManageFormProps> = (props) => {
  const { order } = props;
  const { call: callUpdateStatus, status: updateStatusStatus } = useQuery<void, { id: string; status: OrderStatusEnum }>(
    "order/update-status",
    [Api.Order.UpdateStatus],
    { callOnLoad: false }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<StatusManageDto>({ defaultValues: {} });

  const onSubmit = async (dto: StatusManageDto) => {
    callUpdateStatus({ id: order?.id?.toString() ?? "", status: dto.status });
  };

  useEffect(() => {
    reset({
      status: order.orderStatus,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      status: order.orderStatus,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    if (updateStatusStatus === "finished") {
      ToastsStore.success("Status has been changed");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateStatusStatus]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-child form-child-wrapper">
          <h3 className="title form-child">Current order status</h3>
          <SelectBox
            id="status"
            label="Status"
            validation_msg={errors?.status?.message}
            options={mapEnum(OrderStatusEnum, (x: OrderStatusEnum) => {
              return { id: x, label: OrderStatusEnum_Parser(x) };
            })}
            maxHeight
            inputProps={{
              placeholder: "Status",
              ...register("status"),
            }}
          />
        </div>
        <br />
        <div className="ml-auto">
          <LoadingBtn type="submit" status={updateStatusStatus}>
            Save
          </LoadingBtn>
        </div>
      </Form>
    </>
  );
};

export default StatusManageForm;
