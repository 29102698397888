export enum SystemScopeEnum {
  Global = 1,
  Category = 2,
  CategoryRoot = 3,
  CategoryLang = 4,
  Product = 5,
  ProductRoot = 6,
  ProductLang = 7,
  GeneralSettings = 8,
  TagRoot = 9,
  TagLang = 10,
  ArticleRoot = 11,
  ArticleLang = 12,
  AuthorRoot = 13,
  AuthorLang = 14
}