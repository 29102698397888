import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import Api, { apiURL } from "../../../../../../../../../api/Api";
import { SystemScopeEnum } from "../../../../../../../../../api/enums/enums";
import { ImageDto, ImageFieldEnum, ImageFieldEnum_Parser, UploadImageDto } from "../../../../../../../../../api/image/image.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import DropzoneGlu from "../../../../../../../../../libs/forms/DropZone/drop-zone-glu";
import Form from "../../../../../../../../../libs/forms/Form/Form";
import SelectBox from "../../../../../../../../../libs/forms/SelectBox/SelectBox";
import TextInput from "../../../../../../../../../libs/forms/TextBox/TextInput";
import Validators from "../../../../../../../../../libs/forms/validators/validators";
// import WYSIWYGEditor from "../../../../../../../../libs/forms/WysiwigEditor/WysiwigEditor";
import { ModalContext } from "../../../../../../../../components/Modal/ModalContext";

interface MediaAddEditModalProps {
  itemId: number;
  media?: ImageDto;
}

const MediaAddEditModal: React.FC<MediaAddEditModalProps> = (props) => {
  const { itemId, media } = props;
  const { call, status } = useQuery<void, UploadImageDto>("product/addImage", [Api.Product.AddImage], { callOnLoad: false });
  const { call: editCall, status: editStatus } = useQuery<void, UploadImageDto>("product/updateImage", [Api.Product.UpdateImage], {
    callOnLoad: false,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<any>({ defaultValues: {} });
  const { handleConfirm } = useContext(ModalContext);

  const onSubmit = (data: any) => {
    var file = null;
    if (data.file) file = data.file;

    if (media) {
      var updateDto = {
        scope: SystemScopeEnum.ProductLang,
        id: data.id,
        file: file,
        alt: data.alt,
        scopeId: itemId,
        field: data.field,
      } as UploadImageDto;
      editCall(updateDto);
    } else {
      var addDto = {
        scope: SystemScopeEnum.ProductLang,
        scopeId: itemId,
        file: file,
        alt: data.alt,
        field: data.field,
      } as UploadImageDto;
      call(addDto);
    }
  };

  useEffect(() => {
    if (status === "finished" || editStatus === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, editStatus]);

  useEffect(() => {
    if (media !== null) {
      reset(media);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} direction="row" cols={1}>
      <DropzoneGlu
        id="file"
        label="file"
        validation_msg={errors?.file?.message}
        onFileAdded={undefined}
        imagePreviewUrl={media && media.path ? apiURL + media.path : null}
        inputProps={{
          control: control,
        }}
      />
      <SelectBox
        id="field"
        label="Function"
        validation_msg={errors?.field?.message}
        required
        options={[
          {
            id: ImageFieldEnum.MainPictureGallery,
            label: ImageFieldEnum_Parser(ImageFieldEnum.MainPictureGallery),
          },
          {
            id: ImageFieldEnum.MainPicture,
            label: ImageFieldEnum_Parser(ImageFieldEnum.MainPicture),
          },
        ]}
        inputProps={{
          ...register("field", Validators.required),
        }}
      />
      <TextInput
        id="alt"
        label="Alt"
        validation_msg={errors?.alt?.message}
        required
        inputProps={{
          ...register("alt", Validators.required),
        }}
      />
      {/* <WYSIWYGEditor
        id="description"
        label="Opis"
        validation_msg={errors?.description?.message}
        inputProps={{
          control: control,
          defaultValue: media?.description,
        }}
      /> */}
      <br />
      <div className="ml-auto">
        {media ? (
          <LoadingBtn type="submit" status={editStatus} className="ml-auto">
            Save
          </LoadingBtn>
        ) : (
          <LoadingBtn type="submit" status={status} className="ml-auto">
            Add
          </LoadingBtn>
        )}
      </div>
    </Form>
  );
};

export default MediaAddEditModal;
