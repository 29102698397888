import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { RoutePath } from "../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../components/Breadcrumbs/BreadcrumbsItem";
import ExactPathSwitch from "../../../../../components/ExactPathSwitch/ExactPathSwitch";

const OrdersLayout: React.FC = () => {

  return (
    <>
      <BreadcrumbsItem to={RoutePath.PANEL_ORDERS} title="Orders" isNotLink />
      <ExactPathSwitch
        path={RoutePath.PANEL_ORDERS}
        if_exact={<Navigate to={RoutePath.PANEL_ORDERS_LIST_NEW} />}
        else={<Outlet />}
      />
    </>
  );
};

export default OrdersLayout;
