import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { RoutePath } from "../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../components/Breadcrumbs/BreadcrumbsItem";
import ExactPathSwitch from "../../../../../components/ExactPathSwitch/ExactPathSwitch";

const UsersLayout = () => {

  return (
    <>
      <BreadcrumbsItem to={RoutePath.PANEL_USERS} title="Users" isNotLink={true} />
      <ExactPathSwitch
        path={RoutePath.PANEL_USERS}
        if_exact={<Navigate to={RoutePath.PANEL_USERS_LIST} />}
        else={<Outlet />}
      />
    </>
  );
};

export default UsersLayout;
